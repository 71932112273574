.sideNavMenu {
    min-width: 222px;
    width: 100%;
    transition: ease-in-out 0.2s;
    max-height: 100vh;
    overflow: auto;
}

.sideNavMenu.collapsedSdNav {
    min-width: 120px;
    width: 100%;
    transition: ease-in-out 0.2s;
}

.sideNavMenu .logoWrapper {
    text-align: center;
    padding-top: 35px;
    width: 217px;
    transition: ease-in-out 0.2s;
}

.sideNavMenu.collapsedSdNav .logoWrapper {
    width: 120px;
}

.sideNavMenu .logoWrapper img {
    width: 150px;
    height: 84px;
    transition: ease-in-out 0.2s;
}

.sideNavMenu.collapsedSdNav .logoWrapper img {
    width: 100px;
}

.sideNavMenu .menuLinks .MuiButtonBase-root.MuiListItem-button {
    padding-left: 0;
    margin-bottom: 25px;
}

.sideNavMenu .menuLinks {
    padding-left: 7px;
    margin: 4rem 0px;
}

.sideNavMenu .menuLinks a {
    width: 100%;
    color: black;
    font-size: 12px;
    text-decoration: none;
    padding-left: 45px;
    border-left: 2px solid transparent;
    display: flex;
    align-items: center;
}

.sideNavMenu .menuLinks img {
    width: 26px;
    margin-right: 10px;
    filter: grayscale(1) brightness(0);
}

.sideNavMenu .menuLinks a.active,
.sideNavMenu .menuLinks a:hover {
    color: var(--primColor);
    border-left: 2px solid var(--primColor);
}

.sideNavMenu .menuLinks a.active img,
.sideNavMenu .menuLinks a:hover img {
    filter: unset;
}

.sideNavMenu .collpaseIcon img {
    transition: ease-in-out 0.3s;
}

.sideNavMenu .collpaseIcon.collpased img {
    transform: rotate(180deg);
}
