.productSongCard {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    background: #fff;
}

.productSongCard .pscImage {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
}

.productSongCard .pscTitle {
    color: var(--primColor);
    font-size: 24px;
    font-weight: 700;
    margin: 20px 0 12px;
    height: 4.3ex;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.productSongCard .pscPayDetails {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
}

.productSongCard .pscPayDetails h6 {
    color: #686868;
    font-size: 11px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
}

.productSongCard .pscPayDetails h6 span {
    display: block;
    width: 100%;
    margin-top: 5px;
    color: #232323;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.productSongCard .pscSongDetails {
    margin: 25px 0 0;
}

.productSongCard .pscSongDetails .sdTop {
    display: grid;
    grid-template-columns: 80px 1fr;
    gap: 15px;
}

.productSongCard .pscSongDetails .sdTop > img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 10px;
}

.productSongCard .pscSongDetails .sdTop h6 {
    color: #232323;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.productSongCard .pscSongDetails .sdTop p {
    color: #686868;
    font-size: 12px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.productSongCard .pscSongDetails .sdBottom {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
    margin-top: 15px;
}

.productSongCard .pscSongDetails .sdStream img {
    height: 20px;
    width: auto;
    object-fit: contain;
    margin-bottom: 8px;
}

.productSongCard .pscSongDetails .sdStream h6 {
    color: #686868;
    font-size: 12px;
    font-weight: 500;
}

.productSongCard .pscSongDetails .sdStream h6 span {
    display: block;
    width: 100%;
    color: #232323;
    font-size: 12px;
    font-weight: 500;
    margin-top: 4px;
}

.productSongCard .slick-dots {
    position: static;
}

.productSongCard .slick-dots button::before {
    font-size: 14px;
}
