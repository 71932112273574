.homeMarginBottom {
    margin-bottom: 80px;
}

.home .homeTitle {
    font-size: 28px;
    font-weight: 700;
    color: var(--textColor);
    margin-bottom: 20px;
}

/* BANNER */
.home .homeBanner {
    height: 630px;
}

.home .homeBanner {
    display: flex;
    justify-content: space-between;
}

.home .homeBanner .hbLt {
    height: 100%;
    background: transparent linear-gradient(180deg, #e1f4ff 0%, #e9f3f9 80%, #fff 100%) 0% 0%
        no-repeat padding-box;
    text-align: start;
    padding: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: -webkit-fill-available;
}

.home .homeBanner .hbLt h1 {
    font-size: 45px;
    font-family: var(--diplayFont);
    color: var(--secColor);
    font-style: italic;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0.5px;
    margin-top: 0;
    margin-bottom: 15px;
}

.home .homeBanner .hbLt h5 {
    color: var(--textColor);
    font-size: 15px;
    margin-bottom: 40px;
}

.home .homeBanner .hbLt .bannerSearch {
    background: #fff;
    padding: 10px;
}

.home .homeBanner .hbLt .bannerSearch input {
    height: 60px;
    border: none;
    border-bottom: 2px solid transparent;
    margin-inline-end: 10px;
    border-radius: 0;
}

.home .homeBanner .hbLt .bannerSearch input:focus {
    outline: 0;
    box-shadow: none;
    border-bottom: 2px solid var(--primColor);
}

.home .homeBanner .hbRt {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    padding: 15px;
    grid-template-rows: max-content;
}

.home .homeBanner .hbRt img {
    width: 260px;
    height: 290px;
}

/* CATEGORY SLIDER */

.homeCategorySlider {
    text-align: start;
}

.homeCategorySlider .categoryItem {
    cursor: pointer;
    height: 230px;
}

.homeCategorySlider .categoryItem img {
    width: 170px;
    height: 170px;
    border-radius: 5px;
    object-fit: cover;
}

.homeCategorySlider .categoryItem h6 {
    font-size: 14px;
    color: var(--textColor);
    margin-top: 10px;
    width: 170px;
}

/* ABOUT */

.homeAbout {
    position: relative;
    z-index: 1;
    padding-top: 40px;
    padding-bottom: 40px;
}

.homeAbout:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    clip-path: polygon(0 0, 69% 0, 13% 100%, 0% 100%);
    background: #e1f4ff;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.homeAbout .homeAboutLt {
    padding: 35px;
    border-radius: 5px;
    border-left: 5px solid var(--primColor);
    box-shadow: 0px 30px 60px #199de51f;
    max-width: 750px;
    width: 100%;
    text-align: start;
    background: #fff;
    margin-inline-end: 30px;
}

.homeAbout .homeAboutLt h2 {
    font-size: 28px;
    font-weight: 700;
    color: var(--secColor);
    margin-bottom: 20px;
    margin-top: 0px;
}

.homeAbout .homeAboutLt p {
    font-size: 16px;
    color: var(--textColor);
    margin-bottom: 20px;
    margin-top: 0px;
}

.homeAbout .homeAboutLt .primButton {
    width: 220px;
}

.homeAbout .homeAboutRt object {
    width: 400px;
}

/* RECENTLY LISTED */

.homeRecentlyListed {
    text-align: start;
}

.homeRecentlyListed .recentItems {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
}

@media (max-width: 1200px) {
    .home .homeBanner .hbRt img {
        width: 220px;
        height: 250px;
    }
}

@media (max-width: 1024px) {
    .home .homeBanner .hbLt {
        padding: 30px;
    }
    .home .homeBanner .hbRt {
        padding: 15px;
    }
    .home .homeBanner.homeMarginBottom {
        margin-bottom: 15px;
    }

    .home .homeBanner .hbLt h1 {
        font-size: 40px;
    }
    .homeAbout {
        flex-wrap: wrap;
    }
}

@media (max-width: 992px) {
    .home .homeBanner .hbRt img {
        width: 200px;
        height: 230px;
        object-fit: cover;
    }
    .home .homeBanner {
        height: 550px;
    }
}

@media (max-width: 991px) {
    .home .homeBanner {
        flex-wrap: wrap;
        height: auto;
    }
    .home .homeBanner .hbLt {
        padding: 60px 30px;
        text-align: center;
    }

    .home .homeBanner .hbLt > div {
        width: 100%;
    }
    .home .homeBanner .hbRt {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        width: 100%;
        padding: 0 30px;
    }
    .home .homeBanner .hbRt img {
        width: 100%;
    }
    .home .homeBanner.homeMarginBottom {
        margin-bottom: 60px;
    }

    .home .homeAbout {
        flex-direction: column-reverse;
    }

    .home .homeAbout .homeAboutLt {
        max-width: initial;
        text-align: center;
    }

    .home .homeAbout .homeAboutLt .primButton {
        width: 220px;
        margin: 0 auto;
    }
}

@media (max-width: 825px) {
    .home .homeBanner .hbRt {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
    .home .homeBanner .hbRt img {
        height: 200px;
    }
}

@media (max-width: 767px) {
    .home .homeBanner .hbRt {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
    .homeCategorySlider .categoryItem {
        padding-inline-end: 10px;
    }
    .homeCategorySlider .categoryItem img {
        width: 100%;
    }
}

@media (max-width: 660px) {
    .home .homeBanner .hbRt {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    .home .homeBanner .hbRt img {
        height: 350px;
    }
}

@media (max-width: 500px) {
    .home .homeTitle {
        font-size: 20px;
    }

    .home .homeBanner.homeMarginBottom,
    .home .homeMarginBottom {
        margin-bottom: 30px;
    }
    .home .homeBanner .hbRt img {
        height: 200px;
    }
    .homeAbout .homeAboutLt {
        text-align: start;
        margin-inline-end: 0;
        border-left: none;
        padding: 25px;
        border-top: 5px solid var(--primColor);
    }
    .homeAbout .homeAboutLt h2 {
        font-size: 24px;
        text-align: start;
    }
    .homeAbout .homeAboutLt p {
        text-align: start;
    }
    .home .homeAbout .homeAboutLt .primButton {
        margin: initial;
    }
}

@media (max-width: 445px) {
    .homeAbout .homeAboutRt object {
        width: 300px;
        margin: 0 auto 30px;
    }
    .home .homeBanner .hbRt {
        display: none;
    }
    .home .homeBanner .hbLt .bannerSearch {
        flex-wrap: wrap;
    }
    .home .homeBanner .hbLt .bannerSearch input {
        width: 100%;
    }
    .home .homeBanner .hbLt .bannerSearch .primButton {
        width: 100%;
        margin-top: 5px;
    }
    .home .homeBanner .hbLt h1 {
        line-height: 50px;
    }
    .home .homeBanner .hbLt {
        padding: 40px 15px;
    }

    .home .homeCategorySlider .slick-next,
    .home .homeCategorySlider .slick-prev {
        display: none;
    }
    .homeCategorySlider .categoryItem h6 {
        margin-top: 10px;
    }
}
