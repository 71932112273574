.profileName {
    width: max-content;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 70px;
    text-align: center;
}
.profileName h2 {
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.profileName h2 img {
    margin-right: 10px;
}

.profileName h6 {
    color: #fff;
    margin-top: 15px;
    font-size: 12px;
    font-weight: 700;
}

/* .profileName.noRR {
    top: 0;
} */

.ryltyDashbrdWrapper.publicProfile.noRR {
    padding-top: 10px;
}

.publicProfile .songCardContainer.noRR {
    margin-top: 180px;
    background: #fff;
    padding: 20px;
    border-radius: 20px;
}

.publicProfile .songCardContainer.empty {
    display: flex;
    justify-content: center;
    align-items: center;
}

.previewNoRecords {
    margin-top: 50px;
}
