.appLayoutWrapper {
    max-width: 2000px;
    margin: 0 auto;
    position: relative;
}

.appLayoutWrapper .sideNav {
    position: sticky;
    top: 0;
}

.appLayoutWrapper .rtWrapper {
    width: calc(100% - 222px);
    z-index: 1;
}

.topContainer .mainHeader {
    background: var(--primColor);
    padding: 10px 0 !important;
    height: 70px;
}

.appLayoutWrapper .rtWrapper .backGrndImage {
    width: calc(100% - 222px);
    position: absolute;
    z-index: -1;
    /* height: 300px; */
    max-height: 420px;
    aspect-ratio: 1200/420;
    top: 70px;
    object-fit: cover;
    object-position: top;
}

.appLayoutWrapper .rtWrapper .backGrndImage.noHeader {
    top: 0 !important;
}

.appLayoutWrapper .rtWrapper.navCollapsed,
.appLayoutWrapper .rtWrapper.navCollapsed .backGrndImage {
    width: calc(100% - 120px);
}

.appLayoutWrapper .backGrndImage.largeImage {
    max-height: 420px;
    height: 100%;
    aspect-ratio: 1200 / 420;
}

.appLayoutWrapper .pgHdrContent {
    display: flex;
    align-items: center;
    margin-top: 60px;
}

.appLayoutWrapper .pgHdrContent img {
    width: 40px;
    height: 40px;
    filter: brightness(11);
    margin-right: 15px;
}

.appLayoutWrapper .pgHdrContent h4 {
    font-family: var(--secFont);
    font-size: 36px;
    font-weight: 700;
    color: white;
    margin: 0;
}

/* .appLayoutWrapper .srchlogoContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 130px;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.appLayoutWrapper .srchlogoContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 232px;
    height: 78px;
    padding-left: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.appLayoutWrapper .srchlogoContainer .logoBack {
    position: absolute;
    width: 130px;
}

.appLayoutWrapper .srchlogoContainer .mnLogo {
    /* z-index: 1; */
    position: relative;
    /* top: -7px; */
    width: 100%;
}

.appLayoutWrapper .sideNav .sideNavMenu::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.appLayoutWrapper .sideNav .sideNavMenu::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.appLayoutWrapper .sideNav .sideNavMenu::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 100px;
}

/* Handle on hover */
.appLayoutWrapper .sideNav .sideNavMenu::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.appLayoutWrapper .rtWrapper .customContainer.unAuthCustomContainer,
.topContainer .customClassMobile .customContainer.mainHeader {
    max-width: 1900px;
    padding-inline-start: 60px !important;
    padding-inline-end: 60px !important;
    margin-left: auto;
    margin-right: auto;
}

.topContainer .customClassMobile .customContainer.mainHeader {
    padding-inline-start: 20px !important;
}

.topContainer .customClassMobile .mainHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
}

.topContainer .customClassMobile {
    background: var(--primColor);
}
.topContainer .customClassMobile .mainHeader .srchlogoContainer {
    position: relative !important;
}

@media (max-width: 1366px) {
    .appLayoutWrapper .rtWrapper .backGrndImage.search,
    .appLayoutWrapper .rtWrapper .backGrndImage.auctions {
        height: 344px;
    }

    .appLayoutWrapper .rtWrapper .backGrndImage.auctions.watchlist {
        height: 304px;
    }
}

@media (max-width: 1000px) {
    .appLayoutWrapper .rtWrapper .backGrndImage.search,
    .appLayoutWrapper .rtWrapper .backGrndImage.auctions {
        height: 404px;
    }
}

@media (max-width: 700px) {
    .appLayoutWrapper .rtWrapper .backGrndImage.auctions.watchlist {
        height: 280px;
    }

    .appLayoutWrapper .srchlogoContainer .mnLogo {
        width: 180px;
    }

    .appLayoutWrapper .srchlogoContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 252px;
        height: 82px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 600px) {
    .appLayoutWrapper .pgHdrContent h4 {
        font-size: 30px;
    }

    .appLayoutWrapper .pgHdrContent img {
        width: 30px;
        height: 30px;
    }

    .appLayoutWrapper .rtWrapper .backGrndImage.search,
    .appLayoutWrapper .rtWrapper .backGrndImage.auctions {
        height: 504px;
    }

    .appLayoutWrapper .srchlogoContainer {
        padding-left: 0;
        width: 194px;
    }

    .appLayoutWrapper .srchlogoContainer img {
        width: 100%;
    }

    .appLayoutWrapper .srchlogoContainer .mnLogo {
        width: 136px;
    }
}

.appLayoutWrapper .rtWrapper .customContainer {
    -webkit-padding-start: 15px !important;
    padding-inline-start: 15px !important;
    -webkit-padding-end: 15px !important;
    padding-inline-end: 15px !important;
}
