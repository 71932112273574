.App {
    /* text-align: center; */
    background-color: #fff;
    /* max-width: 1600px; */
    margin-inline-start: auto !important;
    margin-inline-end: auto !important;
}

.externalSearch .feedbacklab {
    display: none !important;
}
