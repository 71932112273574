.custom-modal {
    display: flex;
    align-items: center;
}

.custom-modal .modal-dialog {
    width: 90%;
    max-width: 900px;
}

.custom-modal .modal-header {
    background: #f0eff4;
    border-radius: 10px 10px 0 0;
    height: 60px;
}

.lh-sm {
    line-height: 0;
}

.custom-modal .modal-title {
    line-height: 1.5;
    font-weight: bold;
    font-size: 18px;
    text-align: start;
    color: #111;
    text-transform: uppercase;
}

.custom-modal .card {
    width: 100%;
    height: 166px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #eaeaea;
    max-width: 800px;
    margin: 10px auto;
}

.custom-modal .card-text,
.custom-modal .card-title {
    font-weight: normal;
    font-size: 16px;
    text-align: start;
    color: #111;
}

.custom-modal .modal-body {
    max-height: calc(92vh - 60px);
    overflow: auto;
}

.custom-modal .modal-content {
    border: none;
}

.custom-modal .modal-dialog.modal-lg {
    width: 90%;
    max-width: 900px;
    margin: 0 auto;
}

.ncModal .modalChildren {
    padding: 0;
}

.savedBankCnt .savedBank {
    padding: 15px;
    text-align: left;
    background: #ebebeb;
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
    margin: 0;
    /* background-image: radial-gradient(
        circle farthest-corner at 10% 20%,
        rgba(97, 186, 255, 1) 0%,
        rgba(166, 239, 253, 1) 90.1%
    ); */
    background-image: linear-gradient(to right, #00f2fe 0%, #4facfe 100%);
}

.savedBankCnt .savedBank .material-icons {
    font-size: 45px;
    color: var(--primColor);
}

.savedBankCnt .savedBank h4 {
    font-size: 16px;
    margin: 20px 0 15px;
    font-weight: 600;
    color: #333;
}

.savedBankCnt .savedBank h5 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 600;
    color: #333;
    letter-spacing: 4px;
}

.savedBankCnt .savedBank h4 span,
.savedBankCnt .savedBank h5 span {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: var(--primColor);
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.savedBankCnt .savedBank .edtBtn .MuiButton-label {
    font-size: 15px;
    font-weight: 600;
    color: var(--primColor);
}

.savedBankCnt .savedBank .dltBtn .MuiButton-label {
    font-size: 15px;
    font-weight: 600;
    color: #dc3545;
}

@media screen and (max-width: 767px) {
    .custom-modal .modal-dialog.modal-lg {
        width: 90%;
        max-width: 700px;
    }
}

@media screen and (max-width: 500px) {
    .custom-modal .modal-dialog.modal-lg {
        width: 90%;
        max-width: 400px;
    }
}
