.logRegLayout {
    display: flex;
    align-items: stretch;
}

.logRegLayout .leftImgContainer {
    /* background: url(../../../../assets/logBack.png); */
    background: #3982c0;
    max-width: 480px;
    width: 100%;
    height: 100vh;
    background-size: cover;
    position: sticky;
    top: 0;
}

.logRegLayout .leftImgContainer.backgroundImg {
    background: url(../../../../assets/logBack.png);
    background-size: cover;
}

.logRegLayout .leftImgContainer .logoContainer {
    width: 232px;
    padding-left: 40px;
    height: 78px;
    position: relative;
    display: flex;
    align-items: center;
}

.logRegLayout .leftImgContainer .logoContainer .mnLogo {
    width: 100%;
}

.logRegLayout .stepCounter {
    font-family: var(--secFont);
    height: 55px;
    border-top: 1px solid #2e2e33;
    border-bottom: 1px solid #2e2e33;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 28px;
}

.logRegLayout .regHdrTxt {
    font-family: var(--secFont);
}

.logRegLayout img.vrtclogo {
    width: 130px;
    margin-left: 27px;
    margin-top: 16px;
}

@media (max-width: 850px) {
    .logRegLayout .rgLyrWrpr {
        padding: 0 !important;
        margin-top: 25px;
    }

    .logRegLayout .leftImgContainer {
        max-width: unset;
    }
}

.logRegisterContentCnt {
    padding: 24px 16px 24px 40px;
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
}

.logRegisterContentCnt * {
    color: white;
    margin-bottom: 0px;
}

.logRegisterContentCnt > div {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 8px;
}

.logRegisterContentCnt h2 {
    font-size: 24px;
    font-weight: 500;
    margin-top: 12px;
}
.logRegisterContentCnt h3 {
    font-size: 18px;
    font-weight: 500;
}

.logRegisterContentCnt p {
    font-size: 16px;
}

@media (max-height: 750px) {
    .logRegisterContentCnt {
        padding: 0px 16px 24px 40px;
        gap: 16px;
    }
    .logRegisterContentCnt p {
        font-size: 14px;
    }
    .logRegisterContentCnt p {
        font-size: 14px;
    }
    .logRegisterContentCnt h2 {
        font-size: 22px;
    }
    .logRegisterContentCnt h3 {
        font-size: 16px;
    }
    .logRegisterContentCnt > div {
        gap: 4px;
    }
}

@media (max-width: 700px) {
    .logRegLayout {
        flex-wrap: wrap;
    }

    .logRegLayout .leftImgContainer .logoContainer {
        width: 130px;
        height: auto;
        position: relative;
    }
    .logRegisterContentCnt {
        padding-left: 16px;
    }

    .logRegisterContentCnt {
        margin-top: 40px !important;
    }
    .logRegLayout .leftImgContainer .logoContainer .whtBackDrop {
        width: 100%;
        height: 100%;
    }

    .logRegLayout .rgLyrWrpr {
        margin-top: 0px;
    }

    .loginWrapper .registerForm .regType {
        width: 100%;
        padding-right: 0px !important;
        margin-right: 0px !important;
    }

    .logRegLayout .rgLyrWrpr .registerForm > .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-top: 0px !important;
    }

    .logRegLayout .rgLyrWrpr .registerForm > .row > div {
        padding: 0px !important;
    }

    .logRegLayout .rtDtlContainer .loginWrapper.regLoginWrapper {
        padding: 0px !important;
    }
    .logRegLayout .rtDtlContainer .loginWrapper.regLoginWrapper .RadioBox {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .logRegLayout .leftImgContainer {
        height: 100%;
        position: relative;
    }

    .logRegLayout img.vrtclogo {
        width: 85px;
        margin-left: 7px;
        margin-top: -56px;
    }

    .logRegLayout .leftImgContainer .logoContainer .mnLogo {
        position: absolute;
        top: 13px;
        left: 16px;
    }

    .logRegLayout .rtDtlContainer .loginWrapper {
        padding: 30px !important;
    }

    .loginWrapper .rdrLnk,
    .loginWrapper .rdrLnk a {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .loginWrapper .chkCntnr .MuiTypography-root,
    .loginWrapper .chkCntnr a {
        font-size: 13px;
    }

    .loginWrapper .customCheckbox .MuiListItem-button {
        font-size: 13px;
    }

    .customCheckbox .MuiFormControlLabel-root {
        margin-bottom: 0;
    }

    .logRegLayout .RadioBox .d-flex {
        flex-wrap: wrap;
    }

    .loginWrapper .registerForm .regType {
        margin-bottom: 10px;
    }

    .loginWrapper .customCheckbox .MuiListItem-button,
    .loginWrapper .customCheckbox .MuiListItem-button a {
        font-size: 12px;
    }

    /* .logRegLayout .rgLyrWrpr .MuiFormControlLabel-root {
        margin-right: 0;
    } */

    .loginWrapper .loginForm h3 {
        font-size: 31px;
    }
}
