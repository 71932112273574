:root {
    --fontFamily: 'Montserrat', sans-serif;
    --secFont: 'Poppins', sans-serif;
    --primColor: rgb(47, 107, 175);
    --secColor: rgb(248, 213, 72);
    --gradColor: linear-gradient(
        146deg,
        rgba(0, 24, 242, 1) 0%,
        rgba(208, 41, 253, 1) 64%,
        rgba(248, 39, 255, 1) 100%
    );
    --black: rgb(35, 35, 35);
    --headFont: 'Playfair Display', serif;
}

.primButton button .MuiButton-label,
.secButton button .MuiButton-label {
    font-weight: 600;
    text-transform: uppercase;
}

.primButton button {
    color: #fff;
    border-radius: 10px;
    background: var(--primColor);
    transition: 200ms all ease-in-out;
    box-shadow: none;
    border: 1px solid var(--primColor);
}

.primButton button:hover {
    color: var(--black);
    box-shadow: none;
    background: white;
    border: 1px solid var(--primColor);
}

.secButton button {
    color: var(--black);
    border-radius: 10px;
    background: var(--secColor);
    transition: 200ms all ease-in-out;
    border: 1px solid var(--secColor);
    box-shadow: none;
    border: none;
}

.secButton button:hover {
    background-color: white;
    border: 1px solid var(--secColor);
    box-shadow: none;
}

.primButton button,
.secButton button,
.tertButton button {
    height: 50px;
}

.primButton.small button,
.secButton.small button,
.tertButton.small button {
    height: 40px;
    min-width: max-content;
}

.primButton.large button,
.secButton.large button,
.tertButton.large button {
    height: 60px;
}

/* Code for Firefox */
::-moz-selection {
    color: #fff;
    background: rgb(25, 157, 229, 15);
}

::selection {
    color: #fff;
    background: rgb(25, 157, 229, 15);
}

.playFair {
    font-family: 'Playfair Display', serif;
}

@media (max-width: 500px) {
    .primButton.small button,
    .secButton.small button,
    .tertButton.small button {
        height: 40px;
        font-size: 13px;
    }

    .primButton button,
    .secButton button,
    .tertButton button {
        height: 45px;
        font-size: 14px;
    }

    .primButton.large button,
    .secButton.large button,
    .tertButton.large button {
        height: 50px;
        font-size: 14px;
    }
    .home .homeBanner .hbLt .bannerSearch input {
        height: 50px;
    }
}
