.dashboardInner .srchTopContainer {
    margin-top: 26px;
    margin-bottom: 25px;
}

.dashboardInner .srchTopContainer .customSelect {
    margin-bottom: 0;
}

.ryltyDashbrdWrapper .broadcasting span {
    color: #fff;
    padding-right: 5px;
}
.tfootTab .primButton {
    width: fit-content;
}
.ryltyDashbrdWrapper .broadcasting {
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    /* text-transform: uppercase; */
    background: linear-gradient(90deg, #2f6baf 0%, #7b61ff 100%);
    border-left: 5px solid #ffb100;
    border-radius: 6px;
    display: flex;
    align-items: flex-start;
    padding: 5px;
}

.dashboardInner .srchTopContainer .customSelect label,
.dashboardInner .srchTopContainer .customSelect .MuiSelect-select.MuiSelect-select {
    color: white;
    font-family: var(--fontFamily);
    font-weight: 500;
    font-size: 14px;
}

.dashboardInner .srchTopContainer .transaction .customSelect .MuiSelect-select.MuiSelect-select {
    width: 200px;
}

.dashboardInner .srchTopContainer .customSelect .MuiOutlinedInput-notchedOutline {
    border: none;
}

.dashboardInner .srchTopContainer .tabTitle .MuiButtonBase-root {
    width: max-content;
}

.dashboardInner .srchTopContainer .tabTitle .MuiButtonBase-root a,
.dashboardInner .srchTopContainer .tabTitle button {
    color: #d3d3d3;
    margin: 0 10px;
    font-weight: bold;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: 14px;
}

.dashboardInner .srchTopContainer .tabTitle .MuiButtonBase-root a.active,
.dashboardInner .srchTopContainer .tabTitle button.active {
    color: white;
}

.dashboardInner .srchTopContainer .tabTitle .MuiButtonBase-root a.active::after,
.dashboardInner .srchTopContainer .tabTitle button.active::after {
    content: '';
    height: 4px;
    width: 100%;
    background: white;
    position: absolute;
    bottom: -6px;
    left: 0;
}

.dashboardInner .srchTopContainer .customSelect .MuiSelect-select:not([multiple]) option,
.dashboardInner .srchTopContainer .customSelect .MuiSelect-select:not([multiple]) optgroup {
    color: black;
}

.dashboardInner .srchTopContainer .txt_one {
    font-size: 0.9375rem;
    font-weight: 500;
}
.dashboardInner .srchTopContainer .srchTxt {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 3px 0;
    margin-right: 8px;
}

.dashboardInner .srchTopContainer .rsltsCount {
    color: rgb(146, 146, 146);
}

.dashboardInner .srchTopContainer .svSrchBtn {
    width: 100%;
    max-width: max-content;
    height: max-content;
}

.dashboardInner .srchTopContainer .rtDiv .customSelect {
    max-width: 200px;
    width: 100%;
    margin-left: 10px;
    margin-bottom: 0;
}

.dashboardInner .InvoicesWrapper {
    background: white;
    border-radius: 10px;
    padding: 30px;
}

.dashboardInner .InvoicesWrapper .primButton button {
    height: 40px;
}

.dashboardInner .InvoicesWrapper .tableWrapper {
    margin-bottom: 45px;
}

.dashboardInner .table td:not(:last-child) {
    padding-top: 23px;
}

.dashboardInner .dashViewWrapper {
    background: white;
    padding: 30px;
    border-radius: 10px;
}

.dashboardInner .dashViewWrapper .maxWdWrpr {
    max-width: 800px;
    margin: auto;
    position: relative;
}

.dashboardInner .dashViewWrapper .maxWdWrpr .verifiedStateWrapper {
    position: absolute;
    right: 0;
    max-width: 220px;
    font-size: 12px;
    text-align: left;
    font-weight: 500;
}

.dashboardInner .dashViewWrapper .maxWdWrpr .verifiedStateWrapper .actnVrfdTxt {
    background: #0afdbd;
    border: 2.5px solid black;
    border-radius: 100px;
    font-size: 13px;
}

.dashboardInner .dashViewWrapper .maxWdWrpr .notverifiedStateWrapper {
    color: red;
    text-align: left;
    font-weight: 500;
    background: #ffdede;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 25px;
}

.dashboardInner .dashViewWrapper .maxWdWrpr .verifiedStateWrapper img {
    width: 130px;
}

.dashboardInner .dashViewWrapper .maxWdWrpr .notverifiedStateWrapper img {
    margin-right: 15px;
    width: 130px;
}

.dashboardInner .dashViewWrapper .maxWdWrpr .notverifiedStateWrapper .textCntnr {
    padding: 10px 5px;
}

.dashboardInner .dashViewWrapper .maxWdWrpr .notverifiedStateWrapper .textCntnr p {
    font-size: 14px;
    margin-bottom: 5px;
}

.dashboardInner .dashViewWrapper .maxWdWrpr .notverifiedStateWrapper .textCntnr .primButton {
    margin-left: unset;
    margin-bottom: 0;
    margin-top: 10px;
}

.dashboardInner .dashSubtitle {
    font-size: 18px;
    font-family: var(--secFont);
    font-weight: 600;
}

.dashboardInner .RadioBox .MuiTypography-root {
    font-size: 14px;
    font-weight: 600;
}

.grphContainer .customSelect .MuiFormControl-root::after {
    top: -7px;
}

.adBnkBtns {
    max-width: 315px;
    position: relative;
}

.adBnkBtns.singleBtn .primButton {
    left: 50%;
    right: initial;
    transform: translateX(-50%);
}

.adBnkBtns .primButton {
    position: absolute;
    bottom: 6px;
    max-width: 48%;
    width: 100%;
    left: 5px;
}

.adBnkBtns .primButton button {
    height: 35px !important;
    padding: 0;
}

.adBnkBtns .primButton button .MuiButton-label {
    font-size: 11px;
}

.adBnkBtns .primButton:last-of-type {
    right: 5px;
    left: unset;
}

.adBnkBtns.stcBtn .primButton {
    left: 26%;
    right: unset;
}

/*mainDashboard CSS*/
.ryltyDashbrdWrapper {
    padding-bottom: 35px;
}

.ryltyDashbrdWrapper .topActDtls .dshCardCntnr {
    background-color: white;
    position: relative;
    padding: 23px;
    border-radius: 10px;
    height: max-content;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
}

.ryltyDashbrdWrapper .topActDtls .dshCardCntnr .imgContainer {
    margin-right: 10px;
}

.ryltyDashbrdWrapper .topActDtls .dshCardCntnr h3 {
    font-family: var(--secFont);
    font-weight: bold;
    font-size: 24px;
    color: var(--primColor);
}

.ryltyDashbrdWrapper .topActDtls .dshCardCntnr:nth-child(2) h3 {
    color: #7b61ff;
}

.ryltyDashbrdWrapper .topActDtls .dshCardCntnr:nth-child(3) h3 {
    color: #31d1b4;
}

.ryltyDashbrdWrapper .topActDtls .dshCardCntnr:nth-child(4) h3 {
    color: #f8d548;
}

.ryltyDashbrdWrapper .topActDtls .dshCardCntnr p {
    font-size: 12px;
    font-weight: 500;
    height: 36px;
}

.ryltyDashbrdWrapper .topActDtls {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 15px;
    padding: 60px 0px;
}

.ryltyDashbrdWrapper .compareContainer .primButton button {
    height: 56px;
}

.ryltyDashbrdWrapper .compareContainer .resetButton {
    color: var(--primColor);
    font-weight: 600;
}

.ryltyDashbrdWrapper .compareContainer .mnLbl,
.ryltyDashbrdWrapper .myOwnShares .mnLbl {
    font-size: 14px;
    font-weight: 700;
}

.ryltyDashbrdWrapper .myOwnShares .songCardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 15px;
}

.ryltyDashbrdWrapper.publicProfile .topActDtls {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1100px;
    margin: 0 auto;
    transform: translateY(110px);
}

.ryltyDashbrdWrapper .myOwnShares .songCardGrid .ownSngCard {
    padding: 15px;
    border-radius: 18px;
    /* border: 1px solid #e5e5e5; */
    box-shadow: 0 0 10px #00000017;
}

.ryltyDashbrdWrapper .myOwnShares .songCardGrid .ownSngCard .topCrdCntss {
    display: flex;
    align-items: flex-start;
}

.ryltyDashbrdWrapper .myOwnShares .songCardGrid .ownSngCard .btmCrdCntnr {
    margin-top: 10px;
}

.ryltyDashbrdWrapper .myOwnShares .songCardGrid .ownSngCard .btmCrdCntnr .grdVlBx {
    padding: 15px 0;
}

.ryltyDashbrdWrapper .myOwnShares .songCardGrid .ownSngCard .btmCrdCntnr .grdVlBx:not(:last-child) {
    border-bottom: 1px solid rgb(230, 230, 230);
}

.ryltyDashbrdWrapper .myOwnShares .songCardGrid .ownSngCard .btmCrdCntnr .grdVlBx h3 {
    color: var(--primColor);
}

.ryltyDashbrdWrapper .myOwnShares .songCardGrid .ownSngCard .btmCrdCntnr h3 {
    font-size: 22px;
    margin-bottom: 2px;
    font-family: 'Poppins';
}

.ryltyDashbrdWrapper .myOwnShares .songCardGrid .ownSngCard .btmCrdCntnr small {
    font-size: 70%;
}

.ryltyDashbrdWrapper .myOwnShares .songCardGrid .ownSngCard img {
    width: 56px;
    height: 56px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 15px;
}

.ryltyDashbrdWrapper .myOwnShares .songCardGrid .ownSngCard h4 {
    font-size: 14px;
    font-weight: 700;
    font-family: var(--secFont);
    margin-bottom: 5px;
}

.ryltyDashbrdWrapper .myOwnShares .songCardGrid .ownSngCard p {
    color: #686868;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 0;
}

.cntnrWrpr .grphContainer {
    background: white;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    padding: 0;
}

.ryltyDashbrdWrapper .grphContainer .ddVal {
    min-width: 160px;
}

.cntnrWrpr .grphContainer .tbHdr {
    padding: 19px 24px 12px 24px;
    border-bottom: 1px solid #e5e5e5;
}

.cntnrWrpr .grphContainer .tbHdr p {
    font-size: 16px;
    color: #686868;
    margin: 0;
    font-weight: 500;
}

.cntnrWrpr .grphContainer .tbHdr .customSelect {
    margin-bottom: 0;
}

.cntnrWrpr .grphContainer .tbHdr .customSelect select {
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    color: #686868;
}

.cntnrWrpr .grphContainer .tbHdr .customSelect .MuiOutlinedInput-notchedOutline {
    border: none;
}

.dshbrdTransTable {
    margin-top: 25px;
}

.dshbrdTransTable .dashTableWrpr {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 10px;
}

/*new css*/
.dashboard .dashboardInner {
    /* background: #fff; */
    padding: 15px 35px;
    margin-top: 5px;
}

.dashboard .dashboardInner.myAuctions .atnStsTabs {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard .dashboardInner.myAuctions .atnStsTabs .MuiButtonBase-root {
    max-width: 192px;
    padding: 0;
    height: 45px;
}
.dashboard .dashboardInner.myAuctions .atnStsTabs .MuiButtonBase-root a {
    width: 100%;
    height: 100%;
    background: #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--black);
}

.dashboard .dashboardInner.myAuctions .atnStsTabs .MuiButtonBase-root a.active,
.dashboard .dashboardInner.myAuctions .atnStsTabs .MuiButtonBase-root a:hover {
    background: var(--black);
    color: white;
}

.dashboard .dashboardInner.notifications {
    max-width: 80%;
    margin: auto;
}

.dashboard .dashboardInner.myAuctions .searchMiscFilters {
    margin-top: -40px;
}

.transctnTab .atnStsTabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}

.transctnTab .atnStsTabs .MuiButtonBase-root {
    max-width: 170px;
    padding: 0;
    height: 45px;
    background: #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--black);
    font-size: 0.95rem;
}

.transctnTab .atnStsTabs .MuiButtonBase-root.active,
.transctnTab .atnStsTabs .MuiButtonBase-root:hover {
    background: var(--black);
    color: white;
}

.dtPicker .MuiFormControl-root.customDatepicker {
    margin-bottom: 0;
}

.trkGrd {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 15px;
}

.trkGrd .trdCard {
    background: white;
    box-shadow: 0 0 10px #32333526;
}

.trkGrd .trdCard .imgWrpr {
    width: 100%;
    height: 140px;
}

.trkGrd .trdCard .imgWrpr img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.trkGrd .trdCard .btmCnt label {
    color: gray;
    font-size: 14px;
}

.trkGrd .trdCard .btmCnt p {
    font-weight: bold;
    font-size: 14px;
}

/*old Css*/

.dashboard {
    background: var(--backgroundColor);
    /* padding-top: 30px; */
    /* padding-bottom: 30px; */
}

.dashboard .customBreadcrumbs {
    margin-bottom: 10px;
}
.dashboard .dashboardRt .maTitle {
    padding: 15px 30px;
    background: #fff;
}

.sideNav .userAvatarProfile {
    background: #fff;
    padding: 35px 0 20px 0px;
    border-bottom: 1px solid #f1f3f6;
}

.sideNav ul {
    background: #fff;
    padding: 20px 0 10px 20px;
}

.sideNav .userAvatarProfile .material-icons {
    font-size: 100px;
    color: #c7c7c7;
}

.sideNav .userAvatarProfile img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: contain;
}

.sideNav .userAvatarProfile h4 {
    font-size: 18px;
    color: #373737;
    margin: 15px auto;
    word-break: break-all;
    padding: 0 10px;
}

.sideNav hr {
    margin-bottom: 25px;
    background-color: #f1f3f6;
}

.sideNav h5 {
    font-size: 15px;
    font-weight: 600;
    color: var(--primColor);
    text-align: start;
    padding-top: 15px;
}

.sideNav ul {
    /* padding-inline-start: 0; */
    margin: 0;
}

.sideNav ul a,
.sideNav ul .MuiListItem-button {
    width: 100%;
    height: 100%;
    display: block;
    color: #979797 !important;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.sideNav ul .MuiListItem-button {
    height: 60px;
    padding: 0;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.sideNav ul a,
.sideNav ul .test {
    padding: 8px 16px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 100%;
}

.sideNav ul a span,
.sideNav ul .MuiListItem-button span {
    padding-inline-end: 10px;
}

.sideNav ul .MuiCollapse-wrapperInner {
    padding-inline-start: 35px;
}

.sideNav ul a:hover {
    text-decoration: none;
}

.sideNav ul > .MuiListItem-button a.active,
.sideNav ul .activeDropdown {
    color: var(--primColor) !important;
    background: #e8f7ff;
}

.sideNav ul .MuiListItem-button a.active {
    color: var(--primColor) !important;
}

.dashboard .dashboardLt .gridListToggle button {
    color: var(--secColor);
    width: 50%;
}

.dashboard .dashboardLt .gridListToggle button.active {
    color: var(--primColor);
    background: #fff;
}

.dashboard .dashboardLt .gridListToggle i {
    padding-inline-end: 10px;
}

.dashTitle {
    font-size: 20px;
    font-weight: 700;
    color: #0e131f;
    text-align: start;
    text-transform: uppercase;
    margin-bottom: 0;
}

.dashTitle span {
    font-size: 12px;
    font-weight: 600;
    color: #999999;
    padding-inline-start: 0px;
    text-transform: initial;
    display: block;
}

.myAccount .accountCnt {
    /* background: #fff; */
    border-radius: 5px;
    width: 100%;
}

.myAccount .accChild h4 {
    text-align: start;
    font-size: 18px;
    font-weight: 600;
    margin: 25px 0;
}

.myAccount .primButton,
.notifications .primButton {
    max-width: 200px;
    margin: 20px auto;
}

.myAccount .accountCnt .crActBtn .primButton {
    margin: 0 0 15px 0;
    min-width: 150px;
}

.myAccount .accountCnt .crActBtn .secButton {
    min-width: 150px;
}

.myAccount .userProfileImage {
    position: relative;
}

.myAccount .userProfileImage .noUserImage {
    font-size: 160px;
    color: #c7c7c7;
}

.myAccount .userProfileImage .editUserImage {
    width: 30px;
    height: 30px;
    min-width: auto;
    border-radius: 50%;
    background: var(--primColor);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 25px;
    right: 20px;
    font-size: 18px;
    color: #fff;
    box-shadow: 0px 3px 6px rgba(25, 157, 229, 40%);
}

.mySavedCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    gap: 15px;
}

.mySavedCards .emptySavedCard {
    border: 1px dashed #a6adb4;
    border-radius: 5px;
    padding: 25px;
    cursor: pointer;
}

.mySavedCards .emptySavedCard h4 {
    font-size: 16px;
    text-transform: uppercase;
    color: #4d4d4d;
    font-weight: 600;
}

.mySavedCards .filledSavedCard {
    height: 175px;
    border-radius: 5px;
    background: url('../../assets/svg/cardBg.svg'),
        linear-gradient(#5a5a5a 0%, #323335 49.33%, #000 100%);
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.3);
    position: relative;
    padding: 1rem 0.75rem;
    color: #fff;
}

.mySavedCards .modifyCard {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.mySavedCards .modifyCard .material-icons {
    font-size: 18px;
}

.mySavedCards .modifyCard.edit {
    background: #5d80ff9c;
    color: #fff;
}

.mySavedCards .modifyCard.delete {
    background: #ff5d5d9c;
    color: #fff;
    margin-inline-start: 10px;
}

.mySavedCards .card-num {
    padding: 26px 0 15px;
    text-align: center;
}

.mySavedCards .card-num span {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    line-height: 1;
    margin: 5px 0;
}

.mySavedCards .card-num span + span {
    margin-inline-start: 15px;
}

.mySavedCards .fs-12 {
    font-size: 0.75rem;
    text-align: start;
}

.mySavedCards .custom-modal .modal-dialog.modal-lg {
    max-width: 400px;
}

.auctionStatus {
    display: none;
}

.auctionStatus .MuiListItem-root {
    padding: 0;
    width: max-content;
    margin-bottom: 25px;
    border-radius: 50px;
}

.auctionStatus .MuiListItem-root a {
    padding: 10px 25px;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 50px;
    color: #000;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.auctionStatus .MuiListItem-root a:focus,
.auctionStatus .MuiListItem-root a:hover {
    text-decoration: none;
}

.auctionStatus .MuiListItem-root:not(:last-child) {
    margin-inline-end: 16px;
}

.auctionStatus .MuiListItem-root a.active {
    color: var(--primColor);
    background: rgb(25 157 229 / 12%);
    border-color: rgb(25 157 229);
}

.companyProfile .MuiPaper-root.MuiAppBar-root {
    color: #333;
    box-shadow: 0px 2px 2px #ccc;
    background: #f7f7f7;
    margin-top: 20px;
}

.companyProfile .MuiPaper-root.MuiAppBar-root .MuiTab-root.Mui-selected {
    color: var(--primColor);
    background: #199de517;
}

.companyProfile .MuiPaper-root.MuiAppBar-root .MuiTabs-indicator {
    background: var(--primColor);
}

.companyProfile .MuiBox-root {
    padding-top: 30px;
}

.companyProfile .catgDet > div {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.companyProfile .catgDet .customCheckbox {
    width: 25%;
}

.dashboard .walletHead {
    margin-bottom: 30px;
}

.dashboard .walletBalance {
    background: rgb(25 157 229 / 11%);
    border: 1px dashed var(--primColor);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--primColor);
    text-align: start;
    margin-inline-end: auto;
    width: max-content;
    display: flex;
    align-items: center;
}

.dashboard .walletBalance .material-icons {
    padding-inline-end: 5px;
}

.dashboard .walletBalance span:last-child {
    padding-inline-start: 10px;
}

.dashboard h5 {
    font-size: 18px;
    text-align: start;
    margin-bottom: 20px;
    color: var(--secColor);
}

.dashboard .notifications .dashSubtitle,
.dashboard .mySavedSearches .dashSubtitle {
    font-size: 16px;
    color: #212529;
    line-height: 24px;
    margin-bottom: 2rem;
    text-align: center;
}

.dashboard .mySavedSearches table thead tr th:last-child {
    text-align: center;
}

.dashboard .mySavedSearches table .ssActBtn button:first-child {
    color: var(--primColor);
}

.dashboard .mySavedSearches table .ssActBtn button:last-child {
    color: #ff6c6c;
}

.dashboard .tableWrapper {
    width: 100%;
}

.myInvoices .tableWrapper td:last-child {
    width: 200px;
}

.verifyEmail {
    display: flex;
    /* justify-content: space-between; */
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    background: #353535;
    background: #ff900770;
    padding: 30px 20px;
    border-radius: 10px;
}

.verifyEmail > img {
    width: 60px;
    height: auto;
    margin-right: 20px;
}

.verifyEmail h2 {
    text-align: left;
    font-size: 18px;
    color: #7b4d13;
    width: 100%;
    font-weight: 600;
}

.verifyEmail .secButton {
    margin: 0 0 0 15px;
}

.verifyEmail button {
    width: 150px;
    background: #ffdfb9;
}

.verifyEmail > div {
    /* width: 100%; */
    margin-right: auto;
}

/* .savedCardsCnt,
.savedBankCnt {
    margin-bottom: 20px;
} */

.setmxBdForm .primButton {
    margin-top: 0;
    margin-left: unset;
}

.setmxBdForm .primButton button {
    height: 56px;
}

.mxBdClick {
    color: var(--primColor);
    cursor: pointer;
}

.myAccount .swtchTabs p.activeTab {
    color: var(--primColor);
}

.myAccount .swtchTabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.myAccount .swtchTabs p {
    color: black;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.myAccount .swtchTabs p:first-child {
    margin-right: 15px;
}

.ttlVlWrpr h4 {
    margin: 0 !important;
    font-size: 20px !important;
    font-weight: bold !important;
}

.ttlVlWrpr h4 span {
    font-size: 14px;
    /* color: white !important; */
}

.ttlVlWrpr .icnWlWrpr {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.ttlVlWrpr .icnWlWrpr .material-icons {
    font-size: 28px;
}

.dashboardInner.myAccount .dashSubtitle .MuiSvgIcon-root {
    color: var(--primColor);
}

.prflAlrtPopup {
    text-align: center;
}

.prflAlrtPopup .icnCrclCntnr {
    width: 66px;
    height: 66px;
    border-radius: 100px;
    background: rgb(20, 20, 20);
    color: var(--secColor);
    font-size: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    padding: 0;
    position: relative;
}

.prflAlrtPopup .icnCrclCntnr .material-icons {
    font-size: 80px;
    position: relative;
    top: -7px;
}

.prflAlrtPopup h4 {
    font-size: 22px;
    margin-bottom: 34px;
}

.prflAlrtPopup .btnStack {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.prflAlrtPopup .btnStack .secButton,
.prflAlrtPopup .btnStack .primButton {
    width: 42%;
}

.prflAlrtPopup .btnStack .secButton button {
    background: gray;
    color: white;
}

.ryltyDashbrdWrapper .grphContainer .grphHdr {
    text-transform: capitalize;
}

.ryltyDashbrdWrapper .collapseButton.MuiIconButton-root {
    padding: 4px;
}

.payInfoTab .pyGridWrpr {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
    gap: 20px;
}

.payInfoTab .inPyWrpr {
    border: 1px solid #e7e7e7;
    padding: 14px;
    padding-top: 0;
    border-radius: 10px;
    height: 100%;
}

.payInfoTab .inPyWrpr .hdrPyWrpr {
    border-bottom: 1px solid #d3d4d4;
    padding: 14px 0;
    margin-bottom: 20px;
}

.payInfoTab .inPyWrpr .hdrPyWrpr h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.payInfoTab .savedBank {
    position: relative;
}

.payInfoTab .savedBank .actnBtnWrpr {
    position: absolute;
    width: 100%;
    bottom: -49px;
    left: 0;
}

.payInfoTab .savedBank .actnBtnWrpr .primButton button {
    padding-left: 10px;
    padding-right: 10px;
}

.payInfoTab .savedBank .actnBtnWrpr .primButton button .MuiButton-label {
    font-size: 11px;
}

.payInfoTab .savedBank .actnBtnWrpr .primButton .material-icons {
    color: white;
    font-size: 13px;
}

.payInfoTab .savedBank .actnBtnWrpr .primButton:hover .material-icons {
    color: var(--primColor);
}

.ryltyDashbrdWrapper .dshbrdTransTable .dashTableWrpr table tr td:not(:first-child),
.ryltyDashbrdWrapper .dshbrdTransTable .dashTableWrpr table tr th:not(:first-child) {
    text-align: center;
}

p.placeHldrTxt {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    top: 46%;
    color: gray;
    font-weight: 600;
}

.userStatusWrapper {
    padding: 20px;
    background: #fff4f4;
    border-radius: 10px;
    margin-bottom: 30px;
}

.userStatusWrapper h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 25px;
}

.userStatusWrapper ul {
    position: relative;
    list-style: none;
    padding-left: 60px;
}

.userStatusWrapper ul::before {
    content: '';
    width: 1px;
    height: 100%;
    background: #d8d8d8;
    position: absolute;
    left: 17px;
    top: -7px;
}

.userStatusWrapper ul li {
    font-size: 15px;
    margin-bottom: 10px;
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
}

.userStatusWrapper ul li .lstPrgrs {
    position: absolute;
    left: -54px;
    top: 9px;
}

.userStatusWrapper ul li .circle {
    content: '';
    width: 22px;
    height: 22px;
    background: white;
    border-radius: 100px;
    border: 1px solid gray;
}

.userStatusWrapper ul li .primButton {
    margin: unset;
}

.userStatusWrapper ul li .primButton button {
    background: var(--primColor);
    color: white;
    margin-left: 20px;
    padding: 10px;
    height: 32px;
}

.userStatusWrapper ul li .primButton button .MuiButton-label {
    text-transform: initial;
}

.userStatusWrapper ul li .material-icons {
    color: #4db401;
    background: white;
    border-radius: 100px;
}

.upldActnFld .primButton {
    margin: unset;
    max-width: 300px;
}

.ownrDtlsWrpr h4.dashSubtitle {
    font-size: 21px;
}

.ownrDtlsWrpr .ownDtlsDescrp {
    font-size: 14px;
}

.ownrDtlsWrpr .bckToProfl {
    color: var(--primColor);
}

.ownrDtlsWrpr .ownCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--primColor);
    border-radius: 10px;
    padding: 8px 10px;
}

.ownrDtlsWrpr .ownCard p {
    margin: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.ownrDtlsWrpr .ownCard p .material-icons {
    margin-left: 5px;
}

.ownrDtlsWrpr .ownCard .btnWrpr {
    display: flex;
    align-items: center;
}

.ownrDtlsWrpr .ownCard .btnWrpr button {
    width: 40px;
    height: 40px;
    min-width: unset;
    margin-left: 10px;
}

.ownrDtlsWrpr .ownCard .btnWrpr button.edtBtn {
    background: #daeafd;
    color: var(--primColor);
}

.ownrDtlsWrpr .ownCard .btnWrpr button.dltBtn {
    background: #ffd8d8;
    color: #cb4444;
}

.ownrDtlsWrpr .ownCard .verifyIcon {
    color: #4db401;
}

.ownrDtlsWrpr .ownCard .unVerifyIcon {
    color: #ff2d2d;
}

.ownrDtlsWrpr .ownCard.unverified {
    background: #fff0f0;
}

.ownrDtlsWrpr .ownCardGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 28px;
}

.ownrDtlsWrpr .admreWrpr .primButton {
    margin: unset;
    max-width: 250px;
    margin-left: auto;
}

.ownrDtlsWrpr .admreWrpr .primButton .material-icons {
    margin-right: 9px;
}

.ownrDtlsWrpr .btmChkBxWrpr {
    border-top: 1px solid #e7e7e7;
    margin-top: 40px;
}

.ownrDtlsWrpr .btmChkBxWrpr .actnBtnws {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ownrDtlsWrpr .btmChkBxWrpr .actnBtnws .primButton {
    margin: unset;
    margin-left: 20px;
}

.ownrDtlsWrpr .btmChkBxWrpr .actnBtnws .secButton button {
    border: 1px solid var(--primColor);
    color: var(--primColor);
    background: transparent;
}

.userStatusWrapper .statusChip {
    padding: 5px 15px;
    height: 32px;
    margin-left: 20px;
}

.userStatusWrapper .statusChip.notApproved {
    background: #b40123;
    color: white;
    border-radius: 100px;
}

.userStatusWrapper .statusChip.approved {
    background: #4db401;
    color: white;
    border-radius: 100px;
}

p.unVrfdAlrt {
    font-size: 15px;
    padding-left: 60px;
    line-height: 36px;
    color: #b40123;
}

p.unVrfdAlrt a {
    color: #b40123;
}

.userStatusWrapper.userVerified {
    background: #edffe0;
}

.prflAlrtPopup .ssnFld .customInput {
    margin-top: 0px !important;
}

.prflAlrtPopup .btmBtns .primButton {
    max-width: 150px;
    margin-left: auto;
}

.prflAlrtPopup .btnPrmBtn .primButton {
    max-width: 350px;
    width: 100%;
}

.infoDecTxt {
    padding: 3px 10px;
    border: 1px solid var(--primColor);
    border-radius: 100px;
    display: flex;
    align-items: center;
    background: #dfeeff;
}

.infoDecTxt span {
    font-size: 12px;
    font-weight: normal;
    text-align: left;
}

.infoDecTxt svg {
    margin-right: 10px;
    color: var(--primColor);
}

.ttleTxt {
    white-space: pre;
}

.removeCard {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #f6f6f699;
    color: #cf3232;
    font-weight: 600;
}

.publicProfile .songCardContainer {
    margin-top: 100px;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    gap: 15px;
}

.ryltyDashbrdWrapper.publicProfile {
    position: relative;
}

.publicProfileUrl {
    margin-bottom: 25px;
}

.publicProfileUrl .ppInner {
    padding: 10px 15px;
    background: rgb(214 233 255 / 36%);
    color: rgb(20 67 120);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px dashed #91b5de;
    border-radius: 10px;
}

.publicProfileUrl button {
    min-width: 35px;
    height: 35px;
    border-radius: 50%;
    color: rgb(20 67 120);
}

.publicProfileUrl p {
    color: green;
    font-weight: 600;
    margin-top: 3px;
    font-size: 15px;
}

.ownershipGrid .gridCard .pcgImg img {
    min-height: 430px;
    aspect-ratio: initial;
}

.liveNowCnt {
    display: grid;
    grid-template-columns: repeat(4, minmax(270px, 1fr));
    grid-gap: 25px;
    gap: 32px;
    margin: 72px 0px;
}

.liveNowCnt .cardliveNow {
    max-width: 350px;
    width: 100%;
    /* background-color: white; */
}

.liveNowCnt .cardImgCnt {
    width: 100%;
    /* height: 254px; */
    position: relative;
    border-radius: 10px;
}
.liveNowCnt .live-badge {
    position: absolute;
    font-size: 14px;
    top: 12px;
    font-weight: 600;
    left: 12px;
    background-color: red;
    padding: 3px 12px;
    border-radius: 30px;
    color: white;
}
.liveNowCnt .card-image {
    width: 100%;
    height: 100%;
    min-height: 254px;
    object-fit: cover;
    border-radius: 10px;
    display: block;
    aspect-ratio: 1 / 1;
}

.user-info {
    padding: 2px 14px 2px 4px;
    border-radius: 30px;
    background: white;
    display: flex;
    gap: 8px;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    position: absolute;
    bottom: 12px;
    left: 12px;
}

.user-avatar {
    height: 22px;
    width: 22px;
    border-radius: 50%;
}
.timerShareCnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin-top: 8px;
}
.timerShareCnt .platform {
    background: #ededed;
    border-radius: 14px;
    padding: 8px;
}

.offeringAuctionCnt {
    justify-content: center !important;
    gap: 16px !important;
}

.artistsNowCnt .user-info {
    padding: 6px 12px;
    border-radius: 14px;
}

/* Artists page  */

.singleArtistMainCnt {
    margin-top: 240px;
    padding: 24px 24px 24px 48px;
    display: flex;
    flex-direction: column;
    gap: 56px;
}

.singleArtistBg {
    height: 300px;
}

.sectionCnt h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
}

/* Header Styles start */

.artistPcImg {
    width: 270px;
    height: 270px;
    min-height: 270px;
    min-width: 270px;
    padding: 6px;
    border-radius: 50%;
    background: white;
}
.artistPcImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.artistsHeaderCnt {
    gap: 32px;
}

.artistsHeaderDescCnt {
    margin-top: 24px;
}

.artistsHeaderContent {
    margin-bottom: 24px;
}

.artistsHeaderContent h4 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
}
.artistsHeaderContent span {
    font-size: 16px;
    font-weight: 500;
}

.artistsHeaderLabelsCnt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}
.artistsHeaderLabelsCnt div {
    display: grid;
    grid-template-columns: 200px 1fr;
    font-weight: 500;
}
.artistsHeaderLabelsCnt div label {
    margin-bottom: 0px;

    color: #8a8a8a;
}
.artistsHeaderLabelsCnt div span {
}

/* Artists Stats styles */

.artistStatCardCnt {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: space-between;
}

.artistStatCard {
    min-width: 240px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: center;
    padding: 12px 16px;
    border-radius: 10px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
}

.artistStatCard > img {
    width: 100%;
}

.statsCardHeader {
    gap: 8px;
    height: fit-content;
    width: 100%;
    justify-content: flex-start;
}

.statsCardHeader div {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
}

.statsCardHeader img {
    width: 20px;
    height: auto;
}

.statsCardHeader span,
.statsCardHeader h4 {
    font-size: 14px;
    margin: 0;
}
.statsCardHeader h4 {
    font-weight: 700;
}

/* active offerings in songvest  */

.offeringHeaderCnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.offeringHeaderCnt .material-icons {
    color: #8d8d8d;
}

.artistActiveOffCardCnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.artistActiveOffCard {
    padding: 12px 16px;
    border-radius: 10px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
    min-width: 380px;
    position: relative;
}

.offerStatusSpan {
    background: red;
    padding: 4px 16px;
    border-radius: 30px;
    color: white !important;
    font-weight: 600 !important;
    position: absolute !important;
    top: 8px;
    right: 8px;
    font-size: 12px !important;
}

.offerCardHeader {
    display: flex;
    align-items: flex-start;
    gap: 16px;
}
.offerCardHeader h4 {
    margin-bottom: 6px;
    font-weight: 500;
}

.offerCardHeader h4,
.offerCardHeader span {
    font-size: 16px;
}

.offerCardHeader span {
    color: #686868;
    font-size: 14px;
}

.offerCardHeader img {
    border-radius: 4px;
    width: 72px;
    height: 70px;
}

.offerStreamsParent {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    margin-top: 16px;
    justify-content: space-between;
}
.offerStreamsParent span,
.offerStreamsParent h4,
.offerFooterLeftCnt span,
.offerFooterLeftCnt h4 {
    font-size: 14px;
}

.offerStreamsParent span,
.offerFooterLeftCnt span {
    color: #686868;
}

.offerFooterLeftCnt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.offerFooterLeftCnt > div {
    display: grid;
    grid-template-columns: 120px 100px;
}

.offerFooterParent {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.offerFooterBtn span {
    color: #2f6baf;
    font-weight: 600;
}
.offerFooterBtn label {
    margin: 0;
}

.offerFooterBtn .MuiButton-label > span {
    display: flex;
    align-items: center;
    gap: 0px;
}

.newsCardCnt {
    gap: 16px;
    justify-content: space-between;
}

.newsCardHeader {
    width: 100%;
}

.newsCardHeader span {
    font-size: 12px;
    color: #686868;
    font-weight: 700;
}

.artistNewsCard {
    padding: 12px 16px;
    border-radius: 10px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
    width: 380px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
}

.artistNewsCard p {
    text-align: center;
    margin-bottom: 0px;
    font-size: 14px;
}

/* Sold Item page styles */

.soldItemMainCnt {
    padding: 24px 24px 24px 48px;
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.soldHeaderCnt {
    display: flex;
    justify-content: space-between;
    padding: 20px 32px;
    border: 1px solid #2ebd59;
    border-radius: 10px;
    background: #f2fbf534;
    width: 100%;
}
.soldItemMainCnt button {
    color: white;
    font-weight: 500;
    background-color: #2f6baf;
    padding: 12px 32px;
    border-radius: 10px;
}
.soldItemMainCnt button .MuiButton-label {
    display: flex;
    align-items: center;
    gap: 12px;
}

.soldHeaderContent {
    gap: 10px;
}

.soldHeaderContent span {
    font-weight: 500;
}

.displaySold {
    display: none;
}
.soldDetailsCnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.soldDetailsCnt {
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    padding: 16px 32px;
}

.soldDetailsSmallerCnt > img {
    width: 200px;
    height: 190px;
}

.soldDetailsSmallerCnt .user-info {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.115);
    width: fit-content;
    left: 0px;
    bottom: 0px;
    margin-bottom: 8px;
    padding-right: 24px;
}
.soldDetailsSmallerCnt .user-info img {
    width: 48px;
    height: 48px;
}
.soldDetailsSmallerCnt .user-name {
    font-size: 16px;
}

.userInfoCnt {
    justify-content: space-between;
    height: 100%;
}

.InvestLiveCnt h4 {
    margin-bottom: 32px;
    font-weight: 700;
}
.InvestLiveCnt img {
    height: 510px;
}

.tableRecentCnt table thead th {
    font-size: 16px;
}

.tableRecentCnt h4 {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 20px;
}

.trackDetailsCnt h4 {
    font-weight: 700;
    margin-bottom: 32px;
}

.trackDetailsCnt {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.trackDetailCard {
    min-width: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    padding: 16px 16px 16px 24px;
}
.trackDetailsCnt > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow-x: auto;
    gap: 16px;
}

.trackDetailCard {
}

.trackDetailsCardPlays {
    margin-top: 30px;
    padding: 0 32px;
    width: 100%;
    justify-content: space-between;
}

.trackDetailsCardPlays h5 {
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 16px;
}
.trackDetailsCardPlays span {
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 0px;
    font-size: 14px;
}

.trackDetailsCardBottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 24px;
    width: 100%;
}
.trackDetailsCardBottom img {
    height: 28px;
}

.trackBottomCnt {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.trackBottomCnt h5 {
    margin-bottom: 5px;
    width: fit-content;
}
.trackBottomCnt img {
    width: fit-content;
}

.trackBottomCnt > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.invoiceBtn {
    width: 18%;
}
.invoiceBtn span {
    color: #2f6baf;
}

.soldOutSpan {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    padding: 12px 30px;
    background: red;
    color: white;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 600;
}

.someInfo h4 {
    font-size: 24px;
    font-weight: 600;
    color: rgb(47, 107, 175);
    margin-bottom: 4px;
}
.someInfo h2 {
    font-size: 40px;
    font-weight: 700;
    color: rgb(47, 107, 175);
    margin-bottom: 8px;
}

.someInfo p {
    margin-bottom: 0px;
}
.someInfo > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 1750px) {
    .InvestLiveCnt img {
        height: auto;
    }
}
@media (max-width: 1380px) {
    .InvestLiveCnt img {
        height: 80%;
    }
}

@media (max-width: 1400px) {
    .liveNowCnt {
        display: grid;
        grid-template-columns: repeat(3, minmax(270px, 1fr));
    }
}

@media (max-width: 1024px) {
    .respSort {
        display: none;
    }
    .auctionStatus {
        display: flex;
    }
    .searchMiscFilters {
        display: none !important;
    }

    .tableWrapper {
        overflow-y: scroll;
    }

    .tableWrapper .table {
        font-size: 13px;
    }

    .dashboard .dashboardInner.notifications {
        max-width: 100%;
    }

    .dashboard .notifications .dpTable table thead th {
        text-align: left;
        font-size: 15px;
    }

    .mySavedSearches .tableWrapper .table td,
    .mySavedSearches .tableWrapper .table th {
        white-space: pre;
        text-align: left;
    }
}

@media (max-width: 1000px) {
    .dashboardInner .tabTitle {
        margin: auto;
        flex-wrap: wrap;
        justify-content: center;
    }

    .dashboardInner .tabTitle button,
    .dashboardInner .tabTitle .MuiButtonBase-root {
        margin-top: 15px;
    }
}

@media (max-width: 940px) {
    .dashboardInner .cardGrid {
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    }

    .watchLstTopCntnr {
        display: none !important;
    }
}

@media (max-width: 900px) {
    .liveNowCnt {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width: 800px) {
    .ryltyDashbrdWrapper.publicProfile {
        padding-top: 250px;
    }
    .ryltyDashbrdWrapper.publicProfile .topActDtls {
        transform: none;
    }

    .ryltyDashbrdWrapper.publicProfile .topActDtls .dshCardCntnr {
        flex-wrap: wrap;
        justify-content: center;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 14%);
        padding: 15px;
    }

    .ryltyDashbrdWrapper.publicProfile .topActDtls .dshCardCntnr .imgContainer {
        margin-right: 0px;
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
    }
    .ryltyDashbrdWrapper.publicProfile .topActDtls .dshCardCntnr p {
        height: 35px;
        text-align: center;
        line-height: initial;
        margin-top: 5px !important;
    }
    .ryltyDashbrdWrapper.publicProfile .topActDtls .dshCardCntnr h3 {
        text-align: center;
        width: 100%;
    }
    .publicProfile .songCardContainer {
        margin-top: 50px;
        padding: 0;
    }
}

@media (max-width: 600px) {
    .dashboardInner .srchTopContainer .tabTitle .MuiButtonBase-root a,
    .dashboardInner .srchTopContainer .tabTitle button {
        color: #d3d3d3;
        margin: 0 7px;
        font-weight: bold;
        width: 100%;
        height: 100%;
        text-decoration: none;
        font-size: 13px;
    }

    .ryltyDashbrdWrapper .topActDtls,
    .ryltyDashbrdWrapper .pdtsSctn {
        margin-top: 28px;
    }

    .ryltyDashbrdWrapper .compareContainer {
        margin-top: 23px;
    }

    .ryltyDashbrdWrapper .grphContainer .tbHdr p {
        margin-bottom: 12px;
    }

    .acrdnWrpr {
        margin-bottom: 25px;
    }

    .dashboardInner .srchTopContainer {
        padding-top: 25px !important;
        flex-wrap: wrap;
    }

    .dashboardInner .srchTopContainer .rtDiv {
        flex-wrap: wrap;
        justify-content: space-between !important;
    }

    .dashboardInner .srchTopContainer .rtDiv .customSelect {
        width: 100%;
        max-width: unset;
        margin: 10px 0;
    }

    .dashboardInner .srchTopContainer .rtDiv .customSelect:not(:first-child) {
        width: 48%;
    }

    .dashboardInner .srchTopContainer .customSelect label,
    .dashboardInner .srchTopContainer .customSelect .MuiSelect-select.MuiSelect-select {
        max-width: 103px;
    }

    .verifyEmail {
        flex-wrap: wrap;
    }

    .verifyEmail img {
        margin-bottom: 15px;
    }

    .verifyEmail h2 {
        font-size: 16px;
        line-height: 24px;
    }

    p.placeHldrTxt {
        top: 53%;
        width: 40%;
        font-size: 12px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 545px) {
    .auctionStatus .MuiListItem-root a {
        padding: 10px 19px;
        height: 40px;
        font-size: 15px;
        line-height: 1.2;
    }
    .dashTitle {
        font-size: 18px;
    }
    .dashboard .dashboardRt .maTitle {
        padding: 5px 15px;
    }
    .dashboard .myAuctions,
    .dashboard .myInvoices,
    .dashboard .mySavedCards,
    .dashboard .myAccount,
    .dashboard .myWallet {
        padding: 0px;
    }
    .myAccount .userProfileImage .noUserImage {
        font-size: 120px;
    }
    .myAccount .accChild h4 {
        font-size: 16px;
        margin: 0;
        margin-bottom: 20px;
    }
    .myAccount .primButton,
    .notifications .primButton {
        max-width: 100%;
        margin: 10px auto 20px;
    }
    .dashboardRt.customContainer {
        padding-top: 25px !important;
    }

    .dashboard .dashboardInner.myAuctions .atnStsTabs .MuiButtonBase-root a {
        font-size: 14px;
        text-align: center;
    }

    .dashboard .dashboardInner {
        padding: 0;
    }

    .ryltyDashbrdWrapper .topActDtls {
        grid-template-columns: 100% !important;
    }
}

@media (max-width: 500px) {
    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-inline-end: 5px;
    }
}

@media (max-width: 470px) {
    .auctionStatus .MuiListItem-root a {
        padding: 10px 15px;
        height: 40px;
        font-size: 13px;
        line-height: 1.4;
    }
}

@media (max-width: 420px) {
    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-inline-end: 5px;
    }
    .auctionStatus .MuiListItem-root {
        margin-bottom: 5px;
    }

    .auctionStatus {
        margin-bottom: 10px;
    }
}

@media (max-width: 385px) {
    .auctionStatus .MuiListItem-root a {
        padding: 10px 19px;
        font-size: 13px;
    }
    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-inline-end: 5px;
    }
    .auctionStatus {
        flex-wrap: wrap;
    }
}

@media (max-width: 340px) {
    .auctionStatus .MuiListItem-root a {
        font-size: 12px;
    }
    .auctionStatus .MuiListItem-root {
        margin-bottom: 15px;
    }
}

.dashboardRt.customContainer {
    background: #f5f5f5;
    min-height: 80vh;
}

.ryltyDashbrdWrapper {
    width: 100%;
}

.ryltyDashbrdWrapper .pdtsSctn {
    padding: 0 60px;
}

.crdGrdWrpr {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 25px;
}

.savedCards {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); */
    grid-template-columns: 1fr;
    gap: 20px;
    width: 100%;
}

.savedCards .savedCardsCard {
    background: url('../../assets/cardBackground.png');
    width: 100%;
    max-width: 310px;
    height: 180px;
    border-radius: 4px;
    position: relative;
    padding-top: 92px;
    padding-left: 24px;
    padding-right: 24px;
    color: white;
}

.savedCards .savedCardsCard h4 {
    font-size: 20px;
}

.savedCards .savedCardsCard .edtBtn {
    color: gray;
}

.savedCards .savedCardsCard .dltBtn {
    color: #ff2d2d;
    position: absolute;
}

.tblTabHdr {
    padding: 6px 15px;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 14px;
}

.ryltyDashbrdWrapper .myOwnShares .tblTabHdr .mnLbl {
    font-size: 16px;
    color: #686868;
    margin: 0;
    font-weight: 500;
}

@media (max-width: 1400px) {
    .ryltyDashbrdWrapper .topActDtls {
        grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    }
}

@media (max-width: 1300px) {
    .mobVsFltr.searchMiscFilters {
        margin-top: 15px !important;
        flex-direction: row !important;
    }

    .mobVsFltr.searchMiscFilters .gridListToggle {
        margin-bottom: 0 !important;
    }
}

@media (max-width: 950px) {
    .dashboardNav {
        display: none !important;
    }

    .ryltyDashbrdWrapper .topActDtls,
    .ryltyDashbrdWrapper .pdtsSctn {
        padding: 0px;
    }

    .ryltyDashbrdWrapper .cntnrWrpr {
        padding: 0px;
    }

    .ryltyDashbrdWrapper .grphContainer {
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: 10px;
    }

    .ryltyDashbrdWrapper .grphContainer > div {
        flex-wrap: wrap;
    }

    .ryltyDashbrdWrapper .grphContainer .col-12 {
        padding: 0 !important;
    }

    .mobVsFltr.searchMiscFilters {
        display: flex !important;
        margin-top: 15px !important;
    }

    .mobVsFltr.searchMiscFilters .gridListToggle {
        display: none;
    }

    .tableWrapper table tr th {
        white-space: pre;
    }
}

@media (max-width: 800px) {
    .dashboardInner .dashViewWrapper .maxWdWrpr .verifiedStateWrapper {
        top: -50px;
        right: -45px;
    }

    .dashboardInner .dashViewWrapper .maxWdWrpr .verifiedStateWrapper img {
        width: 100px;
    }

    .dashboardInner .dashViewWrapper .maxWdWrpr .notverifiedStateWrapper {
        flex-wrap: wrap;
    }
}
