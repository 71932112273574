.srchContainer .srchTopContainer {
    margin-top: 26px;
    margin-bottom: 15px;
}

.srchContainer .srchTopContainer .customSelect {
    margin-bottom: 0;
}

.srchContainer .srchTopContainer .customSelect label,
.srchContainer .srchTopContainer .customSelect .MuiSelect-select.MuiSelect-select {
    color: white;
    font-family: var(--fontFamily);
    font-weight: 500;
    font-size: 14px;
}

.srchContainer .srchTopContainer .customSelect .MuiOutlinedInput-notchedOutline {
    border: none;
}

.srchContainer .srchTopContainer .tabTitle button {
    color: #d3d3d3;
    margin: 0 10px;
    font-weight: bold;
    font-size: 14px;
}

.srchContainer .srchTopContainer .tabTitle button.active {
    color: white;
}

.srchContainer .srchTopContainer .tabTitle button.active::after {
    content: '';
    height: 4px;
    width: 100%;
    background: white;
    position: absolute;
    bottom: -6px;
    left: 0;
}

.srchContainer .srchTopContainer .customSelect .MuiSelect-select:not([multiple]) option,
.srchContainer .srchTopContainer .customSelect .MuiSelect-select:not([multiple]) optgroup {
    color: black;
}

.srchContainer .srchTopContainer .txt_one {
    font-size: 0.9375rem;
    font-weight: 500;
}
.srchContainer .srchTopContainer .srchTxt {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 3px 0;
    margin-right: 8px;
}

.srchContainer .srchTopContainer .rsltsCount {
    color: rgb(146, 146, 146);
}

.srchContainer .srchTopContainer .svSrchBtn {
    width: 100%;
    max-width: max-content;
    height: max-content;
}

.srchContainer .srchTopContainer .rtDiv .customSelect {
    max-width: 200px;
    width: 100%;
    margin-left: 10px;
    margin-bottom: 0;
}

.srchContainer .cardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 25px;
}

.allTradingsWrapper .cardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 25px;
    gap: 25px;
}

.allTradingsWrapper .cardGrid .gridCard {
    max-width: unset;
}

.hmSrchPage {
    margin-top: 120px;
}

.srchRsltTxtWrpr {
    color: white;
}

.srchRsltTxtWrpr .leftDiv h4 {
    font-size: 18px;
}

.srchRsltTxtWrpr .svSrchBtn {
    width: 190px;
    color: white;
}

.lvIcon {
    width: 40px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    background: red;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: hideShow;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

.noRctdFnd {
    background: white;
    border: 1px dashed gray;
    padding: 35px;
    border-radius: 20px;
    padding-bottom: 2px;
}

.noRctdFnd p {
    line-height: 30px;
}

.noRctdFnd .zohoUpdWrpr {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
}

.noRctdFnd .primButton button {
    height: 56px;
}

.noRctdFnd .material-icons {
    font-size: 39px;
    color: rgb(161, 161, 161);
}

.noRctdFnd hr {
    border-top: 1px solid rgb(202, 202, 202);
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}

.fullScreenPopup.searchPopup header {
    display: none;
}

.fullScreenPopup.searchPopup .MuiDialog-paperFullScreen {
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: 100%;
    max-height: none;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(37px);
    background: transparent;
}

.fullScreenPopup.searchPopup .modalChildren {
    max-width: 90vw;
    width: 100%;
    max-height: 90vh;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    padding: 20px;
    border: 1px solid gray;
    border-radius: 10px;
    background: white;
    position: relative;
}

.fullScreenPopup.searchPopup .modalChildren .searchCloseBtn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.fullScreenPopup.searchPopup .srchRsltTxtWrpr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
}

.fullScreenPopup.searchPopup .srchRsltTxtWrpr .svSrchBtn {
    color: var(--primColor);
}

.fullScreenPopup.searchPopup .srchRsltTxtWrpr .customSelect {
    margin-bottom: 0px;
    width: 100%;
    max-width: 200px;
}

.fullScreenPopup.searchPopup .srchRsltTxtWrpr .customSelect .MuiFormControl-root::after {
    top: 6px;
    right: 7px;
}

.fullScreenPopup.searchPopup .srchRsltTxtWrpr .customSelect select {
    font-size: 14px;
}

.fullScreenPopup.searchPopup .srchRsltTxtWrpr .customSelect .MuiOutlinedInput-notchedOutline {
    border: none;
}

.emptyCard {
    background: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.emptyCard .logoIcon {
    width: 80px;
    height: auto;
    object-fit: contain;
    display: block;
    margin-bottom: 30px;
}

.emptyCard h6 {
    font-size: 18px;
    color: #000;
    font-weight: 500;
    margin: 0;
}

.emptyCard.large {
    background: #fff;
}

.emptyCard.large .eclCards {
    margin-top: 35px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0;
}

.emptyCard.large .eclCards img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.emptyCardImage.small img {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: contain;
}

.emptyCardImage.large img {
    width: 100%;
    height: auto;
    max-height: 800px;
    object-fit: contain;
}

@keyframes hideShow {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@media (max-width: 1366px) {
    .srchContainer .tabTitle {
        margin: auto;
    }
}

@media (max-width: 1000px) {
    .srchContainer .srchTopContainer .tabTitle button {
        margin-top: 15px;
    }
}

@media (max-width: 940px) {
    .srchContainer .cardGrid {
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    }
}

@media (max-width: 600px) {
    .srchContainer .srchTopContainer {
        padding-top: 25px !important;
        flex-wrap: wrap;
    }

    .srchContainer .srchTopContainer .rtDiv {
        flex-wrap: wrap;
        justify-content: space-between !important;
    }

    .srchContainer .srchTopContainer .rtDiv .customSelect {
        width: 100%;
        max-width: unset;
        margin: 10px 0;
    }

    .srchContainer .srchTopContainer .rtDiv .customSelect:not(:first-child) {
        width: 48%;
    }

    .srchContainer .srchTopContainer .customSelect label,
    .srchContainer .srchTopContainer .customSelect .MuiSelect-select.MuiSelect-select {
        width: 103px;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }

    .srchRsltTxtWrpr {
        flex-wrap: wrap;
        margin-top: 65px;
        color: gray;
        justify-content: center;
    }

    .srchRsltTxtWrpr .leftDiv {
        justify-content: center;
    }

    .srchRsltTxtWrpr .svSrchBtn {
        color: var(--black);
        margin-top: 10px;
    }
}

@media (max-width: 400px) {
    .fullScreenPopup.searchPopup .modalChildren {
        padding: 10px;
    }

    .fullScreenPopup.searchPopup .modalChildren .srchRsltTxtWrpr {
        justify-content: center !important;
    }

    .fullScreenPopup.searchPopup .modalChildren .srchRsltTxtWrpr .leftDiv {
        width: 100%;
        text-align: center;
        flex-wrap: wrap;
        margin: 9px 0;
    }

    .fullScreenPopup.searchPopup .modalChildren .srchRsltTxtWrpr .leftDiv h4 {
        width: 100%;
        text-align: center;
        margin-bottom: 5px !important;
        margin-top: 5px !important;
    }
}
