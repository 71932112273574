.loginWrapper .rdrLnk,
.loginWrapper .rdrLnk a {
    font-weight: 600;
}

.loginWrapper .loginForm {
    max-width: 416px;
}

.loginWrapper .loginForm h3 {
    font-size: 36px;
    font-family: var(--secFont);
    font-weight: bold;
}

.loginWrapper .chkCntnr {
    margin-bottom: 15px;
}

.loginWrapper .chkCntnr .MuiListItem-button {
    padding: 0;
}

.loginWrapper .chkCntnr .MuiTypography-root,
.loginWrapper .chkCntnr a {
    font-weight: 700;
}

.loginWrapper .primButton {
    width: 192px;
}

.loginWrapper .otpBxwpr {
    display: flex;
    align-items: center;
    position: relative;
}

.loginWrapper .otpBxwpr .inptBxWrpr {
    width: 56px;
    height: 56px;
    border: 1px solid var(--primColor);
    border-radius: 10px;
    position: absolute;
    pointer-events: none;
}

.loginWrapper .otpBxwpr .inptBxWrpr:first-child {
    left: 15px;
}

.loginWrapper .otpBxwpr .inptBxWrpr:nth-child(2) {
    left: 76px;
}

.loginWrapper .otpBxwpr .inptBxWrpr:nth-child(3) {
    left: 137px;
}

.loginWrapper .otpBxwpr .inptBxWrpr:nth-child(4) {
    left: 198px;
}

.loginWrapper .otpBxwpr .inptBxWrpr:nth-child(5) {
    left: 259px;
}

.loginWrapper .otpBxwpr .inptBxWrpr:nth-child(6) {
    left: 320px;
}

.loginWrapper .otpBxwpr .inptBxWrpr.ltBox {
    background: white;
    border: none;
    left: 381px;
}

.loginWrapper .otpBxwpr input {
    width: 100%;
    height: 56px;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 22px;
    text-align: left;
    letter-spacing: 48.5px;
    padding-left: 20px;
    padding-right: 0px;
    border: none;
}

.loginWrapper .otpBxwpr input:focus {
    outline: none;
}

.loginWrapper .rsndTxt {
    color: #8e8c8c;
    font-weight: 500;
    font-size: 14px;
}

.loginWrapper .rsndTxt span {
    color: var(--primColor);
}

.loginWrapper .optForm {
    max-width: 520px;
}

@media (max-width: 600px) {
    .loginWrapper .otpBxwpr {
        zoom: 0.9;
    }
}

@media (max-width: 400px) {
    .loginWrapper .otpBxwpr {
        zoom: 0.85;
    }
}
