.gridCard {
    max-width: 350px;
    width: 100%;
}

.gridCard .pcgImg > img {
    width: 100%;
    height: 100%;
    min-height: 254px;
    object-fit: cover;
    border-radius: 10px;
    aspect-ratio: 1 / 1;
}

.gridCard .pcgImg {
    width: 100%;
    /* height: 254px; */
    position: relative;
}
.gridCard .editCert {
    background: var(--primColor);
    border-radius: 5px;
    width: 100%;
    margin: 5px 0;
    border: 1px solid var(--primColor);
}

.gridCard .editCert:hover {
    background: #fff;
}

.gridCard .editCert:hover .MuiButton-label {
    color: var(--primColor);
}

.gridCard .editCert .MuiButton-label {
    font-weight: 700;
    color: #fff;
    letter-spacing: initial;
}

.gridCard .editCert .material-icons {
    margin-right: 8px;
    font-size: 20px;
}

.gridCard .pcgImg .favoriteCheck,
.gridCard .pcgImg .favoriteCheck {
    position: absolute;
    top: 4px;
    right: 5px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid gray;
    z-index: 99;
}

.gridCard .pcgImg .favoriteCheck label .ntChckd,
.gridCard .pcgImg .favoriteCheck label .ntChckd {
    color: gray;
}

.gridCard .pcgImg .favoriteCheck label,
.gridCard .pcgImg .favoriteCheck label {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.gridCard .pcgImg .favoriteCheck input:checked + label,
.gridCard .pcgImg .favoriteCheck input:checked + label {
    color: #ff4646;
}

.gridCard .productWinningStatus {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 13px;
    background-color: var(--primColor);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 6px 20px;
    font-weight: 600;
}

.gridCard .productWinningStatus.lost {
    background: red;
}

.gridCard .productWinningStatus.won {
    background: green;
}

.gridCard .productWinningStatus.winning {
    background: var(--secColor);
}

.gridCard .productWinningStatus.outbid {
    background: rgb(255, 94, 0);
}

.gridCard .productWinningStatus.cmngSoon {
    background: #ffe005;
    color: black;
}

.gridCard .pdtDtls {
    padding: 16px 0;
}

.gridCard .pdtDtls .tmrContainer .gridTimer {
    font-size: 14px;
    font-weight: 500;
}

.gridCard .pdtDtls .pdtName {
    font-family: var(--secFont);
    font-weight: bold;
    font-size: 24px;
    color: var(--primColor);
    margin-top: 13px;
    height: 4.3ex;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gridCard .pdtDtls .typeChip {
    background: #e5e5e5;
    font-size: 12px;
    font-weight: 600;
    padding: 7px 10px;
    border-radius: 100px;
    padding: 6px 10px;
}

.gridCard .pdtDtls .typeChip.comingSoon {
    background: #ffe005;
}

.gridCard .bdWnDetails .row div {
    padding-top: 5px;
    padding-bottom: 5px;
    color: gray;
}

.gridCard .bdWnDetails .row.ttlCard {
    color: var(--primColor);
    border-bottom: 1px solid #dedede;
}

.gridCard .closedText {
    display: flex;
    padding: 8px 10px;
    border-radius: 10px;
    background: #fde6e6;
    justify-content: center;
    align-items: center;
    color: red;
    margin-bottom: -8px !important;
}

.cstmPopOver .MuiPaper-root.MuiPopover-paper .MuiTypography-root {
    font-size: 14px;
    font-weight: 500;
}

.gridCard .pdtDtls .tmrContainer .gridTimer.closdDt {
    font-size: 12px;
}

.gridCard .prchasedWrapper {
    background-color: #ffe005;
    border-radius: 5px;
    padding: 20px 0px;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    text-align: center;
    position: absolute;
    top: -10px;
    left: -9px;
    line-height: 8px;
}

.gridCard .prchasedWrapper p {
    margin: 0;
    font-size: 21px;
    line-height: 1.2rem;
    font-weight: 900;
    margin-bottom: 4px;
}

.gridCard .prchasedWrapper.sldWrpr {
    background: red;
    line-height: 22px;
    font-weight: 600;
    color: white;
}

.gridCard .prchasedWrapper.sldWrpr p {
    color: white;
}

.gridCard .prchasedWrapper small {
    font-size: 9px;
    font-weight: bold;
    white-space: pre-wrap;
    padding: 0 3px;
}

/* GRID END */

/* AUCTION CARD */

.auctionCard {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0px 3px 20px #0000001f;
}

.auctionCard .auctionCardLt {
    width: 70%;
}

.auctionCard .acImg {
    width: 100%;
    max-width: 250px;
    position: relative;
    margin-inline-end: 20px;
}

.auctionCard .acImg img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.auctionCard .acContent {
    margin-inline-end: 15px;
    text-align: start;
}

.gridCard .pdtDtls .cstDtls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 8px;
    margin-top: 15px;
    border-top: 1px solid #e5e5e5;
}

.gridCard .pdtDtls .cstDtls p {
    font-size: 22px;
    font-weight: 700;
    font-family: var(--secFont);
    margin: 0;
}

.gridCard .pdtDtls .cstDtls small {
    font-weight: 700;
    font-size: 12px;
}

.gridCard .shrtDesc {
    height: 8ex;
    font-size: 14px;
    color: gray;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

/* .gridCard .pdtDtls .closdDt h6 {
    font-size: 14px;
    font-weight: 500;
} */

/* GRID END */

/* CART ITEM */

.cartItem {
    background: #fff;
    /* padding: 10px; */
    padding: 10px 10px 0px 10px;
    /* padding-bottom: 25px; */
    margin-bottom: 25px;
    border-radius: 10px;
    width: 100%;
    /* border-bottom: 1px solid #d7dadc; */
}

.cartItem .itemInfo {
    width: 100%;
}
.checkoutCnt .cartItem .itemInfo {
    /* width: 75%; */
    gap: 24px;
}

.cartItem .cartInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.checkoutCnt .cartItem .cartInfo {
    gap: 0px;
    margin-top: 12px;
}
.checkoutCnt .cartItem .cartInfo h6 {
    font-size: 18px;
}

.cartItem .pclImg {
    width: 100%;
    max-width: 150px;
    position: relative;
    margin-inline-end: 20px;
}
.checkoutCnt .cartItem .pclImg {
    width: 100%;
    max-width: 240px;
    position: relative;
    margin-inline-end: 20px;
}

.cartItem .pclImg img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
}
.checkoutCnt .cartItem .pclImg img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 5px;
}

.cartItem .listContent {
    width: max-content;
    text-align: start;
    margin-inline-end: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cartItem .listActBtn {
    width: 25%;
    max-width: max-content;
}

.cartItem .listContent .listProdTitle {
    font-size: 18px;
    font-weight: 600;
    color: #0e131f;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.checkoutCnt .cartItem .listContent .listProdTitle {
    font-size: 30px;
    /* word-break: break-all; */
}

.cartItem .listContent .listLotInfo {
    margin: 5px 0;
}

.checkoutCnt .cartItem .listContent .listLotInfo h5 {
    color: var(--black);
    font-size: 18px;
    margin: 0;
    line-height: 25px;
    display: flex;
    gap: 8px;
    align-items: center;
}
.cartItem .listContent .listLotInfo h5 {
    color: var(--black);
    font-size: 15px;
    margin: 0;
    line-height: 25px;
}

.checkoutCnt .cartItem .listContent .listLotInfo span {
    font-size: 18px;
}

.cartItem .listContent .listLotInfo h5:nth-child(2) {
    padding-inline-start: 20px;
    border-left: 1px solid #ccc;
    margin-inline-start: 20px;
}

.cartItem .listContent .listLotInfo span:first-child {
    /* width: 90px; */
    display: block;
}

.cartItem .listContent .listLotInfo span:last-child {
    color: var(--primColor);
    font-size: 18px;
    font-weight: 700;
    display: block;
}

.cartItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

.cartItem .listActBox {
    width: 30%;
}

.cartItem .listBidInfo p {
    font-size: 16px;
    color: var(--secColor);
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.cartItem .listBidInfo h6 {
    margin: 0;
    font-size: 14px;
    color: var(--secColor);
    padding-inline-end: 10px;
}

.cartItem .listBidInfo p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.cartItem .listBidInfo p span:first-child {
    color: var(--primColor);
}

.cartItem .listBidInfo p span.listDivider {
    padding: 0 15px;
}

.cartItem .listBidInfo {
    margin-top: 5px;
}

.cartItem .listTimer p span {
    padding-inline-end: 5px;
}

.cartItem .listBidBox .customInput {
    margin: 15px 0;
}

.cartItem button.removeCart {
    text-transform: initial;
    color: var(--primColor);
    margin-top: 5px;
    width: 100%;
}

.cartItem .updateCartQuantity {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 10px;
}

.cartItem .updateCartQuantity .customInput .MuiFormControl-root .MuiOutlinedInput-root {
    width: 120px;
    padding: 5px 35px 5px 5px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    border-radius: 50px;
    text-align: center;
    height: 40px;
}

.cartItem .updateCartQuantity input:focus {
    outline: 0;
}
.cartItem .updateCartQuantity input {
    padding: 0;
    text-align: center;
}

.cartItem .updateCartQuantity button {
    min-width: max-content;
    position: absolute;
    top: 3px;
    z-index: 10;
    color: var(--primColor);
}

.cartItem .updateCartQuantity button:first-child {
    left: 10px;
    border-radius: 50px;
}

.cartItem .updateCartQuantity button.Mui-disabled {
    color: #ccc;
}

.cartItem .updateCartQuantity button:last-child {
    right: 10px;
    border-radius: 50px;
}

.updateCartQuantity .MuiFormHelperText-root.Mui-error {
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 120px;
}

/* CART ITEM END */

/*ROYALTY CARD CSS*/

.royaltyCardWrapper {
    background: white;
    padding: 15px;
    border-radius: 5px;
}

.royaltyCardWrapper .topContainer .imgWrpr {
    width: 82px;
    height: 95px;
}

.royaltyCardWrapper .topContainer .imgWrpr img {
    width: 100%;
    height: 100%;
}

.royaltyCardWrapper .topContainer .textContainer {
    text-align: left;
}

.royaltyCardWrapper .topContainer .textContainer h2 {
    font-size: 15px;
    font-weight: bold;
}

.royaltyCardWrapper .topContainer .textContainer p {
    color: gray;
    font-size: 14px;
    margin: 0;
    margin-bottom: 5px;
}

.royaltyCardWrapper .bottomContainer p {
    font-weight: bold;
}

.royaltyCardWrapper .bottomContainer p > u {
    color: var(--primColor);
}

/* SIMILAR ITEMS */
.similarItem {
    background: #fff;
    padding: 10px;
    /* margin-bottom: 10px; */
}

.similarItem .pclImg {
    width: 25%;
    margin-inline-end: 30px;
}

.similarItem .pclImg img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 3px;
}

.similarItem .listContent {
    text-align: start;
    width: 70%;
}

.similarItem .listContent .listProdTitle {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.similarItem .listContent .listLotInfo {
    margin: 5px 0;
}

.similarItem .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 14px;
    margin: 0;
    line-height: 25px;
}

.similarItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

/* SIMILAR ITEMS END */

.actionAfterWin {
    margin: 45px 0 5px 0;
}

.actionAfterWin .primButton,
.actionAfterWin .secButton {
    width: 100% !important;
}

/* Skeletons */

.gridViewSkeleton {
    width: 100%;
    height: 100%;
    max-width: 350px;
}

.listViewSkeleton {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1.5fr 3fr 1.5fr;
    margin: 15px 0;
    background: #f7f7f7;
}

.bidHistoryModal table thead th,
.bidHistoryModal table tbody td {
    text-align: start;
    white-space: nowrap;
}

.customModal .bidHistoryModal .modal-dialog.modal-lg {
    max-width: 550px;
}

.productCardGrid .moreInfo button,
.productCardList .moreInfo button {
    color: var(--secColor);
    text-transform: initial;
}

.productCardGrid .moreInfo button .material-icons,
.productCardList .moreInfo button .material-icons {
    padding-inline-start: 5px;
}

/* Trading Card */
.tradingCardWrapper {
    background: white;
    padding: 8px;
    box-shadow: 0 0 10px #00000014;
    border-radius: 15px;
}

.tradingCardWrapper .pdtNmDetls {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px 10px;
    color: white;
    background: #2f6bafed;
    border-radius: 10px;
    width: 100%;
}

.tradingCardWrapper .pdtNmDetls h4 {
    font-size: 20px;
    font-weight: 800;
}

.tradingCardWrapper .pdtNmDetls p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
}

.tradingCardWrapper .vwMrInfo .MuiButton-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
}

.tradingCardWrapper .vwMrInfo .MuiButton-label img {
    width: 21px;
    height: 21px;
    object-fit: contain;
    margin-bottom: 11px;
}

.tradingCardWrapper .btnStk .primButton,
.tradingCardWrapper .btnStk .secButton {
    width: 48%;
}

.tradingCardWrapper .ryltyInfo {
    background: whitesmoke;
    padding: 8px 12px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.tradingCardWrapper .ryltyInfo p {
    font-weight: 500;
    font-size: 14px;
}

.tradingCardWrapper .ryltyInfo p,
.tradingCardWrapper .ryltyInfo h4 {
    margin: 0;
}

.tradingCardWrapper .ryltyInfo h4 {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 24px;
}

.tradingCardWrapper .topInfoCntnr {
    margin-top: 10px;
    margin-bottom: 10px;
}

.tradingCardWrapper .topInfoCntnr div p {
    color: #747474;
    font-weight: 600;
    font-size: 11px;
    text-align: center;
}

.tradingCardWrapper .topInfoCntnr .lftContainer p,
.tradingCardWrapper .topInfoCntnr .rtCntnr p {
    margin-bottom: 3px;
}

.tradingCardWrapper .pyInfo p {
    font-size: 12px;
    font-weight: 600;
    color: #747474;
    margin-bottom: 6px;
}

.tradingCardWrapper .pyInfo p span.green {
    color: green;
}

.tradingCardWrapper .pyInfo p span.red {
    color: #d32222;
}

.tradingCardWrapper .topInfoCntnr .lftContainer h3,
.tradingCardWrapper .topInfoCntnr .rtCntnr h3 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    font-family: 'Poppins';
    margin-bottom: 0;
}

.tradingCardWrapper .topInfoCntnr .mdlCntnr {
    padding-left: 10px;
    padding-right: 10px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}

.tradingCardWrapper .topInfoCntnr .mdlCntnr p {
    margin-bottom: 6px;
}

.tradingCardWrapper .topInfoCntnr .mdlCntnr h2 {
    color: var(--primColor);
    font-weight: 700;
    font-family: 'Poppins';
    font-size: 28px;
    margin-bottom: 0;
}

.gridCard .invtOly {
    background: #eff6ff;
    border: 1px dashed #2f6baf;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primColor);
    font-weight: 600;
    border-radius: 5px;
    font-size: 17px;
}

.gridCard .pcgArtist {
    position: absolute;
    bottom: 10px;
    left: 10px;
    border-radius: 25px;
    background: #fff;
    padding: 3px 15px 4px 5px;
}

.gridCard .pcgArtist img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.gridCard .pcgArtist span {
    font-size: 12px;
    color: 232323;
    font-weight: 700;
}

.cartAccordion.MuiPaper-root.MuiAccordion-root {
    border: 0;
    margin-top: 16px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    min-width: 560px;
}
.cartAccordion .MuiAccordionSummary-root {
    border: 1px solid #f2f2f2;
    outline: 0;
}
.cartAccordion .MuiCollapse-wrapper {
    border: 1px solid #f2f2f2;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.cartAccordion .MuiCollapse-wrapper .ck-content p img {
    width: 100%;
    height: 100%;
}
.cartAccordion.MuiAccordion-root:before {
    display: none;
}

.reserve_button button span {
    font-size: 12px;
}

@media (max-width: 991px) {
    .productCardList {
        flex-wrap: wrap;
    }

    .gridCard {
        max-width: unset;
    }
}

@media (max-width: 768px) {
    .cartItem {
        flex-wrap: wrap;
        width: 48%;
    }
    .cartItem .itemInfo {
        width: 100%;
        flex-wrap: wrap;
    }

    .cartItem .pclImg {
        max-width: none;
        margin-inline-end: 0;
    }

    .cartItem .pclImg img {
        height: 200px;
    }
    .cartItem .listContent {
        width: 100%;
        margin-inline-end: 0;
    }
    .cartItem .listActBtn {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .cartItem .listContent .listProdTitle {
        font-size: 24px;
        text-align: center;
    }

    .cartItem .cartInfo h6 {
        text-align: center;
    }

    .cartItem .listContent .listLotInfo {
        justify-content: center !important;
    }
}

@media (max-width: 500px) {
    .gridCard {
        margin: auto;
    }

    .auctionCard {
        flex-wrap: wrap;
    }
    .auctionCard .auctionCardLt {
        width: 100%;
        flex-wrap: wrap;
    }
    .auctionCard .acImg {
        max-width: initial;
        margin-inline-end: 0;
    }
    .auctionCard .acContent {
        width: 100%;
        margin-inline-end: 0;
    }
    .auctionCard .acActBtn {
        width: 100%;
    }
    .auctionCard .acTimer {
        flex-wrap: nowrap !important;
        justify-content: flex-start !important;
        margin: 10px 0;
    }
    .auctionCard .acActBtn .acTimer h6 {
        justify-content: flex-start;
        width: max-content;
    }
    .auctionCard .acActBtn .acTimer p {
        margin: 0;
        margin-inline-start: 10px;
    }

    .auctionCard .acContent h2 {
        font-size: 16px;
        line-height: 20px;
    }
    .auctionCard .acContent p {
        font-size: 13px;
        margin-bottom: 2px;
    }

    .cartItem .pclImg img {
        height: 150px;
    }
    .cartItem .itemInfo {
        width: 100%;
    }
    .productCardGrid .gridProdTitle {
        font-size: 16px;
    }
    .productCardGrid .gridTimer h6,
    .productCardGrid .gridBidInfo h6 {
        font-size: 13px;
    }
    .search .searchResults .productCardGrid,
    .dashboard .productCardGrid {
        width: 100%;
    }

    .productCardGrid .gridBidBox .customInput,
    .productCardGrid .gridBidBox .customInput .MuiFormControl-root.MuiTextField-root {
        height: 45px;
    }
    .productCardGrid .gridBidBox .customInput input {
        height: 100%;
    }
    .productCardGrid .gridBidBox .primButton,
    .productCardGrid .gridBidBox .secButton {
        width: 49%;
    }
    .similarItem .listContent .listProdTitle {
        font-size: 15px;
    }
    .similarItem .pclImg {
        width: 30%;
        margin-inline-end: 10px;
    }

    .cartItem {
        width: 100%;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem button.removeCart {
        width: max-content;
    }

    .gridCard .pdtDtls .pdtName,
    .gridCard .shrtDesc {
        height: auto;
    }
}

@media (max-width: 450px) {
    .auctionCard .acContent h2 {
        font-size: 14px;
    }
    .auctionCard .acContent p {
        font-size: 12px;
    }
    .auctionCard .acContent {
        margin-bottom: 10px;
    }
}

.cursorPointer {
    cursor: pointer;
}
