.customDialog.freeSharePopup .MuiPaper-root.MuiDialog-paper {
    background: url(../../../../assets/freeback.png);
    background-size: cover;
    max-width: 800px;
    width: 100%;
    border-radius: 10px;
}

.customDialog.freeSharePopup .MuiDialogTitle-root {
    display: none;
}

.customDialog.freeSharePopup .MuiDialogContent-root {
    padding: 20px;
}

.freeSharesWrapper .inrFrShrCntnr {
    border: 1px dashed #707070;
    border-radius: 8px;
    position: relative;
    padding: 22px;
}

.freeSharesWrapper .inrFrShrCntnr .closeBtn {
    position: absolute;
    top: -12px;
    right: -14px;
    background: white;
    border: 1px solid gray;
    padding: 2px;
}

.freeSharesWrapper .inrFrShrCntnr h4 {
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 35px;
}

.freeSharesWrapper .inrFrShrCntnr p {
    text-align: center;
    font-size: 14px;
}

.freeSharesWrapper .inrFrShrCntnr ul {
    width: max-content;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 21px;
    font-size: 17px;
    font-weight: 600;
    color: var(--primColor);
}

.freeSharesWrapper .inrFrShrCntnr li::marker {
    content: url(../../../../assets/svg/freelist.svg);
}

.freeSharesWrapper .inrFrShrCntnr li span {
    padding-left: 10px;
}

.freeSharesWrapper .inrFrShrCntnr .acntSetup {
    padding: 10px;
    border: 1px solid #ff9a9a;
    border-radius: 10px;
    background: #ffffff4d;
    color: red;
}

.freeSharesWrapper .inrFrShrCntnr .acntSetup p {
    margin-bottom: 10px;
}

.freeSharesWrapper .inrFrShrCntnr .acntSetup .primButton {
    max-width: 210px;
    margin-left: auto;
    margin-right: auto;
}

.freeSharesWrapper .inrFrShrCntnr .acntSetup .primButton .MuiButton-label {
    font-size: 13px;
}

.freeSharesWrapper .inrFrShrCntnr .ftrActn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #d9d9d9;
    padding-top: 15px;
    margin-top: 40px;
}

.freeSharesWrapper .inrFrShrCntnr p.nvTxt {
    font-weight: 500;
    font-size: 16px;
}

.freeSharesWrapper .ftrActn .customCheckbox .MuiButtonBase-root.MuiListItem-root {
    font-size: 13px;
    padding-left: 0;
}
