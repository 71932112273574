.mainHeader {
    width: calc(100% - 217px);
}

.mainHeader .srchContainer {
    width: 180px;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    background: white;
    border-radius: 10px;
}

.mainHeader .srchContainer input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    border-radius: 10px;
    padding-left: 5px;
}

.mainHeader .srchContainer input:focus {
    outline: none;
}

.mainHeader .srchContainer input::placeholder {
    color: var(--black);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.mainHeader .acntBtnDrop {
    color: white;
    padding: 0;
}

.mainHeader .cartBtn img {
    width: 22px;
}

.mainHeader .bfrLgnCont {
    color: white;
    display: flex;
    align-items: center;
}

.mainHeader .bfrLgnCont a {
    color: white;
}

#menu-list-grow img {
    filter: grayscale(1) brightness(0);
}

.mblLnk {
    display: flex;
    align-items: center;
}

.mblLnk .material-icons {
    margin-right: 10px;
}

.mobMenuBtn.MuiButton-root {
    min-width: unset;
    padding: 0;
}

.mobMenuBtn .MuiButton-text {
    padding: 0;
}

.mobMenuBtn .material-icons {
    color: white;
}

.mblSrchContainer input {
    height: 40px;
    width: 100%;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    background: #f7fdff;
}

.mblSrchContainer input:focus {
    outline: none;
}

.mblSrchContainer .srchBtn {
    background: var(--primColor);
    height: 40px;
    border-radius: 0 0 3px 0px;
}

.mblSrchContainer .srchBtn img {
    filter: brightness(11);
    width: 30px;
    height: 30px;
}

.mblSrchDrawer .MuiPaper-root.MuiDrawer-paper {
    margin: 10px;
    border-radius: 3px;
}

.mobsrchIcon {
    filter: brightness(11);
    height: 23px;
}

.mnNvlLnks {
    color: white;
    font-size: 14px;
    margin-left: 22px;
}

.mnNvlLnks:hover {
    color: white;
}

.wlcmPopup {
    text-align: center;
}

.wlcmPopup .wlcmCntnr img {
    max-width: 150px;
    width: 100%;
    margin-bottom: 10px;
}

.wlcmPopup .wlcmCntnr h2 {
    font-size: 26px;
    margin: 10px 0;
    font-weight: 600;
    margin-bottom: 20px;
}

.wlcmPopup.customDialog .cdTop .closeBtn {
    position: absolute;
    top: 5px;
    right: 5px;
    margin: 0;
}

/* RESPONSIVE */

@media (max-width: 1145px) {
    header .headRt ul {
        list-style: none;
        color: white;
        margin-bottom: 0;
    }
    header .headRt ul li a {
        font-size: 15px;
    }
    header .headRt li > button {
        min-width: max-content;
        margin-inline-end: 5px;
    }
    header .headRt li > button > .MuiButton-label {
        color: white;
    }

    .MuiPaper-root.MuiDrawer-paper .headerSearchForm {
        display: flex;
        flex-wrap: nowrap;
        padding: 0 11px;
    }
}

@media (max-width: 1024px) {
    .mblHdr {
        padding: 20px;
    }

    .headRt.respNav .naLogoHead {
        padding: 10px 0px 10px 20px;
        background: var(--black);
    }

    .headRt.respNav .naLogoHead img {
        max-width: 157px;
        width: 100%;
        height: auto;
    }

    .headRt.respNav .headDrawerClose .MuiButton-label {
        color: rgb(147, 147, 147);
    }

    .MuiDrawer-paperAnchorTop {
        border-radius: 0 0 5px 5px;
    }

    .MuiDrawer-paperAnchorTop .drwrIpt {
        height: 44px;
        border-radius: 2px 0 0 2px;
    }

    header .headLt img {
        max-width: 200px;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .deskNav {
        display: none !important;
    }
    .respNav {
        display: block !important;
    }
    .respNavBtn {
        display: inline-flex !important;
        min-width: max-content !important;
        margin-inline-start: 10px !important;
        color: #000 !important;
    }
    .headRt.respNav .navRespLinks {
        padding-inline-start: 0;
        list-style-type: none;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 15px;
    }

    .headRt.respNav .MuiListItem-root {
        padding: 12px 20px;
        color: #4a4a4a;
        font-weight: 600;
        /* justify-content: space-between; */
    }
    .headRt.respNav .MuiListItem-root > div {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .headRt.respNav .MuiListItem-root span.material-icons {
        padding-inline-end: 10px;
        color: #878787;
    }

    header .headRt ul li:not(:last-child) {
        margin-inline-end: 0;
    }

    .headRt.respNav .navRespLinks a {
        width: 100%;
        text-decoration: none;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        font-weight: 600;
        flex-wrap: wrap;
    }

    .headRt.respNav .navRespLinks a .hnContact {
        padding-inline-start: 35px;
    }

    .headRt.respNav .navRespLinks a.active,
    .headRt.respNav .navRespLinks a.active span {
        color: var(--primColor);
    }

    .headerDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 300px;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root {
        flex-wrap: wrap;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root p {
        font-size: 14px;
        padding-inline-start: 8px;
        margin: 0;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
        width: 100%;
        justify-content: space-between;
    }

    .headerDrawer .mobShopCurrLocation {
        padding-bottom: 0 !important;
    }

    .headerDrawer .headerDrawerNavLinks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: calc(100vh - 100px);
        height: auto;
    }

    .headerDrawer .headerDrawerNavLinks hr {
        width: 100%;
    }

    /* .headRt.respNav button {
        justify-content: space-between;
    } */

    .navCategories {
        padding-inline-start: 0;
        margin: 0;
        padding-top: 10px;
    }

    .navCategories .navBack {
        color: #8a8a8a;
    }

    .navCategories .MuiListItem-button {
        width: 100%;
    }
    .notLoggedLink {
        padding: 10px 20px;
        width: 100%;
    }
}

@media (max-width: 500px) {
    .searchSlide h3,
    .searchSlide h4 {
        font-size: 16px;
    }
    .searchSlide h3 span {
        margin-inline-start: 10px;
        font-size: 13px;
    }

    .popularCatg .catgType label h4 {
        font-size: 14px;
    }

    header.mainHeader .headLt img {
        max-width: 140px;
    }
}

/* ******* */
