.mainFooter {
    background: var(--primColor);
    color: white;
    padding-top: 16px;
    padding-bottom: 20px;
}

.mainFooter .ftrinrContainer {
    max-width: 959px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.mainFooter .logoContainer .cprtSection {
    margin-top: 35px;
    font-size: 11px;
}

.mainFooter .cntrFtrContent a {
    color: white;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
}

.mainFooter .cntrFtrContent ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
}

.mainFooter .cntrFtrContent ul:nth-child(2) {
    margin-top: 35px;
}

.mainFooter .cntrFtrContent ul li {
    padding-left: 0;
    margin: 0 3px;
}

.mainFooter .cntrFtrContent ul li a {
    text-decoration: underline;
}

.mainFooter .sclMdLinks {
    display: flex;
}

.mainFooter .sclMdLinks a {
    margin: 0 10px;
}

.mainFooter .lstContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 88px;
}

.mainFooter .lstContainer a {
    color: white;
    font-size: 13px;
}

.mainFooter .bottomContainer {
    margin-top: 75px;
}

.mainFooter .bottomContainer h6 {
    font-size: 14px;
    font-weight: 600;
    font-family: var(--secFont);
    margin-bottom: 0;
}

.mainFooter .bottomContainer p {
    font-size: 10px;
    margin-bottom: 0;
    margin-top: 16px;
}

.mainFooter p.uprCsCntnr {
    text-transform: uppercase;
    font-size: 9px;
}

.mainFooter .bottomContainer p.smlSizeTxt {
    font-size: 8px;
    opacity: 0.8;
}

.moblCntnr {
    font-size: 14px;
    margin-top: 35px;
}

.moblCntnr .sclMdLinks {
    margin-bottom: 20px;
}

.moblCntnr .sclMdLinks a {
    margin-right: 15px;
}

.dscmlr p {
    text-align: justify;
}

.dscmlr a {
    color: white;
    margin-left: 5px;
    text-decoration: underline;
    word-break: break-all;
}

@media (max-width: 1169px) {
    .topContainer {
        flex-wrap: wrap;
    }

    .mainFooter .cntrFtrContent ul {
        justify-content: flex-end;
    }

    .mainFooter .lstContainer {
        margin-left: auto;
        margin-top: 25px;
        height: 90px;
        align-items: flex-end;
    }
}

@media (max-width: 872px) {
    .topContainer {
        justify-content: center !important;
    }

    .mainFooter .logoContainer {
        text-align: center;
        margin-bottom: 25px;
    }

    .mainFooter .cntrFtrContent ul {
        justify-content: center;
        flex-wrap: wrap;
    }

    .mainFooter .cntrFtrContent ul:nth-child(2) {
        margin-top: 13px;
    }

    .mainFooter .lstContainer {
        margin: unset;
        margin-top: 25px;
        height: 90px;
        align-items: center;
    }
}
