.artistCnt {
    margin-top: 18%;
    margin-bottom: 45px;
}

.artistTop {
    display: flex;
    gap: 30px;
}

.artistTop .atImage {
    width: 100%;
    max-width: 270px;
    height: 100%;
}

.artistTop .atImage img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    border: 5px solid #fff;
    border-radius: 10px;
}

.artistTop .atContent h1 {
    font-size: 22px;
    margin-top: 70px;
    color: #232323;
    font-weight: 700;
    margin-bottom: 12px;
}

.artistTop .atContent .atAbout {
    font-size: 14px;
    color: #232323;
    line-height: 24px;
    font-weight: 400;
    display: inline;
}

.artistTop .atContent .readMore {
    margin-left: 5px;
    font-size: 14px;
}

.artistTop .atContent .atMisc {
    font-size: 14px;
    color: #232323;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 25px;
    margin: 20px 0 5px;
}

.artistTop .atContent .atMisc:last-child {
    margin: 0;
}

.artistTop .atContent .atMisc span {
    color: #8a8a8a;
    min-width: 120px;
    display: inline-block;
}

.artistCnt .acTitle {
    font-size: 18px;
    color: #232323;
    font-weight: 600;
    margin: 45px 0 15px;
}

.acSummary {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
}

.acSummary .acsCard {
    padding: 8px 16px;
    box-shadow: 0px 2px 7px rgb(0, 0, 0, 12%);
    border-radius: 10px;
}

.acSummary .acsType {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.acSummary .acsType img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.acSummary .acsType p {
    font-size: 12px;
    color: #232323;
    font-weight: 500;
    margin-bottom: 4px;
}

.acSummary .acsType p span {
    font-weight: 700;
    display: block;
}

.ssCard {
    padding: 15px 15px 5px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    max-width: 335px;
    min-width: 335px;
}

.ssCnt .slick-slide,
.anCnt .slick-slide {
    margin-right: 15px;
}

.ssCard .ssTop {
    display: flex;
    gap: 15px;
}

.ssCard .ssTop img {
    width: 70px;
    height: 70px;
    border-radius: 10px;
}

.ssCard .ssTop h4 {
    font-size: 14px;
    color: #232323;
    font-weight: 600;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ssCard .ssTop p {
    font-size: 10px;
    color: #686868;
    font-weight: 700;
    margin-bottom: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ssCard .ssTop .auctionType {
    font-size: 10px;
    font-weight: 700;
    color: #5d5fef;
    background: #d4d5fc;
    padding: 4px 10px;
    border-radius: 6px;
}

.ssCard .ssMid {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-top: 15px;
}

.ssCard .ssMid img {
    height: 20px;
    width: 100%;
    object-fit: contain;
    margin-bottom: 12px;
}

.ssCard .ssMid h5 {
    font-size: 12px;
    color: #686868;
    font-weight: 500;
    margin-bottom: 5px;
}

.ssCard .ssMid h5 span {
    display: block;
    color: #232323;
    margin-top: 5px;
}

.ssCard .MuiDivider-root {
    margin: 15px 0 5px;
}

.ssCard .ssBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}
.ssCard .ssBottom .ssPrice {
    color: #686868;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}

.ssCard .ssBottom .ssPrice span {
    color: #232323;
    margin-left: 5px;
}

.ssCard .ssBottom button {
    font-size: 12px;
    font-weight: 700;
    color: #2f6baf;
    margin-right: -15px;
}

.ssCard .ssBottom button .MuiButton-label {
    text-transform: uppercase;
    letter-spacing: initial;
}

.anCard {
    padding: 12px 15px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    max-width: 335px;
    min-width: 335px;
}

.anCard .anTop {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.anCard .anTop img {
    width: 100%;
    height: 20px;
    object-fit: contain;
}

.anCard .anTop span {
    font-size: 10px;
    color: #686868;
    font-weight: 700;
    width: 100%;
    text-align: right;
}

.anCard p {
    font-size: 14px;
    color: #232323;
    font-weight: 400;
    margin: 15px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
