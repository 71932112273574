.productContainer .pdtTopContainer {
    position: relative;
}

.productContainer .pdtTopContainer .pdtImg img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
}

.productContainer .pdtTopContainer .backButton {
    padding-left: 0 !important;
}

.productContainer .pvAbt {
    color: #989898;
    font-size: 16px;
    font-weight: 500;
}

.backButton .MuiButton-label {
    color: white;
}

.upcomingBadge {
    font-size: 14px;
    font-weight: 600;
    border-radius: 100px;
    padding: 12px 24px;
    background: #ffe005;
    margin: 0px auto;
    display: block;
    width: fit-content;
    margin-bottom: 25px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.productContainer .pdtTopContainer .pdtName {
    font-size: 36px;
    text-align: left;
    color: white;
    font-weight: 700;
    margin-bottom: 0;
    margin-right: 5px;
    font-family: var(--secFont);
}

.productContainer .pdtTopContainer .favoriteCheck {
    background: transparent;
    display: flex;
    align-items: center;
    width: max-content;
}

.productContainer .pdtTopContainer .favoriteCheck label {
    display: flex;
    align-items: center;
    color: white;
    font-weight: 500;
}

.productContainer .pdtTopContainer .favoriteCheck label .material-icons {
    margin-right: 5px;
}
.productContainer .pdtTopContainer .favoriteCheck input:checked + label {
    color: white !important;
}

.productContainer .pdtTopContainer .favoriteCheck input:checked + label .material-icons {
    color: red !important;
}

.productContainer .pdtTopContainer.offrngWrapper .favoriteCheck input {
    display: none;
}

.productContainer .pdtTopContainer.offrngWrapper .favoriteCheck label {
    color: var(--primColor);
    margin-top: 0px;
}

.productContainer .pdtTopContainer.offrngWrapper .favoriteCheck input:checked + label {
    color: var(--primColor) !important;
}

.productContainer .pdtTopContainer.offrngWrapper .favoriteCheck .material-icons {
    font-size: 20px;
}

.productContainer .pdtTopContainer .ofrnCrcl {
    font-size: 14px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.productContainer .pdtTopContainer .songDetails {
    display: flex;
}

.productContainer .pdtTopContainer .songDetails .dtlGrid {
    min-width: 120px;
}

.productContainer .pdtTopContainer .songDetails .dtlGrid h3 {
    font-size: 22px;
    line-height: 100%;
    color: white;
    font-family: var(--secFont);
}

.productContainer .pdtTopContainer .songDetails .dtlGrid p {
    margin: 0;
    color: white;
    font-weight: 600;
    font-size: 12px;
    width: 100%;
}

.productContainer .bdngDtlWrapper {
    margin-top: 31px;
}

.productContainer .bdngDtlWrapper .placeBidWrpr {
    background: #ffffff;
    /* border: 1px solid #e5e5e5; */
    box-sizing: border-box;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04); */
    border-radius: 10px;
    margin-bottom: 25px;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .prcLvlTxt {
    font-size: 13px;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .sctnHdr {
    padding: 16px;
    font-family: var(--secFont);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid #e5e5e5;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .sctnHdr .helpIcon {
    width: max-content;
    color: var(--primColor);
}

.productContainer .bdngDtlWrapper .placeBidWrpr .sctnHdr h4 {
    /* width: 75px; */
    font-size: 12px;
    font-weight: 700;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .bdBodyContent {
    padding: 16px;
    padding-bottom: 20px;
}

.productContainer .vipAuc .bdngDtlWrapper .placeBidWrpr .bdBodyContent {
    padding-bottom: 0;
}

.productContainer .MuiAccordionDetails-root .bdngDtlWrapper .placeBidWrpr .bdBodyContent {
    padding-left: 0;
    padding-right: 0;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .mnBdnCont label {
    font-size: 13px;
    font-weight: 500;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .bidInput {
    position: relative;
    max-width: 300px;
    width: 100%;
    margin-right: 15px;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .bidInput > div {
    width: 100%;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .bidInput input {
    display: flex;
    align-items: center;
    height: 40px;
    background: #f2f2f2;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
    font-size: 15px;
    width: 100%;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .bidInput input::placeholder {
    font-size: 12px;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .bidInput .MuiOutlinedInput-notchedOutline,
.productContainer
    .bdngDtlWrapper
    .placeBidWrpr
    .bidInput
    .MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .bidInput .suffixWrapper {
    position: absolute;
    padding-left: 5px;
    padding-right: 5px;
    height: 32px;
    right: 3px;
    top: 3px;
    background: #e5e5e5;
    border-radius: 10px;
    font-weight: 500;
    font-size: 13px;
    color: var(--primColor);
    display: flex;
    align-items: center;
    width: max-content;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .bidInput .MuiOutlinedInput-root {
    height: 40px;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .crntBdValue {
    font-size: 15px;
    font-weight: 500;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .bidInput .customInput {
    margin-bottom: 0;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .primButton {
    width: 100%;
}

.productContainer .bdngDtlWrapper .placeBidWrpr.proxyBidWrapper .secButton,
.productContainer .bdngDtlWrapper .placeBidWrpr.proxyBidWrapper .primButton {
    margin-top: 30px;
    width: 100%;
}

.productContainer .bdngDtlWrapper .placeBidWrpr.proxyBidWrapper .secButton button {
    padding: 0;
}

.productContainer .bdngDtlWrapper .placeBidWrpr.proxyBidWrapper .secButton button .MuiButton-label {
    justify-content: space-between;
}

.productContainer .bdngDtlWrapper .placeBidWrpr.proxyBidWrapper .secButton .iconWrapr {
    background: #f0c721;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px 0 0 10px;
    height: 100%;
    display: inline-flex;
    justify-self: center;
    align-items: center;
}

.productContainer .bdngDtlWrapper .placeBidWrpr.proxyBidWrapper .secButton .iconWrapr img {
    width: 22px;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .crntBdStWrp .grdContent {
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
}

.productContainer .bdngDtlWrapper .placeBidWrpr .crntBdStWrp .grdContent .grdBox {
    padding: 19px;
    /* max-width: 110px; */
    border-right: 1px solid #e5e5e5;
    height: 70px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 220px;
}

.productContainer
    .bdngDtlWrapper
    .placeBidWrpr
    .crntBdStWrp
    .grdContent
    .grdBox:not(:nth-last-child(2)) {
    border-bottom: 1px solid #e5e5e5;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .crntBdStWrp .grdContent .grdBox h4 {
    font-size: 16px;
    font-weight: 700;
    color: var(--primColor);
    font-family: var(--secFont);
}

.productContainer .bdngDtlWrapper .placeBidWrpr .crntBdStWrp .grdContent .grdBox p {
    font-size: 10px;
    font-weight: 700;
    color: #b4b4b4;
    margin-bottom: 5px;
    /* max-width: 103px; */
}

.productContainer .pvPriceAct {
    display: block;
    /* position: relative; */
    /* grid-template-columns: 1fr 3fr;
    column-gap: 60px; */
}

.productContainer .pvPriceAct .biddingCnt,
.productContainer .pvPriceAct .biddingCnt > div:first-child {
    width: 100%;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .ylowGrd {
    background: var(--secColor);
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
}

.productContainer .bdngDtlWrapper .placeBidWrpr .ylowGrd .grdCntnr {
    width: 110px;
    padding: 15px;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .ylowGrd .grdCntnr h3 {
    font-size: 20px;
    font-weight: bold;
    font-family: var(--secFont);
    white-space: pre;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .ylowGrd .grdCntnr p {
    font-weight: bold;
    font-size: 10px;
    margin: 0;
}

/* .productContainer .bdngDtlWrapper .chartContainer {
    position: relative;
    max-width: 321px;
    margin-left: auto;
    margin-right: auto;
} */

.productContainer .bdngDtlWrapper .nmbrOfBdsChart .chartContainer {
    height: 251px;
}

.productContainer .bdngDtlWrapper .chartContainer {
    position: relative;
    max-width: 321px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.productContainer .bdngDtlWrapper .chartContainer .txtInforWrpr {
    position: absolute;
    width: 162px;
    height: 162px;
    border-radius: 200px;
    top: 21%;
    left: calc(50% - 81px);
    background: white;
    padding: 20px 8px;
}

.productContainer .bdngDtlWrapper .chartContainer .txtInforWrpr h4 {
    font-weight: bold;
    font-size: 22px;
    font-family: var(--secFont);
}

.productContainer .bdngDtlWrapper .chartContainer .txtInforWrpr p {
    font-size: 12px;
    font-weight: 500;
}
.productContainer .bdngDtlWrapper .chartContainer .txtInforWrpr hr {
    margin-top: 6px;
    margin-bottom: 6px;
}

.productContainer .bdngDtlWrapper .chartContainer .txtInforWrpr .avlblCount h3 {
    font-size: 22px;
    font-weight: bold;
    font-family: var(--secFont);
    margin-bottom: 4px;
    color: black;
}

.productContainer .bdngDtlWrapper .chartContainer .txtInforWrpr .avlblCount h3.active,
.productContainer .bdngDtlWrapper .chartContainer .txtInforWrpr .avlblCount p.active {
    color: var(--primColor);
}

.productContainer .bdngDtlWrapper .chartContainer .txtInforWrpr .avlblCount p {
    /* color: #b4b4b4; */
    color: black;
    font-weight: bold;
    margin: 0;
}

.productContainer .bdngDtlWrapper .bdHstryTable table th {
    font-size: 12px;
    color: var(--primColor);
}

.productContainer .bdngDtlWrapper .bdHstryTable table td {
    font-size: 13px;
    font-weight: 600;
}

.productContainer .bdngDtlWrapper .bdHstryTable table th,
.productContainer .bdngDtlWrapper .bdHstryTable table td {
    border: none !important;
}

.productContainer .bdngDtlWrapper .vwBdHstyLable {
    text-transform: initial;
    font-size: 13px;
    font-weight: 500;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .shrStsWrpr .stsBxs {
    margin-left: 10px;
    margin-right: 10px;
    height: 40px;
    border-radius: 10px;
    background: linear-gradient(270deg, #00ffc2 1.07%, #f8d548 48.96%, #ff4b4b 99.94%);
    border-radius: 10px;
    padding: 6px;
}

.avlblCount .text-right {
    min-width: 63px;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .shrStsWrpr .stsBxs .dmyStstusBox {
    background: transparent !important;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .shrStsWrpr .statusBox.crntUser {
    background: white;
    border: 1px solid black;
    border-radius: 10px;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2px;
    cursor: pointer;
}

.productContainer .bdngDtlWrapper .placeBidWrpr.proxyBidWrapper .shrStsWrpr .statusBox.crntUser {
    background-color: var(--secColor);
}

.productContainer .bdngDtlWrapper .placeBidWrpr .shrStsWrpr .statusBox.crntUser p {
    text-align: center;
    font-weight: 500;
    cursor: pointer;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .shrStsWrpr .statusBox .ngtvWrpr {
    background: red;
    border: 1px dashed black;
    border-radius: 8px 0 0 8px;
    position: relative;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .shrStsWrpr .statusBox .ngtvWrpr::after {
    content: url('../../../../assets/svg/anime.svg');
    position: absolute;
    right: -23px;
    top: -5px;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .shrStsWrpr .stsBxs .dmyStstusBox.ngtvWrpr {
    background: red !important;
    border: 1px dashed black;
    border-radius: 8px 0 0 8px;
    position: relative;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .shrStsWrpr .stsBxs .dmyStstusBox.ngtvWrpr::after {
    content: url('../../../../assets/svg/anime.svg');
    position: absolute;
    right: -23px;
    top: -5px;
}

.productContainer .bdngDtlWrapper .placeBidWrpr .shrStsWrpr .stsBxs .dmyStstusBox.ngtvWrprEmpty {
    background: red !important;
    border: 1px dashed black;
    border-radius: 8px 0 0 8px;
    position: relative;
}

.productContainer
    .bdngDtlWrapper
    .placeBidWrpr
    .shrStsWrpr
    .stsBxs
    .dmyStstusBox.ngtvWrprEmpty::after {
    background: red !important;
    border: 1px dashed black;
    border-radius: 0;
    position: absolute;
    right: -23px;
    top: -1px;
    width: 23px;
    height: 28px;
    content: '';
    border-left: transparent;
    border-right: transparent;
}

.productContainer
    .bdngDtlWrapper
    .placeBidWrpr
    .shrStsWrpr
    .statusBox
    .ngtvWrpr
    .MuiTypography-root {
    text-align: center;
}

.productContainer .pdtDtlTtle h4 {
    font-size: 22px;
    font-weight: bold;
    font-family: var(--secFont);
    margin-bottom: 0;
    margin-top: 40px;
}

.productContainer .pdtDtlTtle {
    padding-bottom: 15px;
    border-bottom: 1px solid #d3d3d3;
}

.productContainer .pdtDtlTtle.noBorder {
    border-bottom: none;
    margin-top: 20px;
}

/* .productContainer .pdtDescriptionCont {
    padding-left: 200px;
} */

.productContainer .pdtDescriptionCont .fldVlu {
    margin-bottom: 10px;
}

.productContainer .pdtDescriptionCont .fldVlu p > span:last-child {
    display: none;
}

.productContainer .pdtDescriptionCont label {
    font-size: 14px;
}

.productContainer .pdtDescriptionCont p {
    font-size: 14px;
    font-weight: 500;
}

.productContainer .pvTimerBox {
    color: var(--primColor);
    background: #fff;
    padding: 10px;
    border-radius: 10px;
}
.checkoutCnt .productContainer .pvTimerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productContainer .pvTimerBox.publicOfferingTimer {
    padding: 10px 15px;
}

.productContainer .offrngWrapper .bdngDtlWrapper .pvTimerBox {
    width: max-content;
    margin-left: auto;
}

.productContainer .offrngWrapper .bdngDtlWrapper .placeBidWrpr .ylowGrd .grdCntnr {
    width: 100%;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    border: 1px solid #f8d548;
}

.productContainer .offrngWrapper .bdngDtlWrapper .placeBidWrpr .ylowGrd .grdCntnr h3 {
    font-size: 16px;
    font-weight: bold;
    font-family: var(--secFont);
    text-align: right;
}

.productContainer .pvTimerBox .pvTimerView {
    display: flex;
    color: var(--primColor);
    margin-top: 10px;
}

.productContainer .pvTimerBox .pvTimerView h6 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productContainer .pvTimerBox .pvTimerView h6 span {
    font-size: 12px;
    font-weight: 500;
}

.productContainer .pvTimerBox .pvTimerView h6:not(:first-child) {
    margin-left: 10px;
}

.productContainer .pvTimerBox .tmrTxt {
    font-size: 13px;
    font-weight: 500;
}
.checkoutCnt .productContainer .pvTimerBox .tmrTxt {
    font-size: 16px;
    font-weight: 500;
}

.productContainer .pvTimerBox .pvTimerView .tmrCnt {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primColor);
    background: #dfe4eb;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}

.productContainer .conclusionWrapper {
    background: url(../../../../assets/conclusionBack.png);
    padding: 60px;
    color: white;
    border-radius: 20px;
    background-size: cover;
    padding-right: 300px;
    margin-bottom: 35px;
    margin-top: 35px;
}

.productContainer .conclusionWrapper h6 {
    font-size: 26px;
    font-weight: 700;
    font-family: var(--secFont);
    margin-bottom: 25px;
}

.productContainer .conclusionWrapper p {
    font-weight: 500;
    font-size: 16px;
    line-height: 162%;
}

.productContainer .conclusionWrapper button {
    text-transform: uppercase;
    color: white;
}

.productContainer .conclusionWrapper button img {
    margin-right: 10px;
}

.productContainer .ofrngNrmlBd.bidInput {
    margin-top: 15px;
    max-width: 100%;
}

.productContainer .ofrngNrmlBd.bidInput p.text-danger {
    font-size: 15px;
    max-width: 320px;
    position: absolute;
}

.productContainer .ofrngNrmlBd.bidInput p.text-danger.purchSngShrTxt {
    font-size: 13px;
    max-width: 320px;
    position: relative;
    margin: 5px 0px;
    font-weight: 500;
}

.productContainer .ofrngNrmlBd.bidInput input {
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 110px;
    background: #f2f2f2;
    border: 1px solid #e5e5e5 !important;
    box-sizing: border-box;
    border-radius: 10px 0 0 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
    font-size: 15px;
    width: 100%;
}

.productContainer .ofrngNrmlBd.bidInput button {
    border-radius: 0 10px 10px 0;
}

.productContainer .bidInput.live .biddingCnt.beforeLogin button {
    border-radius: 0 10px 10px 0;
}

.productContainer .bidInput.live .biddingCnt.beforeLogin button {
    border-radius: 0 10px 10px 0;
}

.productContainer .ofrngNrmlBd.bidInput label {
    font-size: 13px;
    font-weight: 500;
}

.productContainer .ofrngNrmlBd.bidInput .MuiOutlinedInput-notchedOutline,
.productContainer
    .ofrngNrmlBd.bidInput
    .MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;
}

.offrngWrapper.pdtTopContainer {
    margin-top: 400px;
    padding: 0 !important;
}
.offrngWrapper .mnTpCntnr {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 4px #00000036;
    padding: 15px;
}

.productContainer .offrngWrapper .pdtName,
.productContainer .offrngWrapper .songDetails .dtlGrid p {
    color: var(--black);
}

.productContainer .offrngWrapper .songDetails .dtlGrid h3 {
    color: var(--primColor);
    margin-top: 15px;
}

.productContainer .ofrngNrmlBd.bidInput .nrmlAuctBid,
.productContainer .ofrngNrmlBd.bidInput .nrmlAuctBid > form,
.productContainer .ofrngNrmlBd.bidInput .nrmlAuctBid .biddingCnt,
.productContainer .ofrngNrmlBd.bidInput .nrmlAuctBid .biddingCnt .bidInput,
.productContainer .ofrngNrmlBd.bidInput .nrmlAuctBid .biddingCnt .bidInput > div {
    width: 100%;
}

.productContainer .offrngWrapper .pvTimerBox .tmrTxt,
.productContainer .offrngWrapper .pvTimerBox .pvTimerView h6 span:last-child {
    color: var(--primColor);
}

.productContainer .offrngWrapper .bdngDtlWrapper {
    margin-top: 0;
    /* min-width: 290px; */
    /* max-width: 515px; */
    width: 100%;
    margin-left: auto;
}

.productContainer .offrngWrapper .bdngDtlWrapper .chartContainer {
    border-left: 1px solid #dedede;
}

.productContainer .offrngWrapper .bdngDtlWrapper .chartContainer .sctnHdr {
    padding: 16px;
    font-family: var(--secFont);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid #e5e5e5;
}

/* .productContainer .offrngWrapper .bdngDtlWrapper .chartContainer .txtInforWrpr {
    top: 30%;
} */

.productContainer .offrngWrapper .bdngDtlWrapper .chartContainer .sctnHdr h4 {
    font-size: 14px;
    font-weight: 700;
}

.productContainer .nmbrOfBdsChart .tlBdUnitsDtl {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 250px;
    margin: auto;
    border-top: 1px solid rgb(214, 214, 214);
    padding-top: 15px;
    padding-bottom: 10px;
}

.productContainer .nmbrOfBdsChart .tlBdUnitsDtl p {
    font-size: 14px;
    font-weight: 700;
    color: #b4b4b4;
    margin-bottom: 5px;
    max-width: 107px;
}

.productContainer .nmbrOfBdsChart .tlBdUnitsDtl h3 {
    color: var(--primColor);
    font-size: 20px;
    font-family: var(--secFont);
}

.productContainer .prxyMnBdCntnt {
    position: relative;
}

.productContainer .helpIcon.absPstnIcon {
    position: absolute;
    right: 0px;
}

.productContainer .offrngWrapper .bdngDtlWrapper .placeBidWrpr {
    margin-bottom: 0;
}

.productContainer .offrngWrapper .rtContainer.bdngDtlWrapper .placeBidWrpr {
    width: 100%;
}

.offrngWrapper .crntBdStWrp {
    width: 100%;
}

.productContainer .offrngWrapper .bdngDtlWrapper .placeBidWrpr .crntBdStWrp .grdContent .grdBox {
    min-width: 170px;
    /* max-width: 180px; */
    height: 60px;
    padding: 10px;
}

.productContainer .offrngWrapper .placeBidWrpr .sctnHdr {
    padding: 10px;
}

.productContainer .offrngWrapper .backButton {
    position: absolute;
    top: -90px;
    left: 0;
    color: white;
}

.productContainer .offrngWrapper .pvTimerCnt {
    position: absolute;
    top: -140px;
    right: 0;
    color: white;
}

/*removed css*/
.productContainer .pdtTopContainer .pdtDtlContainer .pvTimerBox {
    font-size: 1.125rem;
    font-weight: 500;
    margin-left: 8px;
}

.productContainer .pdtTopContainer .pdtDtlContainer .cstDtls {
    list-style: none;
    padding-left: 0;
}

.productContainer .pdtTopContainer .pdtDtlContainer .cstDtls li {
    display: flex;
    margin: 15px 0;
}

.productContainer .pdtTopContainer .pdtDtlContainer .cstDtls p {
    font-size: 1.125rem;
    font-weight: bold;
}
.productContainer .pdtTopContainer .pdtDtlContainer .cstDtls label {
    min-width: 130px;
}

.productContainer .pdtTopContainer .pdtDtlContainer .stsMtrWrapper {
    border-top: 1px dashed #707070;
    border-bottom: 1px dashed #707070;
    padding: 22px 0;
}

.productContainer .pdtTopContainer .pdtDtlContainer .stsMtrWrapper label {
    min-width: 130px;
}

/* .productContainer .pdtTopContainer .pdtDtlContainer .stsMtrWrapper .avlBox { */
.pdtDtlContainer .stsMtrWrapper .avlBox {
    width: 100%;
    max-width: 100%;
    background: #2e2e2e;
    height: 37px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    font-size: 13px;
    cursor: help;
    display: inline-block;
    max-width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    line-height: 26px;
    padding: 5px;
}

.pdtDtlContainer .stsMtrWrapper .stsBxs {
    background-attachment: fixed !important;
    background: linear-gradient(to right, red, blue) !important;
}

.pdtDtlContainer .stsMtrWrapper .stsBxs .dmyStstusBox-inner {
    background-attachment: fixed !important;
    background: linear-gradient(to right, red, blue) !important;
}

.pdtDtlContainer .stsMtrWrapper .stsBxs .statusBox.crntUser:not(:first-child) {
    border-left: 1px solid white;
}

.pdtDtlContainer .stsMtrWrapper .stsBxs .statusBox.ntCrntUsr {
    background: rgb(26, 26, 26) !important;
}

.pdtDtlContainer .stsMtrWrapper .stsBxs.stsBxsRev {
    background-attachment: fixed !important;
    background: linear-gradient(to right, blue, red) !important;
    position: relative;
}

.mystsPopup .statusBox {
    color: white;
}

.mystsPopup.pdtDtlContainer .stsMtrWrapper .stsBxs .statusBox.ntCrntUsr {
    background: white !important;
}

/* .pdtDtlContainer .stsMtrWrapper .stsBxs.stsBxsRev .dmyStstusBox {
    max-width: 100%;
    width: 100%;
} */

/* .productContainer .pdtTopContainer .pdtDtlContainer .stsMtrWrapper .stsBxs .statusBox { */
.pdtDtlContainer .stsMtrWrapper .stsBxs .statusBox {
    width: 100%;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

/* .productContainer .pdtTopContainer .pdtDtlContainer .stsMtrWrapper .stsBxs .statusBox > div { */
.pdtDtlContainer .stsMtrWrapper .stsBxs .statusBox > div {
    width: 100%;
}

/* .productContainer .pdtTopContainer .pdtDtlContainer .stsMtrWrapper .stsBxs .statusBox p { */
.pdtDtlContainer .stsMtrWrapper .stsBxs .statusBox p {
    font-size: 13px;
    max-width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    line-height: 26px;
    width: 100%;
    /* padding: 5px; */
    display: inline-block;
    padding: 0;
}

.productContainer .pdtTopContainer .pdtDtlContainer .plcBdWrapper {
    height: 152px;
}

.productContainer .pdtTopContainer .pdtDtlContainer .plcBdWrapper .lftWrapper .customInput {
    margin-bottom: 0 !important;
}

.productContainer .pdtTopContainer .pdtDtlContainer .plcBdWrapper .lftWrapper .suffixWrapper {
    background: #6c6c6c;
    height: 56px;
    /* margin: 8px 0px; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -3px;
    z-index: 1;
    border-radius: 0 2px 2px 0;
}

.productContainer .pdtTopContainer .pdtDtlContainer .plcBdWrapper .lftWrapper .primButton button {
    height: 56px;
    margin-left: 10px;
    width: 56px;
}

.productContainer .pdtTopContainer .pdtDscrptn h4 {
    color: white;
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
    max-width: 558px;
    width: 100%;
    border-top: 2px solid #414046;
    padding-top: 15px;
    margin: auto;
    margin-top: 25px;
}

.prxyPopup .primButton {
    width: 100%;
    padding: 0 15px;
}

/* .productContainer .pdtTopContainer .secButton button,
.custom-modal .biddingCnt .secButton button {
    height: 56px;
    border-radius: 4px;
    margin-left: 10px;
    background: var(--primColor);
    color: white;
    border: none;
} */

.productContainer .pdtTopContainer .prxyBidPopBtn {
    border: 1px dashed #e2e2e2;
    height: 56px;
    color: white;
}

.productContainer .pvBidStatus h4 {
    padding: 15px;
    color: #fff;
    height: 45px;
    width: 100%;
    margin: 0;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.25px;
    border-radius: 0;
    letter-spacing: initial;
}

.productContainer .pvBidStatus h4.winning {
    background: #063db4;
    background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(7, 6, 91, 0.8281906512605042) 22%,
        rgba(9, 9, 121, 1) 55%,
        rgba(9, 9, 121, 0.9346332282913166) 74%,
        rgba(9, 9, 121, 0) 100%
    );
}

.productContainer .pvBidStatus h4.won {
    background: #06b473;
    background: linear-gradient(
        90deg,
        rgba(6, 180, 115, 0) 0%,
        rgba(6, 180, 115, 1) 37%,
        rgba(6, 180, 115, 1) 63%,
        rgba(6, 180, 115, 0) 100%
    );
}

.productContainer .pvBidStatus p.won {
    background: #06b473;
    background: linear-gradient(
        90deg,
        rgba(6, 180, 115, 0) 0%,
        rgba(6, 180, 115, 1) 37%,
        rgba(6, 180, 115, 1) 63%,
        rgba(6, 180, 115, 0) 100%
    );
}

.productContainer .pvBidStatus h4.outbid {
    background: #ff9472;
    background: linear-gradient(
        90deg,
        rgba(255, 148, 114, 0.06348476890756305) 0%,
        rgba(255, 148, 114, 1) 37%,
        rgba(255, 148, 114, 1) 63%,
        rgba(255, 148, 114, 0.03547356442577032) 100%
    );
}

.productContainer .pvBidStatus h4.lost {
    background: #ff7272;
    background: linear-gradient(
        90deg,
        rgba(255, 114, 114, 0.0214679621848739) 0%,
        rgba(255, 114, 114, 1) 37%,
        rgba(255, 114, 114, 1) 63%,
        rgba(255, 114, 114, 0.03547356442577032) 100%
    );
}

.productContainer .plcBdWrapper.cstmMrgnTop {
    margin-top: 160px;
}
.productContainer .nrmlAuctBid input {
    border-radius: 4px;
}

.productContainer .entrCrdTlBtn .primButton button {
    width: max-content !important;
}

.mystsPopup .stsMtrWrapper label {
    min-width: 110px;
}

.stsMtrWrapper hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px dashed rgb(112 112 112);
}

.shrStsWrpr.brdrTopCls {
    padding-top: 20px;
    border-top: 1px dashed rgb(112 112 112);
}

.prtyWrpper {
    text-align: center;
    margin: 15px 0;
    /* width: 74%;
    margin-left: auto; */
}

.prtyWrpper h3 {
    font-size: 16px;
    font-weight: bold;
}

.prtyWrpper p {
    font-size: 14px;
}

.prtyWrpper img {
    max-width: 53px;
    width: 100%;
}

.productContainer .publicOfrngDate {
    border: 1px dashed #064cb4;
    background: #064cb457;
    padding: 15px;
    border-radius: 10px;
}

.productContainer .publicOfrngDate .tmrTxtCnt {
    font-size: 22px;
}

.productContainer .trackListWrapper .trackCard {
    background: transparent;
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #d3d3d3;
    box-sizing: border-box;
    border-radius: 10px;
    min-height: 157px;
}

.productContainer .trackListWrapper .trackCard .reactPlayerWrapper > div {
    max-height: 196px;
    width: 100% !important;
}

.productContainer .trackListWrapper .trackCard .trImgWrpr {
    min-width: 72px;
    max-width: 72px;
    height: 72px;
    margin-bottom: 10px;
}

.productContainer .trackListWrapper .trackCard .trImgWrpr img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.productContainer .trackListWrapper .trackCard .trckDtls {
    padding: 0px 10px;
    /* height: 68px; */
}

.productContainer .trackListWrapper .trackCard .trackColumn {
    min-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.productContainer .trackListWrapper .trackCard .trckDtls .mnDtls {
    text-align: center;
}

.productContainer .trackListWrapper .trackCard .trckDtls .mnDtls h5 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.productContainer .trackListWrapper .trackCard .trckDtls .mnDtls p {
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
}

.productContainer .trackListWrapper .trackCard .trckDtls .mnDtls span {
    font-size: 12px;
    font-weight: normal;
    color: #94989e;
    /* height: 4px; */
}

.productContainer .trackListWrapper .trackCard .trckDtls .icnStk .MuiIconButton-root {
    padding: 5px;
}

.productContainer .trackListWrapper .trackCard .trckDtls .vwMoreDtls {
    text-align: left;
    margin-top: 2px;
}

.productContainer .trackListWrapper .trackCard .trckDtls .vwMoreDtls .MuiButton-root {
    padding: 0;
}

.productContainer
    .trackListWrapper
    .trackCard
    .trckDtls
    .vwMoreDtls
    .MuiButton-root
    .MuiButton-label {
    color: #daccdc;
    padding: 0;
    text-transform: initial;
    font-size: 13px;
}

.productContainer .trackListWrapper .trackCard .pdtDtlContainer .infoCardWrpr {
    padding-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.productContainer .trackListWrapper .trackCard .pdtDtlContainer .infoCardWrpr:not(:last-child) {
    margin-bottom: 25px;
}

.productContainer .trackListWrapper .trackCard .pdtDtlContainer .infoCardWrpr .icnNwWrpr {
    display: flex;
    align-items: center;
    font-family: 'Poppins';
}

.productContainer .trackListWrapper .trackCard .pdtDtlContainer .infoCardWrpr .icnNwWrpr .splVwls {
    margin: 0 15px !important;
}

.productContainer
    .trackListWrapper
    .trackCard
    .pdtDtlContainer
    .infoCardWrpr
    .icnNwWrpr
    .splVwls
    span {
    font-size: 20px;
    font-weight: bold;
    width: 100%;
}

.productContainer .trackListWrapper .slick-track {
    display: flex;
    align-items: stretch;
}

.productContainer .trackListWrapper .slick-track .slick-slide {
    height: auto;
}

.productContainer .trackListWrapper .slick-track .slick-slide > div,
.productContainer .trackListWrapper .slick-track .slick-slide > div > div.px-2,
.productContainer .trackListWrapper .slick-track .slick-slide .trackCard {
    height: 100%;
}

.productContainer .trackListWrapper .trackCard .pdtDtlContainer .infoCardWrpr p {
    font-size: 13px;
    min-width: 120px;
    text-align: center;
}

.productContainer .trackListWrapper .trackCard .pdtDtlContainer .infoCardWrpr .fldVlu p {
    text-align: left;
}

.productContainer .trackListWrapper .trackCard .pdtDtlContainer .infoCardWrpr label {
    height: max-content;
    padding-left: 0px;
}

.productContainer .trackListWrapper .trackCard .pdtDtlContainer .infoCardWrpr label img {
    width: 63px;
}

.productContainer
    .trackListWrapper
    .pdtDtlContainer
    .infoCardWrpr:first-child
    .icnNwWrpr
    .splVwls
    span {
    color: #2ebd59;
}

.productContainer
    .trackListWrapper
    .pdtDtlContainer
    .infoCardWrpr:nth-child(2)
    .icnNwWrpr
    .splVwls
    span {
    color: #fd0d1b;
}

.productContainer
    .trackListWrapper
    .pdtDtlContainer
    .infoCardWrpr:nth-child(3)
    .icnNwWrpr
    .splVwls
    span {
    color: #277ef8;
}

.productContainer .trackListWrapper .pdtDtlContainer {
    margin-top: 35px;
}

/* .infoCardWrpr {
    background: white;
} */

.infoCardWrpr label {
    width: 177px;
    min-width: 177px;
    height: 55px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    /* background: #e8e8e8; */
    font-weight: bold;
    color: #535353;
}

.infoCardWrpr .icnNwWrpr label {
    width: unset;
    min-width: unset;
    height: unset;
    display: flex;
    align-items: center;
    padding-left: 15px;
    /* background: #e8e8e8; */
    font-weight: bold;
    color: #535353;
}

.infoCardWrpr p {
    color: #707070;
    text-align: left;
}

.infoCardWrpr .fldVlu {
    min-height: 55px;
    padding: 10px;
    text-align: left;
    font-family: 'Poppins';
}

.infoCardWrpr .fldVlu p:not(:last-child) {
    margin-bottom: 5px !important;
}

.infoCardWrpr .fldVlu p > span:last-child {
    display: none;
}

/* .productContainer {
    background: #f8f8f8;
} */

.pxyBdPop {
    flex-wrap: wrap;
}

.pxyBdPop .bidInput,
.pxyBdPop .bidInput div {
    width: 100%;
}

.pxyBdPop .secButton {
    width: 100%;
}

.pxyBdPop .secButton button {
    margin-left: 0 !important;
    width: 100%;
}

.nrmlBdWrp .prxyBidPopBtn {
    max-width: 150px;
}

.sortDropDown {
    max-width: 160px;
}

.productContainer .bdngDtlWrapper .placeBidWrpr.bdHstryTable .sctnHdr .rtWrapper {
    width: max-content !important;
}

.offrngWrapper .mnTpCntnr {
    position: relative;
}

.offrngWrapper .mnTpCntnr.mnTpforOfrntBuy,
.offrngWrapper .mnTpCntnr.mnTpForAuc {
    display: grid;
    grid-template-columns: 3fr minmax(340px, 1fr);
    gap: 45px;
}

.offrngWrapper .mnTpCntnr .rsrvNtMet {
    color: red;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    /* bottom: -90px; */
    width: max-content;
    background-color: rgb(255, 232, 232);
    padding: 5px 12px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
}

.offrngWrapper .mnTpCntnr .rsrvMet {
    color: green;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    /* bottom: -90px;  */
    width: max-content;
    background-color: rgb(221 231 222);
    padding: 5px 12px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
}

.offrngWrapper .mkNrmlPrxyBtn .primButton button {
    width: 180px;
    height: 40px;
}

.prxyPopup .pxyBdPop .primButton {
    padding: 0px;
}

.offrngWrapper .favoriteCheck label {
    color: var(--black);
}

.offrngWrapper .buyNnwContainer .customInput {
    margin-bottom: 0;
}

.productContainer .offrngWrapper .tlBdUnitsDtl {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 250px;
    margin: auto;
    border-top: 1px solid rgb(214, 214, 214);
    padding-top: 15px;
    padding-bottom: 10px;
}

.productContainer .offrngWrapper .tlBdUnitsDtl p {
    font-size: 14px;
    font-weight: 700;
    color: #b4b4b4;
    margin-bottom: 5px;
    max-width: 107px;
}

.productContainer .offrngWrapper .tlBdUnitsDtl h3 {
    color: var(--primColor);
    font-size: 20px;
    font-family: var(--secFont);
}

/*old css*/

.productView .pvLt,
.productView .pvRt {
    width: 100%;
}

.productView .image-gallery-thumbnails-container {
    text-align: start;
}

.productView .image-gallery-icon {
    filter: none;
    background: #00000036;
    padding: 10px;
}

.productView .image-gallery-icon:hover {
    color: var(--primColor);
}

.productView .image-gallery-thumbnail {
    border: 2px solid transparent;
}

.productView .image-gallery-thumbnail.active,
.productView .image-gallery-thumbnail:hover,
.productView .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid var(--primColor);
    border-radius: 5px;
}

.productView .pvTimerBox {
    border: 1px dashed #7ed2ff;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primColor);
    padding: 15px;
    border-radius: 5px;
    margin: 30px 0 15px 0;
}

.productView .pvTimerBox > * {
    margin-bottom: 0;
}

.productView .pvTimerBox label {
    padding-inline-start: 10px;
    padding-inline-end: 15px;
    font-size: 15px;
}

.productView .pvTimerBox h4 {
    font-size: 16px;
    font-weight: 700;
}

.productView .pvActBtn .pabChild {
    text-align: center;
}

.productView .pvActBtn {
    background: #f8f8f8;
    padding: 15px 10px;
}

.productView .pvBidBox {
    width: 100%;
    margin: 20px auto 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.productView .pvBidBox .biddingCnt > div {
    margin: 0;
    width: 49%;
}

.productView .pvBidBox .biddingCnt .customInput {
    margin-bottom: 5px;
}

.productView .pvBidBox .customInput,
.productView .customInput .MuiTextField-root,
.productView .customInput .MuiInputBase-root.MuiOutlinedInput-root,
.productView .customInput .MuiInputBase-root.MuiOutlinedInput-root input {
    height: 50px;
}

.productView .pvBidBox .customInput .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    margin-top: 5px;
}

.productView .pvBidBox .customInput .MuiInputLabel-outlined.MuiInputLabel-shrink {
    margin-top: 0px;
}

.productView .pvBidBox .tertButton {
    width: 100%;
    margin-inline-start: 10px;
}

.productView .pvBidBox .tertButton,
.productView .pvBidBox .tertButton button {
    height: 50px;
}

.productView .pvBidBox .primButton,
.productView .pvBidBox .secButton,
.productView .pvBidBox .primButton button,
.productView .pvBidBox .secButton button {
    height: 50px;
    font-weight: 700;
}

.productView .pvBidBox form {
    width: 60%;
    margin: 0 auto;
}

.productView .pvBidBox form:not(:nth-child(1)) {
    margin-top: 30px;
}

.productView .pvActBtn .pabChild label {
    font-size: 14px;
    color: #373737;
}

.productView .pvActBtn .pabChild h4 {
    font-size: 18px;
    color: #373737;
    font-weight: 700;
}

.productView .pvActBtn .pabChild.pvCurrentBid h4 {
    color: var(--primColor);
}

.productView .pvBidHistBtn {
    color: var(--primColor);
    text-transform: initial;
    margin: 10px auto;
    font-weight: 600;
    display: flex;
}
.productView .pvBidHistBtn .MuiButton-label .material-icons {
    padding-inline-end: 10px;
}

.productView .pvBidBox > div {
    margin: 0;
    width: 49%;
    margin-inline-end: 10px;
}

.productView .pvBidBox .primButton,
.productView .pvBidBox .secButton {
    width: 49%;
    margin: 0 auto;
}

.productView .pvProductInfo h2 {
    font-size: 24px;
    color: #0e131f;
    margin: 0;
    font-weight: 700;
}

.productView .pvProductDesc label {
    font-size: 15px;
    color: #373737;
    font-weight: 600;
    margin: 0;
    padding-inline-end: 10px;
}

.productView .pvProductDesc h6 {
    font-size: 15px;
    color: #646464;
    font-weight: 400;
    margin: 0;
    display: inline;
}

.productView .pvProductDesc .pvDescChild {
    margin-bottom: 10px;
}

.productView .cartHead {
    font-size: 24px;
    color: #353535;
    font-weight: 700;
    margin: 30px 0 10px 0;
    text-transform: uppercase;
}

.productView .MuiPaper-root.MuiAccordion-root {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.productView .MuiButtonBase-root.MuiAccordionSummary-root {
    margin-bottom: 0;
}

.productView .MuiAccordionDetails-root {
    font-size: 15px;
}

.productView .accTitle {
    margin-bottom: 0;
    font-weight: 600;
}

.productView .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
}

.productView .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 500px;
}

.productView .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    height: auto;
}

.productView .pvBidStatus {
    padding: 10px 0;
    background: #fff;
    position: sticky;
    width: 100%;
    top: -20px;
    z-index: 10;
    border-radius: 10px;
    margin-bottom: 10px;
}

.productView .pvBidStatus h4 {
    padding: 15px;
    color: #fff;
    height: 45px;
    width: 100%;
    margin: 0;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.25px;
    border-radius: 5px;
}

.productView .pvBidStatus h4.winning {
    background: #06a2b4;
}

.productView .pvBidStatus h4.won {
    background: #06b473;
}

.productView .pvBidStatus h4.outbid {
    background: #ff9472;
}

.productView .pvBidStatus h4.lost {
    background: #ff7272;
}

.nwRpWrpr {
    white-space: pre;
    margin-right: 10px;
    min-width: 80px;
}

.mystsPopup .nwRpWrpr {
    min-width: 80px;
}

.vwMrPdtInfo .modalChildren {
    padding: 0;
}

.vwMrPdtInfo .modalChildren .productContainer .vwPdtMoreInfo {
    padding: 20px;
}

.vwMrPdtInfo .modalChildren .productContainer .vwPdtMoreInfo .pdtImg img {
    width: 140px;
    height: 140px;
    border-radius: 7px;
}

.vwMrPdtInfo .infoCardWrpr label {
    height: auto;
    padding: 10px;
    width: 137px;
    min-width: 137px;
    background: none;
    padding-left: 0;
}

.vwMrPdtInfo .pdtDtlContainer {
    margin-left: 15px;
    background: white;
    padding: 10px;
    border-radius: 5px;
    margin-top: 82px;
}

.vwMrPdtInfo .pdtDtlContainer .ttleValue {
    font-size: 22px;
    font-weight: bold;
    border-bottom: 1px solid #d2d2d2;
    padding-bottom: 13px;
    width: 100%;
    margin-bottom: 15px;
}

.vwMrPdtInfo .pdtDtlContainer .adtnlFlds {
    margin: 5px 0;
}

.vwMrPdtInfo .pdtDtlContainer .adtnlFlds label {
    color: gray;
    margin-right: 15px;
}

.vwMrPdtInfo .pdtDtlContainer .pdtImg {
    margin-top: -82px;
}

.vwMrPdtInfo .pdtDtlContainer .ttleValue p {
    line-height: 20px;
}

.vwMrPdtInfo .pdtDtlContainer .ttleValue small {
    font-size: 13px;
    color: gray;
}

.pdtDtlContainer .typeChip {
    font-size: 14px;
    font-weight: normal;
    padding: 10px;
    background: #2a3a3b;
    border-radius: 100px;
    margin-left: 15px;
    pointer-events: none;
    white-space: pre;
}

.productContainer .pdtBdHstTbl .ttleBdHst {
    font-size: 14px;
    font-weight: 600;
    color: white;
}

.productContainer .pdtBdHstTbl table {
    background: #2e2e2e;
    border: none;
}

.productContainer .pdtBdHstTbl table th {
    text-align: center;
}

.productContainer .pdtBdHstTbl table th,
.productContainer .pdtBdHstTbl table td {
    font-size: 13px;
    border: none;
}

.productContainer .pdtBdHstTbl table thead th {
    border-bottom: 1px solid #3c3c3c;
}

.productContainer .pdtBdHstTbl table tbody tr:not(:last-child) td {
    border-bottom: 1px solid #3c3c3c;
}

.pdtBdHstTbl.mobileTable {
    display: none;
}

h4.pdtDscptn {
    font-size: 1.25rem;
    font-weight: 800;
}

.productContainer .descContainer a {
    word-break: break-all;
}

.wonItemStatus {
    background: rgb(46 133 231);
    padding: 10px;
    border-radius: 10px;
    border: 1px dashed var(--primColor);
    margin-bottom: 25px;
    color: white;
    margin-top: 25px;
    text-align: center;
}

.wonItemStatus p:last-child {
    margin-bottom: 0;
}

.offrngWrapper .pdtNmInfoWrpr {
    max-width: 100%;
}

.productContainer .pvBidStatus h4 {
    border-radius: 10px;
}

.offrngWrapper {
    position: relative;
}

/* .offrngWrapper .prdtClosedWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
} */

.offrngWrapper .prdtClosedWrapper .cntntContainer {
    /* background-color: white;
    width: 100%;
    min-height: 348px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border-radius: 10px; */
}

.offrngWrapper .prdtClosedWrapper .cntntContainer .productImage {
    width: 140px;
    height: 140px;
    border-radius: 10px;
}

.offrngWrapper .prdtClosedWrapper .cntntContainer h4 {
    font-family: var(--secFont);
    font-weight: 600;
    margin-top: 15px;
}

.offrngWrapper .prdtClosedWrapper .cntntContainer .primButton button:hover img {
    filter: brightness(0);
}

.adCaledarDiv {
    margin-top: 15px;
}

.adCaledarDiv label small {
    font-weight: 500;
}

.adCaledarDiv img {
    height: 30px;
    width: 100%;
}

.adCaledarDiv a {
    margin-left: 15px;
    max-width: 80px;
}

.extndRuleCntnr {
    position: relative;
}

.extndRuleCntnr .hlpTltpCntnr {
    position: absolute;
    right: 5px;
    top: 0;
}

.extndRuleCntnr .hlpTltpCntnr .MuiSvgIcon-root {
    width: 0.8em;
    height: 0.8em;
}

.nrmlBdTimer.extndRuleCntnr .hlpTltpCntnr .MuiSvgIcon-root {
    color: var(--primColor);
}

.clsdPrchsDtls {
    border-top: 1px solid whitesmoke;
    width: 100%;
    margin-top: 15px;
    padding: 10px;
    text-align: center;
    font-weight: 600;
    color: var(--primColor);
    background: #e4ecff;
    border-radius: 15px 10px;
}

.clsdPrchsDtls p {
    margin-bottom: 10px;
}

.clsdPrchsDtls .primButton {
    max-width: 350px;
    margin: auto;
}

.vwBdHstyLable {
    font-size: 14px;
    font-weight: 600;
}

.nmbrOfBdsChart .rsrvUntWrpr {
    text-transform: capitalize;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    background: rgb(255, 212, 212);
    color: red;
    border-radius: 100px;
    padding: 5px 10px;
    min-width: 135px;
    max-width: 135px;
}

.nmbrOfBdsChart .rsrvUntWrpr.ttwRsrv {
    width: max-content;
    max-width: initial;
    justify-content: center;
    margin: 10px auto 0;
}

.nmbrOfBdsChart .rsrvUntWrpr.ylwBack {
    background: rgb(247, 213, 71);
    color: rgb(118, 96, 0);
}

.nmbrOfBdsChart .rsrvUntWrpr.grnBack {
    background: rgb(213, 255, 219);
    color: rgb(3, 141, 9);
}

.nmbrOfBdsChart .rsrvUntWrpr .material-icons {
    font-size: 20px;
    cursor: pointer;
}

.ryltsDtlsWrpr {
    background: #fed4d3;
    padding: 5px 15px;
    border-radius: 5px;
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    gap: 30px;
}

.subscribeSuccess {
    background: #c8edd761;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    color: green;
    min-height: 100px;
    justify-content: center;
}

.subscribeSuccess .material-icons {
    color: inherit;
}
.subscribeSuccess h5 {
    color: inherit;
    margin: 0 0 0 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}

.productContainer .pvRt {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 25px;
}

.productContainer .pvMyUnits {
    border: 1px solid #cad7e5;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 0;
}

.productContainer .pvMyUnits h4 {
    color: #2f6baf;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
}

.productContainer .pvMyUnits p {
    color: #8a8a8a;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
}

.productContainer .pvUnitPrice {
    /* width: 100%; */
}

.productContainer .pvUnitPrice h3 {
    color: #2f6baf;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    justify-content: center;
}
.productContainer .pvUnitPrice h3 p {
    color: #2f6baf;
}

.productContainer .saleInner .pvUnitPrice h3 {
    font-size: 30px;
}

.productContainer .pvUnitPrice p {
    color: #232323;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
}

.productContainer .pvRoyaltiesPerYear {
    color: #232323;
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
}

.productContainer .pvRoyaltiesPerYear span {
    font-weight: 700;
    padding-left: 10px;
}

.productContainer .availableUnits {
    color: #b74444;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: right;
}

.productContainer .mkNrmlPrxyBtn.noCardBank {
    justify-content: center !important;
}

.productContainer .miscBidding {
    margin: 25px 0 35px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    align-items: flex-start;
}

.productContainer .miscBidding .MuiAccordion-root {
    box-shadow: none;
    border-radius: 10px;
    border: 2px solid #e5e5e5;
    margin-top: 0;
}

.productContainer .miscBidding .MuiAccordionSummary-root {
    min-height: 50px;
}

.productContainer .miscBidding .MuiAccordion-root:before {
    display: none;
}

.productContainer .miscBidding .MuiAccordionSummary-content {
    margin: 0;
}

.productContainer .miscBidding .MuiAccordion-root .MuiTypography-root {
    color: #232323;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.productContainer .miscBidding .MuiAccordion-root .MuiTypography-root img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 10px;
}

.productContainer .miscBidding .MuiAccordion-root .MuiTypography-root .MuiSvgIcon-root {
    margin-left: 12px;
    color: var(--primColor);
}

.productContainer .miscBidding .MuiAccordionDetails-root .mkNrmlPrxyBtn,
.productContainer .miscBidding .MuiAccordionDetails-root .mkNrmlPrxyBtn .primButton {
    width: 100%;
}

.productContainer .miscBidding .MuiAccordionDetails-root .mkNrmlPrxyBtn form {
    width: 100%;
}

.productContainer .miscBidding .MuiAccordionDetails-root .mkNrmlPrxyBtn .biddingCnt {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.productContainer .miscBidding .MuiAccordionDetails-root .mkNrmlPrxyBtn .primButton button {
    height: 50px;
}

.productContainer .miscBidding .customInput {
    margin: 0;
}

.productContainer .miscBidding .MuiInputBase-root {
    height: 50px;
}

.productContainer .miscBidding .secButton button {
    height: 50px;
}

.productContainer .miscBidding .MuiAccordionDetails-root .secButton button {
    height: 40px;
}

.productContainer .offerWatchlist {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productContainer .offerWatchlist a {
    font-size: 14px;
}
.productContainer .offerWatchlist a.vipTotal {
    font-size: 14px;
    cursor: text;
}

.productContainer .offerWatchlist a.vipTotal:hover {
    text-decoration: none;
}

.productContainer .offerWatchlist a:only-child {
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.productContainer .offerWatchlist label {
    font-size: 14px;
}

.productContainer .offerCatalog {
    color: #232323;
    font-size: 14px;
    font-weight: 600;
    /* margin-bottom: 15px; */
    text-align: center;
}

.productContainer .offerCatalog a,
.productContainer .offerCatalog .cursorDecoy {
    color: #2f6baf;
    font-weight: 700;
}

.productContainer .miscAccess.maBuynow {
    margin-top: 60px;
}

.productContainer .miscAccess h4 {
    color: #232323;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 30px;
}

.productContainer .miscAccessInner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
}

.productContainer .miscAccessInner img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    aspect-ratio: 1 / 1;
    border-radius: 10px;
}

.productContainer .miscAccessInner h6 {
    color: #232323;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
}
.productContainer .miscAccessInner p {
    margin: 0;
    color: #989898;
    font-size: 14px;
    font-weight: 400;
}

.privateListingCnt {
    background: #ffffff;
    box-shadow: 0px 4px 16px 4px rgb(0 0 0 / 8%);
    border-radius: 10px;
    margin: 175px 0 30px;
    padding: 50px 25px;
}

.privateListingCnt img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: contain;
    margin-bottom: 20px;
}

.privateListingCnt h2 {
    font-size: 20px;
    color: #333;
    font-weight: 600;
}

.privateListingCnt h3 {
    font-size: 18px;
    color: #333;
    font-weight: 400;
}

.privateListingCnt a {
    font-size: 16px;
    color: var(--primColor);
    font-weight: 500;
}

.productContainer .investDetails .pdtDtlTtle {
    border: none;
}

.productContainer .dshbrdTransTable label {
    margin-bottom: 0;
}

.productContainer .getCertificate {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: #f7fff4;
    border: 1px solid #95d180;
    border-radius: 10px;
    padding: 20px 35px;
    margin: 30px 0;
}

.productContainer .getCertificate > img {
    width: 55px;
    height: 55px;
    object-fit: contain;
    margin-right: 35px;
}

.productContainer .getCertificate .gcInfo {
    margin-right: 10px;
}

.productContainer .getCertificate h3 {
    font-size: 15px;
    color: #121212;
    font-weight: 600;
    margin-bottom: 10px;
}

.productContainer .getCertificate h3 span {
    font-weight: 700;
}

.productContainer .getCertificate h3:last-child {
    margin-bottom: 0;
}

.productContainer .getCertificate .primButton {
    margin-left: auto;
}

.productContainer .getCertificate .primButton button {
    height: 45px;
}

.productContainer .getCertificate .primButton button .material-icons-outlined {
    margin-right: 10px;
}

.productContainer .customizedPlaque {
    background: #323232;
    padding: 15px 35px;
    border-radius: 10px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.productContainer .customizedPlaque h3 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
}

.productContainer .customizedPlaque .primButton {
    width: max-content;
}

.productContainer .customizedPlaque .primButton button {
    height: 35px;
}

.productContainer .customizedPlaque .cpBanner {
    aspect-ratio: 4 / 1;
    width: 100%;
    max-width: 400px;
    height: 100%;
    object-fit: cover;
}

.productContainer .ttwRoyaltiesAmt {
    font-size: 14px;
    font-weight: 600;
    color: #232323;
    margin-top: 15px;
}

.productContainer .ttwRoyaltiesAmt span {
    margin-left: 15px;
    font-weight: 700;
}

.productContainer .bidBarInfo {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.productContainer .pdtTopContainer.vipAuc .placeBidWrpr .prcLvlTxt {
    margin-bottom: 0;
}

.productContainer .pdtTopContainer.vipAuc .placeBidWrpr .helpIcon {
    margin: 0 0 0 10px !important;
}

.productContainer .pdtTopContainer.vipAuc .placeBidWrpr .helpIcon svg {
    margin-left: 0;
}

.productContainer .pdtTopContainer.vipAuc .pvRt {
    margin-bottom: 15px;
}

.productContainer .ssMessageCnt {
    text-align: center;
}

.productContainer .ssMessageCnt .ssZoho {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.productContainer .pvAbt.shortDesc .pdtAbtCntnr {
    display: inline-block;
    margin-bottom: 5px;
}

.productContainer .pvAbt.shortDesc .readMore {
    display: inline-block;
    margin-left: 4px;
    font-size: 14px;
}

.productContainer .bdngDtlWrapper.vipRt {
    border: 2px solid var(--primColor);
    border-top: 10px solid #7fa1c7;
    padding: 0 10px;
    border-radius: 10px;
    box-shadow: 0px 3px 20px #1f457059;
}

.productContainer .bdngDtlWrapper.vipRt .placeBidWrpr.proxyBidWrapper,
.productContainer .offrngWrapper .rtContainer.bdngDtlWrapper.vipRt .placeBidWrpr {
    margin-left: 0;
}

.productContainer .vipAuc .bdngDtlWrapper .customInput .MuiFormHelperText-root {
    letter-spacing: initial;
    margin-left: 0;
    margin-right: 0;
}

.productContainer .ck-content img {
    max-width: 100%;
    object-fit: contain;
    height: auto;
}

@media (max-width: 1400px) {
    .offrngWrapper.pdtTopContainer {
        margin-top: 320px;
    }
}

@media (min-width: 767px) and (max-width: 1150px) {
    .productContainer .saleInner {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;
    }

    .productContainer .saleInner.publicFuture {
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 1150px) {
    .offrngWrapper .mnTpCntnr.mnTpforOfrntBuy,
    .offrngWrapper .mnTpCntnr.mnTpForAuc {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .productContainer .offrngWrapper .rtContainer.bdngDtlWrapper .placeBidWrpr {
        max-width: 400px;
    }

    .productContainer .ofrngNrmlBd .sldOutWrpr {
        position: static;
        width: max-content;
        margin-left: auto;
        margin-bottom: 10px;
        transform: none;
    }
}

@media (min-width: 1000px) {
    .ryltsDtlsWrpr {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: -50px;
    }
}

.productContainer .pdtTopContainer .pdtTxtInfo {
    position: relative;
}

.productContainer .pdtTopContainer.vipAuc .placeBidWrpr .helpIcon {
    text-align: right;
    margin: 5px 0;
}

.productContainer .pdtTopContainer.vipAuc .placeBidWrpr .helpIcon .MuiSvgIcon-root {
    color: var(--primColor);
}

.productContainer .lftContainer .pdtTopContainer .pdtTxtInfo > div {
    width: 100%;
}

.ryltsDtlsWrpr .rylInrWrpr {
    text-align: center;
}

.ryltsDtlsWrpr .rylInrWrpr p {
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: 500;
}

.ryltsDtlsWrpr .rylInrWrpr span {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 4px;
    min-width: 80px;
    text-align: left;
    color: red;
}

.productContainer .offrngWrapper.pdtTopContainer .songDetails .grdCntnr {
    background: var(--secColor);
    padding: 5px 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    min-width: 180px;
}

.productContainer .offrngWrapper.pdtTopContainer .songDetails .grdCntnr h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-right: 10px;
}

.productContainer .offrngWrapper.pdtTopContainer .songDetails .grdCntnr p {
    font-size: 10px;
    margin: 0;
}

.productContainer .offrngWrapper.pdtTopContainer .abtContainer {
    padding-top: 70px;
}

.productContainer .offrngWrapper.pdtTopContainer .abtContainer .sctnHdr {
    font-size: 25px;
    font-family: 'Poppins';
    font-weight: 700;
}

.offrngWrapper.offrngWrapper .mnTpCntnr .lftContainer {
    width: unset;
    margin-right: 18px;
}

.showDscrption {
    font-family: 'Poppins';
    font-size: 15px;
    color: var(--primColor);
    text-decoration: underline;
    margin-top: 10px;
    cursor: pointer;
}

.hwToByTxt {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.hwToByTxt .ulTxtWrp {
    color: var(--primColor);
    font-weight: 500;
    text-decoration: underline;
}

.rctplyPopup.custom-modal .modal-content {
    background: transparent;
}

.rctplyPopup.custom-modal .modal-header {
    background: transparent;
    border-bottom: 1px solid transparent;
}

.rctplyPopup.custom-modal .close {
    opacity: 1;
    border-radius: 100px;
}

.rctplyPopup.custom-modal .close .material-icons {
    color: white;
    font-size: 35px;
}

.rctplyPopup.custom-modal .modal-body.cm-body {
    padding: 55px;
}

.rctplyPopup.custom-modal .modal-body.cm-body > div {
    width: 100% !important;
    height: 480px !important;
    border: 2px solid white;
}

.rctplyPopup.custom-modal .modal-body.cm-body > div {
    width: 100% !important;
    height: 480px !important;
}

.ofrngBuyCntnr .dtlGrid.pcPkgDtl {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.productContainer .ofrngBuyCntnr .songDetails .dtlGrid.pcPkgDtl h3 {
    font-size: 40px;
    margin: 0 0 10px;
    width: 100%;
}

.productContainer .ofrngBuyCntnr .songDetails {
    margin: 0px;
}

.productContainer .offrngWrapper .pvtMrBynw {
    min-height: 95.99px;
}

.productContainer .offrngWrapper .pvtMrBynw:empty {
    min-height: initial;
}

.trackListWrapper .slick-prev:before,
.trackListWrapper .slick-next:before {
    content: none;
}

.trackListWrapper .MuiButtonBase-root.slick-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    min-width: unset;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
}

.trackListWrapper .MuiButtonBase-root.slick-arrow.slick-next {
    right: -50px;
}

.trackListWrapper .MuiButtonBase-root.slick-arrow.slick-prev {
    left: -50px;
}

.trackListWrapper .slick-next:focus,
.trackListWrapper .slick-next:hover,
.trackListWrapper .slick-prev:focus,
.slick-prev:hover {
    color: var(--primColor);
}

.trackListWrapper .MuiButtonBase-root.slick-arrow .material-icons {
    font-size: 30px;
}

.productContainer .pdtDescriptionCont img {
    max-width: 100%;
    height: auto;
}

.dsclmrTxtPdt {
    font-size: 10px;
}

.purchSngShrTxt {
    margin-top: 40px;
}

.getShrsFree {
    background: #f4f4f4;
    padding: 7px 10px;
    border: 1px dashed var(--primColor);
    border-radius: 5px;
    font-size: 14px;
    width: max-content;
    font-weight: 500;
    line-height: 22px;
}

.getShrsFree a {
    padding-right: 5px;
}

.qtyValueFree {
    font-weight: 600;
    /* color: var(--primColor); */
    padding: 0 6px;
}

.frQtyWrp {
    background: #fff8db;
    border: 1px solid var(--secColor);
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 15px;
}

.frQtyWrp p {
    margin: 0;
}

.ofrngNrmlBd .sldOutWrpr {
    position: absolute;
    top: -60px;
    border-radius: 25px;
    font-size: 14px;
    text-transform: capitalize;
    padding: 10px 30px;
    background: #db3d3d;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-weight: 600;
}

.productContainer .pvViewInvoice {
    border: 1px solid var(--primColor);
    background: #fff;
    color: var(--primColor);
    font-weight: 600;
    font-size: 14px;
    border-radius: 10px !important;
    width: 100%;
    height: 45px;
}

.productContainer .pcgArtist {
    margin-bottom: 10px;
    border-radius: 35px;
    border: 1px solid #e4e4e4;
    background: #fff;
    padding: 3px 15px 4px 5px;
    width: max-content;
}

.productContainer .pcgArtist img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.productContainer .pcgArtist span {
    font-size: 12px;
    color: 232323;
    font-weight: 700;
}

.vwPortContainer .checkoutWrpr .ck-content img {
    max-width: 100%;
    object-fit: contain;
    height: auto;
}

.rtContainer.bdngDtlWrapper.vipRt {
    position: relative;
}

.rtContainer.bdngDtlWrapper.vipRt .hlpTltpCntnr {
    margin: 10px 10px 0px 0px;
    position: absolute;
    top: 0;
    right: 0;
}

.rtContainer.bdngDtlWrapper.vipRt .hlpTltpCntnr * {
    width: fit-content;
}

.rtContainer.bdngDtlWrapper.vipRt .hlpTltpCntnr div p {
    text-align: right;
    color: rgb(47, 107, 175);
}

@media (min-width: 1200px) {
    .vwPortContainer .checkoutWrpr .ck-content {
        padding: 0px 20px !important;
    }
    .vwPortContainer .ck-content {
        padding: 0px 20px;
    }
    .vwPortContainer .productContainer .ck-content {
        padding: 0px 32px;
    }
}

@media (min-width: 1280px) and (max-width: 1400px) {
    .productContainer .offrngWrapper.pdtTopContainer .abtContainer {
        width: 38%;
    }
}

@media (min-width: 1370px) {
    .offrngWrapper .mnTpCntnr .lftContainer {
        width: calc(100% - 510px);
    }

    .offrngWrapper.offrngWrapper .mnTpCntnr .lftContainer {
        width: unset;
        min-width: 550px;
    }

    .offrngWrapper .mnTpCntnr .lftContainer.nrmblLftBiddingWrpr {
        width: 100%;
    }
}

@media (max-width: 1470px) {
    .productContainer .bdngDtlWrapper .chartContainer {
        max-width: unset;
    }
}

@media (max-width: 1470px) and (min-width: 1300px) {
    .productContainer .bdngDtlWrapper .placeBidWrpr .bidInput {
        min-width: 265px;
    }
    .productContainer .vipAuc .bdngDtlWrapper .placeBidWrpr .bidInput {
        min-width: 140px;
    }
}

@media (max-width: 1275px) {
    .productContainer .descContainer img {
        width: 100%;
        height: auto;
        max-width: 650px;
    }

    .productContainer .bdngDtlWrapper .placeBidWrpr .ylowGrd .grdCntnr {
        width: unset;
    }
}

@media (max-width: 1099px) {
    .placeBidWrpr .mnBdnCont .biddingCnt {
        flex-wrap: wrap;
    }

    .productContainer .bdngDtlWrapper .placeBidWrpr .bidInput {
        max-width: unset;
        margin-right: 0;
    }

    .productContainer .bdngDtlWrapper .placeBidWrpr .bidInput .customInput {
        margin-bottom: 10px;
    }

    .productContainer .bdngDtlWrapper .placeBidWrpr.proxyBidWrapper .secButton,
    .productContainer .bdngDtlWrapper .placeBidWrpr.proxyBidWrapper .primButton {
        margin-top: 0;
    }
}

@media (max-width: 1490px) {
    .infoCardWrpr label {
        width: unset;
        min-width: unset;
    }
}

@media (max-width: 1470px) {
    .offrngWrapper .pdtNmInfoWrpr {
        flex-wrap: wrap;
    }

    .productContainer .offrngWrapper .bdngDtlWrapper {
        /* min-width: 515px; */
        max-width: unset;
    }

    .productContainer .offrngWrapper .bdngDtlWrapper.nrmblBiddingWrpr {
        min-width: unset;
        max-width: unset;
    }

    .productContainer .offrngWrapper .bdngDtlWrapper .placeBidWrpr {
        overflow: hidden;
    }

    /* .productContainer .offrngWrapper .bdngDtlWrapper .chartContainer .txtInforWrpr {
        left: 21%;
    } */
}

@media (max-width: 1375px) {
    .productContainer .offrngWrapper .bdngDtlWrapper .placeBidWrpr {
        margin-left: 12px;
    }

    .productContainer
        .offrngWrapper
        .bdngDtlWrapper
        .placeBidWrpr
        .crntBdStWrp
        .grdContent
        .grdBox {
        min-width: unset;
    }
}

/* @media (max-width: 1302px) and (min-width: 1270px) {
    .productContainer .bdngDtlWrapper .chartContainer .txtInforWrpr {
        left: 15.5%;
    }
} */

/* @media (max-width: 1342px) and (min-width: 1284px) {
    .productContainer .bdngDtlWrapper .chartContainer .txtInforWrpr {
        left: 18.5% !important;
    }
} */

@media (max-width: 1285px) {
    .offrngWrapper .mnTpCntnr {
        flex-wrap: wrap;
    }

    .offrngWrapper .pdtNmInfoWrpr {
        max-width: unset;
    }

    .productContainer .offrngWrapper .bdngDtlWrapper .placeBidWrpr {
        margin-left: 0;
    }

    .productContainer .offrngWrapper.vipAuc .bdngDtlWrapper .placeBidWrpr {
        width: 100%;
    }

    .productContainer .offrngWrapper .bdngDtlWrapper {
        margin: auto;
        margin-top: 0;
        /* border-top: 1px solid #d0d0d0; */
        padding-top: 25px;
        width: 100%;
    }

    .productContainer .offrngWrapper .MuiAccordionDetails-root .bdngDtlWrapper {
        padding-top: 5px;
    }

    .productContainer .offrngWrapper .bdngDtlWrapper .placeBidWrpr {
        flex-wrap: nowrap !important;
        width: max-content;
        margin: auto !important;
    }

    .productContainer .offrngWrapper .MuiAccordionDetails-root .bdngDtlWrapper .placeBidWrpr {
        width: 100%;
        flex-wrap: wrap !important;
    }
    .productContainer .offrngWrapper .MuiAccordionDetails-root .bdngDtlWrapper .chartContainer {
        border-left: none;
    }

    .productContainer .miscBidding {
        gap: 30px;
    }

    .productContainer .offrngWrapper .bdngDtlWrapper .placeBidWrpr .chartContainer {
        min-width: 321px;
    }
}

@media (max-width: 992px) {
    .offrngWrapper.pdtTopContainer {
        margin-top: 230px;
    }
}

@media (max-width: 800px) {
    .productContainer .ofrngNrmlBd.bidInput p.text-danger {
        position: relative;
        max-width: unset;
    }

    .productContainer .miscBidding {
        gap: 30px;
    }
    .productContainer .getCertificate,
    .productContainer .customizedPlaque {
        flex-wrap: wrap;
    }
    .productContainer .getCertificate .primButton {
        margin-left: 0;
        margin-top: 15px;
    }
    .productContainer .getCertificate > img {
        margin-bottom: 10px;
    }
    .productContainer .customizedPlaque {
        height: auto;
        flex-direction: column-reverse;
    }
    .productContainer .customizedPlaque .cpBanner {
        margin-bottom: 10px;
    }
}

@media (max-width: 1150px) {
    .productContainer .vipAuc .offerWatchlist {
        justify-content: center;
        gap: 15px;
    }
}

@media (max-width: 1040px) {
    .vwPdtMoreInfo .mnTpCntnr {
        margin-top: 80px;
    }
}

@media (max-width: 1285px) {
    .productContainer .bdngDtlWrapper .placeBidWrpr {
        flex-wrap: wrap !important;
    }

    .productContainer .bdngDtlWrapper .placeBidWrpr .crntBdStWrp {
        width: 100% !important;
    }

    .productContainer .pdtTopContainer .pdtName {
        width: 100%;
        font-size: 32px;
    }

    .productContainer .pdtTopContainer .mnTpCntnr.mnTpForAuc .pdtName {
        width: 100%;
    }
    .offrngWrapper .pdtNmInfoWrpr {
        width: 100%;
    }
}

@media (max-width: 950px) {
    .productContainer .pvTimerBox {
        color: black;
    }
    .productContainer .pdtTopContainer {
        clip-path: unset;
        -webkit-clip-path: unset;
        padding: 20px;
    }

    .productContainer .pdtTopContainer .pdtImg {
        margin: auto;
    }

    .productContainer .pdtTopContainer .pdtImg img {
        width: 200px;
        height: 200px;
        border-radius: 5px;
    }

    .productContainer .pdtTopContainer .mnTpCntnr {
        flex-wrap: wrap;
    }

    .productContainer .pdtDtlContainer {
        margin-top: 40px;
    }

    .productContainer .pdtDtlContainer .pdtTp {
        flex-wrap: wrap-reverse;
    }

    .productContainer .dscInfoWrpr > div {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-bottom: 10px;
    }

    .productContainer .pdtTopContainer .pdtDtlContainer .pvTimerBox {
        padding: 5px;
        margin: auto;
        background: #3a3939;
        width: 100%;
        margin-bottom: 25px;
        border-radius: 100px;
    }

    .productContainer .pdtTopContainer .pdtDtlContainer .stsMtrWrapper .shrStsWrpr {
        flex-wrap: wrap;
    }

    .pdtDtlContainer .stsMtrWrapper .stsBxs {
        margin-top: 10px;
    }

    .productContainer .pdtTopContainer .pdtDtlContainer {
        padding-left: 0 !important;
    }

    .productContainer .pdtTopContainer .pdtDtlContainer .plcBdWrapper {
        flex-wrap: wrap;
        height: unset;
    }

    .productContainer
        .pdtTopContainer
        .pdtDtlContainer
        .plcBdWrapper
        .lftWrapper
        .primButton
        button,
    .productContainer .pdtTopContainer .pdtDtlContainer .plcBdWrapper .lftWrapper .primButton {
        width: 150px !important;
        margin-top: 0;
    }

    .productContainer .pdtTopContainer .pdtDtlContainer .plcBdWrapper .rtWrapper {
        margin-top: 10px;
        width: 100%;
    }

    .productContainer .pdtTopContainer .pdtDtlContainer .plcBdWrapper .rtWrapper > div {
        justify-content: flex-end;
    }

    .productContainer .pdtTopContainer .pdtDtlContainer .pdtName {
        text-align: center;
        margin-bottom: 25px;
    }

    .custom-modal .modal-dialog {
        margin: auto;
    }

    .pdtBdHstTbl.mobileTable {
        display: block;
    }

    .pdtBdHstTbl.desktopTable {
        display: none;
    }

    .productContainer .pdtDescriptionCont {
        padding-left: 0;
    }

    .productContainer .pdtDescriptionCont iframe {
        width: 100% !important;
    }

    .productContainer .pdtDescriptionCont img {
        width: 100%;
        height: auto;
    }

    .productContainer .pdtDescriptionCont a {
        word-break: break-all;
    }

    .appLayoutWrapper .backGrndImage.largeImage.productview {
        height: 249px;
    }

    .pdtTopContainer .backButton .MuiButton-label,
    .productContainer .pdtTopContainer .pdtName,
    .productContainer .pdtTopContainer .songDetails .dtlGrid p {
        color: var(--black);
    }

    .pdtTopContainer .backButton .MuiButton-label img {
        filter: brightness(0);
        width: 90px;
    }

    .productContainer .pdtTopContainer .songDetails .dtlGrid h3 {
        color: var(--primColor);
    }

    .productContainer .pvTimerBox .tmrTxt {
        color: var(--black);
    }

    .productContainer .pvTimerBox .pvTimerView h6 span:last-child {
        color: var(--black);
    }

    .productContainer .pdtTopContainer .favoriteCheck label {
        color: var(--primColor);
    }

    .productContainer .pdtTopContainer .pdtTxtInfo {
        padding-left: 0 !important;
    }

    .productContainer .offrngWrapper.pdtTopContainer .pdtTxtInfo {
        padding-left: 10px !important;
    }

    .offrngWrapper .mnTpCntnr {
        flex-wrap: wrap;
    }
}

@media (max-width: 850px) {
    .ofrPdtWrpr.pdtTopContainer .biddingCnt.buyNnwContainer .primButton {
        margin-top: 0 !important;
        max-width: 150px;
    }

    .rglrActnCntnr.pdtTopContainer .biddingCnt.buyNnwContainer .primButton,
    .entrCrdTlBtn .primButton {
        margin-top: 0 !important;
        width: 150px !important;
    }

    .productContainer .ofrPdtWrpr.pdtTopContainer .pdtDtlContainer .pvTimerBox {
        background: transparent;
    }

    .productContainer .nrmlAuctBid {
        flex-wrap: wrap;
    }

    .productContainer .nrmlAuctBid .primButton {
        width: 150px !important;
    }

    .productContainer .pdtTopContainer .pdtDtlContainer .nrmlAuctBid .bidInput > div {
        width: 100% !important;
    }

    .productContainer .offrngWrapper .bdngDtlWrapper .chartContainer {
        border-left: none;
        width: 100%;
        max-width: unset;
    }

    .productContainer .offrngWrapper .bdngDtlWrapper {
        margin: auto;
    }
}

@media (max-width: 800px) {
    .productContainer .pdtTopContainer .pdtTxtInfo {
        flex-wrap: wrap !important;
        margin-top: 10px;
    }

    .productContainer .pdtTopContainer .pdtTxtInfo .rtContainer {
        margin-top: 20px;
    }

    .productContainer .pdtTopContainer .mnTpCntnr .lftContainer .d-flex {
        flex-wrap: wrap;
    }

    .productContainer .pdtTopContainer .mnTpCntnr .lftContainer .stsMtrWrapper .d-flex {
        flex-wrap: nowrap;
    }

    .productContainer .pcgArtist {
        margin: 0 auto 10px;
    }

    .productContainer .pdtTopContainer .pdtName {
        text-align: center;
    }

    .productContainer .pdtTopContainer .mnTpCntnr .lftContainer .mkNrmlPrxyBtn {
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }

    .productContainer .pdtTopContainer .mnTpCntnr .lftContainer .mkNrmlPrxyBtn .vwBdHstyLable {
        margin-top: 15px;
        margin-left: 0 !important;
    }

    .offrngWrapper .mkNrmlPrxyBtn .primButton {
        width: 100%;
    }

    .offrngWrapper .mkNrmlPrxyBtn .primButton button {
        width: 100%;
    }

    .productContainer .pdtTopContainer .pdtTxtInfo > div {
        width: 100%;
        text-align: center;
    }

    .productContainer .offrngWrapper .mnTpCntnr .lftContainer .biddingCnt {
        flex-wrap: nowrap;
    }

    .productContainer .pdtDescriptionCont .fldVlu {
        flex-wrap: wrap;
    }

    .wonItemStatus .won {
        text-align: left;
        margin-bottom: 10px !important;
    }

    .productContainer .bdngDtlWrapper .placeBidWrpr .nmbrOfBdsChart .sctnHdr {
        flex-wrap: wrap;
        height: max-content;
    }

    .nmbrOfBdsChart .rsrvUntWrpr {
        width: 100%;
        justify-content: center;
        margin-top: 12px;
    }

    .productContainer .pdtTopContainer .pdtName {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .productContainer .bdngDtlWrapper .vwBdHstyLable {
        width: 100%;
    }
    .productContainer .miscBidding {
        grid-template-columns: 1fr;
    }

    .productContainer .mkNrmlPrxyBtn.noCardBank .vwBdHstyLable {
        width: max-content;
    }
    .privateListingCnt h2 {
        font-size: 18px;
    }
    .privateListingCnt h3 {
        font-size: 16px;
    }
    .privateListingCnt a {
        font-size: 15px;
    }
    .privateListingCnt {
        margin: 45px 0 30px;
    }
    .offrngWrapper.offrngWrapper .mnTpCntnr .lftContainer {
        margin-right: 0;
    }
    .productContainer .pdtTopContainer .pdtName,
    .productContainer .pdtTopContainer .mnTpCntnr.mnTpForAuc .pdtName {
        text-align: center;
    }
    .productContainer .availableUnits {
        text-align: center;
    }
}

@media (max-width: 600px) {
    .dashboard .dpTable table thead th {
        white-space: pre;
    }

    .productContainer .pdtTopContainer .pdtNmInfoWrpr {
        flex-wrap: wrap;
    }

    .productContainer .pdtTopContainer .pdtName {
        text-align: center;
        width: 100%;
    }

    .productContainer .ofrngNrmlBd .sldOutWrpr {
        margin: 0 auto 10px;
    }

    .productContainer .pdtTopContainer .mnTpCntnr .lftContainer .biddingCnt.buyNnwContainer {
        width: 100%;
        flex-wrap: nowrap;
    }

    .pdtTopContainer .biddingCnt.buyNnwContainer > div {
        width: 100%;
    }

    .productContainer .pdtTopContainer .favoriteCheck {
        margin: auto;
    }

    .productContainer .vipAuc.pdtTopContainer .favoriteCheck {
        margin: 0;
    }

    .productContainer .pdtTopContainer .songDetails {
        flex-wrap: wrap;
    }

    .productContainer .pdtTopContainer.ofrngBuyCntnr .songDetails .dtlGrid {
        min-width: unset;
    }

    .productContainer .pdtTopContainer.ofrngBuyCntnr .mnTpCntnr .lftContainer .songDetails {
        flex-wrap: nowrap;
    }

    .productContainer
        .pdtTopContainer.ofrngBuyCntnr
        .mnTpCntnr
        .lftContainer
        .songDetails.ofrngWrpRp {
        flex-wrap: wrap;
        justify-content: center;
    }

    .productContainer
        .pdtTopContainer.ofrngBuyCntnr
        .mnTpCntnr
        .lftContainer
        .songDetails.ofrngWrpRp
        .dtlGrid.pcPkgDtl,
    .productContainer
        .pdtTopContainer.ofrngBuyCntnr
        .mnTpCntnr
        .lftContainer
        .songDetails.ofrngWrpRp
        .dtlGrid.pcPkgDtl
        h3,
    .productContainer
        .pdtTopContainer.ofrngBuyCntnr
        .mnTpCntnr
        .lftContainer
        .songDetails.ofrngWrpRp
        .dtlGrid.pcPkgDtl
        p {
        text-align: center;
        margin: 0;
        margin-bottom: 10px;
        width: 100%;
    }

    .timerOverWrpr {
        margin-top: 15px;
    }

    .timerOverWrpr > div {
        margin: auto;
    }

    .productContainer .pdtTopContainer .songDetails .dtlGrid {
        margin-bottom: 15px;
        text-align: center;
    }

    .productContainer .pdtTopContainer .pdtTxtInfo .rtContainer {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .productContainer .pvTimerBox {
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        color: #000;
    }

    .productContainer .pdtTopContainer .songDetails .dtlGrid:nth-child(odd) {
        width: 50%;
        border-right: 1px solid #e5e5e5;
    }

    .productContainer .pdtTopContainer.ofrngBuyCntnr .songDetails .dtlGrid:nth-child(odd) {
        width: max-content;
        text-align: left;
        border-right: none;
        width: 30%;
        margin-bottom: 0px;
        flex-wrap: wrap;
    }

    .productContainer .pdtTopContainer.ofrngBuyCntnr .songDetails .dtlGrid:nth-child(odd) p br {
        display: none;
    }

    .productContainer .pdtTopContainer.ofrngBuyCntnr .songDetails .grdCntnr {
        width: 70%;
    }

    .productContainer .pdtTopContainer .backButton {
        position: static;
        right: 0;
        top: -5px;
    }

    .productContainer .pdtTopContainer.ttwContainer .backButton {
        position: static;
        margin: 20px auto;
    }

    .productContainer .pdtTopContainer.ttwContainer .backButton .MuiButton-label {
        color: #000;
    }

    .pdtTopContainer.ttwContainer .backButton .MuiButton-label img {
        filter: brightness(0);
    }

    .offrngWrapper.offrngWrapper .mnTpCntnr .lftContainer {
        margin-right: 0px;
    }

    .productContainer .offrngWrapper.pdtTopContainer .backButton {
        position: absolute;
        left: 0;
        right: initial;
        top: -105px;
    }

    .productContainer .pdtTopContainer .backButton .MuiButton-label {
        color: white;
        font-size: 12px;
    }

    .pdtTopContainer .backButton .MuiButton-label img {
        filter: brightness(11);
    }

    .productContainer .offrngWrapper .pvTimerBox .tmrTxt {
        text-align: center;
    }

    .productContainer .offrngWrapper .pvBidStatus {
        position: absolute;
        /* top: 127px; */
        top: -53px;
        width: 100%;
        left: 0;
    }

    .offrngWrapper .prdtClosedWrapper .cntntContainer h4 {
        text-align: center;
    }

    .offrngWrapper .prdtClosedWrapper .cntntContainer .primButton button img {
        width: 29px;
    }

    .offrngWrapper .prdtClosedWrapper .cntntContainer .primButton button {
        font-size: 12px;
    }

    .productContainer .offrngWrapper .bdngDtlWrapper .placeBidWrpr {
        flex-wrap: wrap !important;
        width: 100%;
    }

    .productContainer .offrngWrapper .bdngDtlWrapper {
        min-width: unset;
    }

    .productContainer
        .offrngWrapper
        .bdngDtlWrapper
        .placeBidWrpr
        .crntBdStWrp
        .grdContent
        .grdBox {
        max-width: unset;
    }

    .productContainer .offrngWrapper .bdngDtlWrapper .placeBidWrpr .chartContainer {
        max-width: 250px;
        height: unset;
    }

    .productContainer .bdngDtlWrapper .chartContainer .txtInforWrpr {
        top: 18%;
    }

    /* .offrngWrapper .mnTpCntnr .rsrvMet {
        position: relative;
        margin-left: auto;
        top: unset;
        right: unset;
    } */

    .ryltsDtlsWrpr .rylInrWrpr p {
        text-align: left;
    }

    .ryltsDtlsWrpr .rylInrWrpr {
        flex-wrap: nowrap !important;
    }

    .productContainer
        .trackListWrapper
        .trackCard
        .pdtDtlContainer
        .infoCardWrpr
        .icnNwWrpr
        .splVwls {
        margin: 0 8px !important;
    }

    .productContainer .trackListWrapper .trackCard .pdtDtlContainer .infoCardWrpr .fldVlu p {
        font-size: 10px;
    }

    .trackListWrapper .MuiButtonBase-root.slick-arrow.slick-next {
        right: -28px;
    }

    .trackListWrapper .MuiButtonBase-root.slick-arrow.slick-prev {
        left: -28px;
    }

    .productContainer .ofrngNrmlBd.bidInput p.text-danger.purchSngShrTxt {
        max-width: unset;
    }

    .productContainer .pvPriceAct {
        grid-template-columns: 1fr;
    }
    .productContainer .pdtTopContainer .mnTpCntnr .lftContainer .d-flex {
        margin: 0 auto;
        padding-left: 0 !important;
    }

    .productContainer .bdngDtlWrapper .placeBidWrpr .shrStsWrpr .stsBxs {
        margin-left: 15px;
        margin-right: 10px;
    }

    .offrngWrapper .mnTpCntnr.mnTpforOfrntBuy {
        gap: 30px;
    }

    .offrngWrapper .mnTpCntnr.mnTpForAuc {
        gap: 15px;
    }

    .offrngWrapper .mnTpCntnr .rsrvNtMet {
        bottom: -80px;
    }

    .productContainer .pdtTopContainer .pdtName {
        font-size: 24px;
    }
    .productContainer .pvAbt * {
        font-size: 14px;
    }

    .productContainer .miscBidding {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .productContainer .miscAccessInner {
        grid-template-columns: 1fr;
    }
    .productContainer .miscAccessInner img {
        height: auto;
    }

    .productContainer .miscAccessInner .miscAccessItem {
        display: grid;
        grid-template-columns: 100px auto;
        gap: 15px;
    }
    .productContainer .miscAccessInner h6 {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 15px;
    }
    .productContainer .miscAccessInner p {
        font-size: 14px;
    }
}

@media (max-width: 545px) {
    .offrngWrapper .mnTpCntnr.mnTpForAuc,
    .offrngWrapper .mnTpCntnr.mnTpforOfrntBuy {
        grid-template-columns: 1fr;
    }
    .productContainer .availableUnits {
        text-align: center;
    }
    .productContainer .offrngWrapper .pvTimerCnt {
        position: relative;
        top: initial;
    }
    .productContainer .offrngWrapper.vipAuc .bdngDtlWrapper {
        padding-top: 0;
    }

    .productContainer .offrngWrapper.vipAuc .bdngDtlWrapper .pvRt {
        margin-top: 0;
    }
    .productContainer .offrngWrapper .pvTimerBox .tmrTxt,
    .productContainer .offrngWrapper .pvTimerBox .pvTimerView h6 span:last-child {
        color: #333;
    }

    .ryltsDtlsWrpr {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .offrngWrapper .mnTpCntnr .rsrvMet {
        bottom: -80px;
    }

    .offrngWrapper .mnTpCntnr.mnTpForAuc .rsrvNtMet,
    .offrngWrapper .mnTpCntnr.mnTpForAuc .rsrvMet {
        bottom: 0;
        margin-top: 12px;
    }

    .nrmlBdTimer.extndRuleCntnr .hlpTltpCntnr .MuiSvgIcon-root {
        color: var(--primColor);
    }
    .offrngWrapper.pdtTopContainer {
        margin-top: 220px;
    }

    .productContainer .offrngWrapper.pdtTopContainer .backButton {
        top: -45px;
    }
    .productContainer .bdngDtlWrapper.vipRt {
        padding-top: 15px !important;
        padding-bottom: 15px;
    }

    .nmbrOfBdsChart .ttwRsrv.rsrvUntWrpr.grnBack {
        margin-bottom: 15px;
    }
}

@media (max-width: 520px) {
    .offrngWrapper .mnTpCntnr.mnTpforOfrntBuy {
        gap: 10px;
    }
    .subscribeSuccess {
        min-height: auto;
    }

    .subscribeSuccess h5 {
        font-size: 16px;
        width: 100%;
    }

    .productContainer .pdtTopContainer .pdtDtlContainer .plcBdWrapper .lftWrapper .primButton {
        margin-top: 10px;
    }

    .productContainer
        .pdtTopContainer
        .pdtDtlContainer
        .plcBdWrapper
        .lftWrapper
        .nrmlAuctBid
        .primButton {
        margin-top: 0;
    }

    .productContainer
        .pdtTopContainer
        .pdtDtlContainer
        .plcBdWrapper
        .lftWrapper
        .primButton
        button,
    .productContainer .pdtTopContainer .pdtDtlContainer .plcBdWrapper .lftWrapper .primButton {
        width: 100% !important;
        margin-left: 0;
    }

    .productContainer .pdtTopContainer .pdtDtlContainer .plcBdWrapper .lftWrapper {
        width: 100%;
    }

    .productContainer .pdtTopContainer .pdtDtlContainer .biddingCnt {
        width: 100%;
    }

    .productContainer .pdtTopContainer .pdtDtlContainer .plcBdWrapper .lftWrapper .bidInput {
        flex-wrap: nowrap !important;
        width: 100%;
    }

    .productContainer .pdtTopContainer .pdtDtlContainer .plcBdWrapper .lftWrapper .bidInput > div {
        width: calc(100% - 120px);
    }

    .prtyWrpper p {
        font-size: 12px;
    }

    .productContainer .offrngWrapper .bdngDtlWrapper .placeBidWrpr .chartContainer {
        min-width: unset;
    }

    /* .productContainer .bdngDtlWrapper .chartContainer .txtInforWrpr {
        left: 20.5%;
    } */

    .gridCard .pdtDtls .typeChip {
        white-space: pre;
    }
}

@media (max-width: 450px) {
    .productContainer .pvBidStatus h4 {
        font-size: 13px;
        height: auto;
        padding: 8px 0;
    }

    .productContainer .offrngWrapper .pvBidStatus {
        top: -63px;
    }
    .gridCard .pdtDtls .typeChip {
        font-size: 11px;
    }

    .productContainer .pdtTopContainer .songDetails .dtlGrid p {
        font-size: 10px;
    }

    .productContainer .getCertificate h3 {
        font-size: 14px;
    }

    .productContainer .getCertificate button .MuiButton-label {
        font-size: 12px;
    }

    .productContainer .customizedPlaque h3 {
        font-size: 16px;
    }

    .productContainer .mkNrmlPrxyBtn {
        flex-wrap: wrap;
        justify-content: center !important;
        flex-direction: column-reverse;
    }
    .productContainer .getCertificate,
    .productContainer .customizedPlaque {
        padding: 15px;
    }
    .productContainer .mkNrmlPrxyBtn .vwBdHstyLable {
        margin-left: auto;
        margin-right: auto !important;
        width: max-content;
        margin-top: 12px;
    }
    .productContainer .miscAccessInner .miscAccessItem {
        grid-template-columns: 70px auto;
    }

    .productContainer .miscBidding .MuiAccordionDetails-root .mkNrmlPrxyBtn .biddingCnt {
        grid-template-columns: 1fr;
    }

    .productContainer .MuiAccordionDetails-root .pagination-wrapper h6 {
        margin-bottom: 10px;
    }
    .productContainer .miscAccess h4 {
        font-size: 18px;
    }

    .productContainer .miscBidding .MuiAccordionDetails-root table th {
        font-size: 12px;
    }

    .productContainer .miscBidding .MuiAccordionDetails-root table th,
    .productContainer .miscBidding .MuiAccordionDetails-root table td {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 12px;
    }

    .productContainer .ofrngNrmlBd.bidInput .nrmlAuctBid .biddingCnt {
        flex-wrap: wrap;
    }

    .productContainer .ofrngNrmlBd.bidInput .nrmlAuctBid .biddingCnt input {
        border-radius: 10px;
    }

    .productContainer .ofrngNrmlBd.bidInput .nrmlAuctBid .biddingCnt .primButton,
    .productContainer .ofrngNrmlBd.bidInput .nrmlAuctBid .biddingCnt .secButton {
        width: 100% !important;
        margin: 0px 0 15px;
    }
    .productContainer .ofrngNrmlBd.bidInput .nrmlAuctBid .biddingCnt .primButton button,
    .productContainer .ofrngNrmlBd.bidInput .nrmlAuctBid .biddingCnt .secButton button {
        border-radius: 10px;
        width: 100%;
    }

    .productContainer .vipAuc .offerWatchlist {
        flex-wrap: wrap;
        gap: 0;
        margin-top: 15px;
    }
    .productContainer .offerWatchlist a {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 400px) {
    .productContainer .ofrngNrmlBd.bidInput input {
        min-width: initial;
    }
}

@media (max-width: 380px) {
    .subscribeSuccess {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .subscribeSuccess h5 {
        margin: 10px 0 0 0;
    }
}

@media (max-width: 330px) {
    .productContainer .pvBidStatus h4 {
        font-size: 12px;
    }
}
