body {
    margin: 0;
    font-family: var(--fontFamily), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursorPointer {
    cursor: pointer;
}

a {
    color: var(--primColor);
    font-weight: 500;
}

.customContainer {
    padding-inline-start: 40px !important;
    padding-inline-end: 40px !important;
    width: 100% !important;
}

.favoriteCheck label {
    margin-top: 0;
}

div:focus,
button:focus {
    outline: 0;
}

.MuiButton-label {
    font-family: var(--fontFamily), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-transform: initial;
}

.MuiBadge-badge {
    background-color: var(--primColor) !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
    color: var(--primColor) !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor) !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 12px;
    background: #2b2b2be0;
}

.customInput,
.customSelect,
.MuiFormControl-root.customDatepicker,
.customTextArea {
    margin-bottom: 19px;
    /* width: 100%; */
}

.customTextArea .MuiFormControl-root {
    width: 100%;
}

.MuiFormControl-root.customDatepicker {
    width: 100%;
}

.customSelect .MuiFormControl-root {
    width: 100%;
}

.customInput label,
.customSelect label,
.datePickerLabel,
.radioTitle {
    font-size: 14px;
    font-weight: 500;
}

.customInput .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.customSelect .MuiOutlinedInput-notchedOutline,
.customDatepicker .MuiOutlinedInput-notchedOutline {
    border-color: var(--primColor);
    border-radius: 10px;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: var(--primColor);
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: var(--primColor);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--primColor) !important;
    border-width: 1px !important;
}

.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0;
    text-align: start;
}

.customSelect .MuiFormControl-root .MuiSvgIcon-root {
    opacity: 0;
}

.customSelect .MuiFormControl-root {
    position: relative;
}

.customSelect .MuiFormControl-root::after {
    font-family: 'Material Icons';
    content: 'expand_more';
    color: black;
    position: absolute;
    top: 12px;
    right: 12px;
    height: 26px;
    width: 20px;
    font-size: 20px;
    pointer-events: none;
}

.srchTopContainer .customSelect .MuiFormControl-root::after {
    color: white;
    top: 5px;
}

.MuiInputBase-root,
.MuiTypography-body1 {
    font-family: var(--fontFamily) !important;
}

.cursorDecoy {
    cursor: pointer;
}

.customModal {
    z-index: 1500 !important;
}

.bidHistoryIcon {
    color: #b0adad;
    margin-left: 6px;
    font-size: 18px;
}

.search .searchLt,
.dashboard .dashboardLt {
    max-width: 280px;
    width: 100%;
    margin-inline-end: 25px;
}

.search .searchLt {
    position: sticky;
    top: 10px;
}

.dashboard .dashboardLt .sideNav {
    position: sticky;
    top: 10px;
}

.search .searchRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 280px);
}

.search .searchResults.Grid,
.dashboardInner .searchResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root {
    min-width: max-content;
    background: #dadadb;
    color: var(--black);
    border-radius: 2px;
    margin-left: 4px;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active {
    background: var(--black);
    color: white;
}

.search .searchMiscFilters .customSelect,
.dashboard .searchMiscFilters .customSelect {
    margin-bottom: 0;
    min-width: 150px;
    margin-inline-start: 25px;
}

.moveBack {
    color: #a6adb4;
}

.moveBack .material-icons {
    color: #979797;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #dfdfdf;
    margin-inline-end: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.customBreadcrumbs ol {
    width: max-content;
}

.customBreadcrumbs a {
    color: #797979;
    text-transform: capitalize;
}

.customBreadcrumbs p {
    text-transform: capitalize;
}

table,
.table {
    border: none;
    border-collapse: unset;
}

.table td,
.table th,
.table thead th,
.table tbody td {
    border: none;
    position: relative;
}

table thead th {
    color: var(--primColor);
    font-size: 14px;
    border: none;
}

table tbody td {
    font-size: 14px;
    font-weight: 500;
    border: none;
}

table .MuiFormControlLabel-root {
    margin: 0;
}

.pagination-wrapper h6 {
    font-size: 14px;
}

table td.credit {
    color: #4caf50;
    font-weight: 500;
    display: flex;
}

.dpTable table td.debit {
    color: #f44336;
    font-weight: 500;
    display: flex;
}

.dpTable table td > .material-icons {
    padding-inline-end: 5px;
}

.dpTable .table th {
    border: none;
}

.dpTable .table td,
.dpTable .table th {
    vertical-align: middle;
    position: relative;
}

.searchSkeletonBody {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.searchSkeletonBody > div {
    box-shadow: 0 3px 20px rgb(0 0 0 / 10%);
}

.searchSkeletonBody .skeletonWrapper {
    background: #fff;
}

.material-icons.rtl,
.rtlImage {
    transform: rotateY(180deg);
}

.loadingCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
}

.defaultLoader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
}

.defaultLoader .MuiCircularProgress-colorPrimary {
    color: #fff;
}
.customDatepicker .MuiFormHelperText-root {
    color: #eaeaea;
}

.MuiFormLabel-root.Mui-error,
.customSelect .Mui-error .MuiSelect-root,
.customSelect .Mui-error svg {
    color: #f44336 !important;
}

.customSelect .MuiInputBase-root.Mui-error .MuiSelect-root {
    color: var(--black) !important;
}

.noRecordsFound {
    background: white;
    border: none;
    border-radius: 10px;
    width: 100%;
}

.MuiMenuItem-root {
    font-family: var(--fontFamily) !important;
    font-weight: 500;
}

.w-max {
    width: max-content;
}

.zsfbbottom {
    right: 138px !important;
    left: unset !important;
}

#feedbacklabel {
    z-index: 99 !important;
}

#feedbacklabel span {
    background-color: #00457b !important;
    color: #ffffff !important;
    border-radius: 10px 10px 0 0 !important;
}

.favoriteCheck input:checked + label {
    color: red !important;
}

.MuiDialogTitle-root .MuiTypography-root {
    font-family: var(--fontFamily);
    font-size: 18px;
    font-weight: 600;
}

.iframeModal .custom-modal .modal-dialog.modal-sm {
    max-width: 900px;
}

.iframeModal iframe {
    border: none;
    width: 100% !important;
    height: 400px !important;
}

.textHeader {
    max-width: '500px';
    text-align: center;
    margin: 'auto';
    margin-top: '20px';
    font-size: '14px';
}

.ofrngNrmlBd.bidInput .MuiFormHelperText-root.Mui-error {
    margin: 3px 0 0;
    font-size: 12px !important;
}

.bidHistorySection .pagination-wrapper {
    padding-left: 15px;
    padding-right: 15px;
}

.bidHistorySection .pagination-wrapper h6 {
    margin: 0;
}

.svRedirect {
    padding: 60px 30px;
}

.svRedirect [aria-label='Redirecting to secure payment'] {
    display: none !important;
}

.svRedirect h6 {
    text-align: center;
    transform: translateY(-30px);
}

.editCertModal.custom-modal .modal-dialog.modal-sm {
    width: 400px;
}

.saleInner.sold {
    position: relative;
}

.customDialog .bidCnfModalCnt h5 {
    font-size: 15px !important;
    text-align: justify;
    line-height: 22px;
}

@media (max-width: 1480px) {
    .offrngWrapper.customContainer {
        padding-inline-start: 20px !important;
        padding-inline-end: 20px !important;
    }
}

@media (max-width: 1145px) {
    .search .searchLt,
    .dashboard .dashboardLt {
        max-width: 250px;
    }
    .search .searchMiscFilters .customSelect,
    .dashboard .searchMiscFilters .customSelect {
        margin-inline-start: 15px;
    }
}

.alrtWrpr {
    padding: 10px 15px;
    border-radius: 10px;
    background: rgb(255, 228, 177);
    display: flex;
    align-items: center;
    width: 100%;
    /* max-width: 400px; */
}

.alrtWrpr p {
    margin: 0;
    color: brown;
}

.alrtWrpr button {
    background: brown;
    padding: 10px 25px;
    margin-left: auto;
    color: white;
    border-radius: 10px;
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }
    .search .searchLt,
    .dashboard .dashboardLt {
        display: none;
    }

    .search .searchRt,
    .dashboard .dashboardRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }
    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 25%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: rgb(25 157 229 / 11%);
        border-color: rgb(25 157 229 / 20%) !important;
    }
    .customBreadcrumbs ol {
        width: 100%;
    }
}

input[value='']::-webkit-datetime-edit {
    color: transparent;
}
input:focus::-webkit-datetime-edit {
    color: #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.cursorPointer {
    cursor: pointer;
}

.logRegPort input:-internal-autofill-selected,
.logRegPort .MuiOutlinedInput-input:-webkit-autofill,
.logRegPort input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #0a0a12 inset !important;
    -webkit-text-fill-color: white;
}

.primButton .MuiButton-contained.Mui-disabled {
    background-color: rgb(78, 78, 78) !important;
    background: unset;
    color: rgb(156, 156, 156);
}

.loginWrapper .radioTitle {
    margin-bottom: 0.5rem;
    padding: 0px !important;
}
.loginWrapper .RadioBox .MuiButtonBase-root.MuiIconButton-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

iframe .row {
    padding-top: 0 !important;
}

@media (max-width: 991px) {
    .dashboardInner .searchResults.Grid.ownershipGrid {
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    }
}

@media (max-width: 800px) {
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 32%;
    }

    .offrngWrapper.customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
    }
}

@media (max-width: 600px) {
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
    }

    .toggleRespDrawer.MuiButtonBase-root {
        width: max-content;
        flex-wrap: nowrap;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 100%;
    }

    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
        width: 100% !important;
    }

    .logRegPort.regViewPort .customCheckbox .MuiListItem-gutters {
        font-size: 14px;
        display: block;
    }

    .tableWrapper .table th,
    .tableWrapper .table td,
    .dpTable .table th,
    .dpTable .table td {
        white-space: pre;
    }
}

@media (max-width: 500px) {
    .dashboardInner .searchResults.Grid.ownershipGrid {
        grid-template-columns: 1fr;
    }

    .ownershipGrid .gridCard .pcgImg img {
        min-height: initial;
        /* max-height: 320px; */
        aspect-ratio: 1 / 2;
    }
}

@media (max-width: 445px) {
    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
    }
    .svRedirect {
        padding: 60px 15px;
    }
    .svRedirect > div {
        width: 100% !important;
    }
    .editCertModal.custom-modal .modal-dialog.modal-sm {
        width: 90%;
    }
    .svRedirect h6 {
        text-align: center;
        font-size: 12px;
        transform: translateY(-50px);
    }
}

@media print {
    .noPrint {
        display: none !important;
    }
}

/*CKEditor CSS*/

/*
 * CKEditor 5 (v31.0.0) content styles.
 * Generated on Wed, 24 Nov 2021 12:23:23 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
 */

:root {
    --ck-color-image-caption-background: hsl(0, 0%, 97%);
    --ck-color-image-caption-text: hsl(0, 0%, 20%);
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341, 100%, 30%);
    --ck-color-table-caption-background: hsl(0, 0%, 97%);
    --ck-color-table-caption-text: hsl(0, 0%, 20%);
    --ck-highlight-marker-blue: hsl(201, 97%, 72%);
    --ck-highlight-marker-green: hsl(120, 93%, 68%);
    --ck-highlight-marker-pink: hsl(345, 96%, 73%);
    --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
    --ck-highlight-pen-green: hsl(112, 100%, 27%);
    --ck-highlight-pen-red: hsl(0, 85%, 49%);
    --ck-image-style-spacing: 1.5em;
    --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
    --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: var(--ck-color-image-caption-text);
    background-color: var(--ck-color-image-caption-background);
    padding: 0.6em;
    font-size: 0.75em;
    outline-offset: -1px;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
    clear: none;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
    margin-top: 0;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
    margin-right: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
    margin-left: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
    font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
    font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
    font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
    font-size: 1.8em;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
    background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
    width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
    display: block;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline {
    /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
    display: flex;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir='rtl'] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: 0.15em;
    border-radius: 2px;
}
/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: var(--ck-color-table-caption-text);
    background-color: var(--ck-color-table-caption-background);
    padding: 0.6em;
    font-size: 0.75em;
    outline-offset: -1px;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
    margin: 0.9em auto;
    display: table;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
    min-width: 2em;
    padding: 0.4em;
    border: 1px solid hsl(0, 0%, 75%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir='rtl'] .table th {
    text-align: right;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir='ltr'] .table th {
    text-align: left;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
    content: '';
    position: absolute;
    border-bottom: 2px dashed hsl(0, 0%, 77%);
    width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
    position: relative;
    z-index: 1;
    padding: 0.3em 0.6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
    list-style: none;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
    margin-bottom: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
    margin-top: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: var(--ck-todo-list-checkmark-size);
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc(var(--ck-todo-list-checkmark-size) / 3);
    top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
        calc(var(--ck-todo-list-checkmark-size) / 8) 0;
    transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
    border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle;
}
/* ckeditor5-language/theme/language.css */
.ck-content span[lang] {
    font-style: italic;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
}

.MuiInputBase-root.Mui-error,
.requiredField .MuiInputBase-root {
    background: #fff7f7;
}

.MuiButton-text.pendingInfo {
    padding: 4px 2px !important;
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%); */
}

.MuiButton-text.pendingInfo:hover {
    text-decoration: none;
    background-color: white;
}

.MuiButton-text.pendingInfo .material-icons {
    font-size: 22.5px;
    color: #00000029;
}
.MuiButton-text.pendingInfo .material-icons:hover {
    font-size: 21px;
    color: #2f6baf;
}

.iframeModal iframe.cardIframe {
    height: 440px !important;
}

.addCardModal .modal-body {
    overflow: hidden !important;
}

@media print {
    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break {
        padding: 0;
    }
    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break::after {
        display: none;
    }
}
