.registerForm {
    max-width: 715px;
    margin: auto;
}

.registerForm .RadioBox .MuiTypography-root {
    font-size: 14px;
    font-weight: 600;
}

.customInput .MuiFormHelperText-root,
.customDatepicker .MuiFormHelperText-root,
.customSelect .MuiFormHelperText-root,
.customSelect .MuiTypography-body1,
.customDatepicker .MuiTypography-body1,
.customInput .MuiTypography-body1 {
    /* color: var(--black); */
    color: gray;
    font-size: 13px !important;
    font-family: var(--fontFamily);
    font-weight: 500;
}

.loginWrapper .customCheckbox .MuiListItem-button {
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    display: block;
}

.loginWrapper .customCheckbox .MuiListItem-button a {
    font-size: 14px;
    font-weight: 600;
}

.loginWrapper .registerForm h3 {
    font-size: 36px;
    font-family: var(--secFont);
    font-weight: bold;
}

.loginWrapper .registerForm .regType {
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 240px;
    height: 56px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    position: relative;
}

.loginWrapper .preReg .primButton {
    width: 192px;
    /* margin-left: auto; */
    margin-right: auto;
}

.loginWrapper .preReg .primButton button {
    padding-left: 40px;
    padding-right: 40px;
}

.loginWrapper .registerForm .regType label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.loginWrapper .registerForm .regType label img {
    margin-right: 20px;
}

.loginWrapper .registerForm .regType h5 {
    font-size: 14px;
    font-weight: 600;
}

.loginWrapper .registerForm .regType label,
.loginWrapper .registerForm .regType h5 {
    margin-bottom: 0;
}

.loginWrapper .registerForm .regType .radioIcon {
    width: 20px;
    height: 20px;
    border: 2px solid var(--primColor);
    border-radius: 30px;
    position: absolute;
    right: 20px;
}

.loginWrapper .registerForm .regType .radioIcon::after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 20px;
    background: var(--primColor);
    position: absolute;
    top: 3px;
    right: 3px;
    transform: scale(0);
    transition: ease-in-out 0.3s;
}
.loginWrapper .registerForm .regType input:checked + label .radioIcon::after {
    transform: scale(1);
}

.loginWrapper .registerForm .regType input:checked + label {
    color: var(--primColor);
}

.viewPort.regViewPort .chkBoxWrpr .MuiFormGroup-root {
    justify-content: center;
}
.viewPort.regViewPort .primButton {
    margin-top: 0px;
}

.cstmPoprCnt {
    margin-top: -10px;
}

.cstmPoprCnt .MuiTypography-root {
    font-size: 13px;
    width: max-content;
    margin-left: auto;
}

.MuiPopover-root .popOverBody {
    max-width: 350px;
    width: 100%;
}
.btmNavigation {
    border-top: 1px solid #2e2e33;
    border-bottom: 1px solid #2e2e33;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
}

.btnDiv .primButton {
    min-width: 230px;
    width: 100%;
    margin-right: 15px;
    margin-left: 0;
}

.btmNavigation .navBtn span.material-icons {
    width: 10px;
}

.btmNavigation .navBtn span.material-icons.finishBtnIcon {
    width: 30px;
}

.btmNavigation .navBtn .MuiButton-label {
    text-transform: initial;
    font-size: 18px;
}

.disclaimerTxt {
    color: #797979;
    line-height: 19px;
}

.disclaimerTxt a {
    color: var(--primColor);
    margin-left: 5px;
}

.regViewPort .MuiIconButton-root.Mui-disabled {
    color: rgb(189, 189, 189) !important;
}

.regViewPort .Mui-disabled .MuiListItem-gutters {
    color: rgb(189, 189, 189) !important;
}

.loginWrapper .chkBoxWrpr.alrdyCmpltd .customCheckbox .MuiListItem-button {
    font-size: 16px;
}

.logRegLayout .rtDtlContainer .loginWrapper .disclaimerTxt a {
    word-break: break-all;
}

.logRegLayout .rtDtlContainer .swtchTabs p.activeTab {
    color: var(--primColor);
}

.logRegLayout .rtDtlContainer .swtchTabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.logRegLayout .rtDtlContainer .swtchTabs p {
    color: black;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logRegLayout .rtDtlContainer .swtchTabs p:first-child {
    margin-right: 15px;
}

.logRegLayout .skipNavBtn {
    position: absolute;
    top: 8px;
    right: 34px;
    color: var(--primColor);
    font-weight: 600;
}

.skipCheckBox .customCheckbox .MuiFormGroup-root .MuiFormControlLabel-root {
    align-items: flex-start;
    font-weight: normal;
}

.skipCheckBox .customCheckbox .MuiFormGroup-root .MuiFormControlLabel-root span {
    padding-top: 0px;
}

.skipCheckBox .customCheckbox .MuiFormGroup-root .MuiFormControlLabel-root .MuiListItem-button {
    font-weight: 400;
    font-size: 15px;
}

.logRegLayout .infoWrapper {
    border: 1px solid #c5c5c5;
    background: rgb(250, 250, 250);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 25px;
    font-size: 14px;
    text-align: justify;
    margin-top: 30px;
}

.logRegLayout .infoWrapper .primButton button {
    width: 260px;
}
/* .markOptCheck {
    text-align: center;
} */

.markOptCheck .customCheckbox .MuiFormControlLabel-root {
    margin: 0px;
    display: inline;
}

.markOptCheck .customCheckbox .MuiCheckbox-root {
    padding: 0px;
}

.markOptCheck .customCheckbox .MuiFormGroup-root {
    display: inline;
    flex-direction: unset;
}

.instrctWrpr {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    backdrop-filter: blur(80px) brightness(1.06);
    display: flex;
    align-items: center;
    justify-content: center;
}

.instrctWrpr .cntCntnr .lgWrpr {
    width: 260px;
    height: 150px;
    margin: auto;
}

.instrctWrpr .cntCntnr .lgWrpr img {
    width: 100%;
    height: 100%;
}

.instrctWrpr .optnCntnr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: 615px;
    width: 100%;
    margin-top: 40px;
}

.instrctWrpr .optnCntnr button {
    width: 250px;
    height: 200px;
    border: 1px solid #707070;
    border-radius: 20px;
    color: #707070;
    background: rgba(255, 255, 255, 0.176);
    font-size: 17px;
}

.instrctWrpr .optnCntnr button img {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
}

.instrctWrpr .optnCntnr button .MuiButton-label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.switchHelperText {
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #6e6e6e;
    margin-top: 5px;
}

.switchHelperText .material-icons {
    color: #ccc;
    margin-right: 5px;
    font-size: 20px;
}
.helper .MuiFormHelperText-root {
    margin: 0;
}
.rgLyrWrpr {
    flex-direction: column;
}
.regActBox {
    width: 100%;
}

.rgLyrWrpr .regLoginWrapper {
    padding-top: 0px !important;
}

@media (max-width: 750px) {
    .regViewPort .leftWrapper,
    .viewPort.regViewPort .rightWrapper {
        width: 100%;
    }

    .regViewPort .leftWrapper .mnTxt {
        font-size: 32px;
        margin-bottom: 25px;
    }

    .regViewPort .leftWrapper .ttlTxt {
        font-size: 20px;
    }

    .regViewPort .leftWrapper .infoLn {
        font-size: 16px;
    }

    .regViewPort .leftWrapper .loginForm {
        margin-top: 26px;
    }

    .viewPort.regViewPort .regType label {
        width: 130px;
        height: 130px;
    }

    .viewPort.regViewPort .regType:first-child {
        margin-inline-end: 20px;
    }

    .regViewPort .leftWrapper .registerForm {
        margin-top: 20px;
    }

    .logRegLayout .rtDtlContainer .loginWrapper .btnDiv {
        flex-wrap: wrap;
    }

    .logRegLayout .rtDtlContainer .loginWrapper .btnDiv p {
        margin-top: 15px;
    }

    .logRegLayout .btmNavigation .MuiButton-label {
        font-size: 15px;
    }

    .logRegLayout .skipNavBtn {
        color: white;
    }
}

@media (max-width: 600px) {
    .logRegLayout .infoWrapper .primButton button {
        max-width: 260px;
        width: 100%;
        height: max-content;
    }
}
